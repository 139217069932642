import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../AppStore/store';
import { loadUser, logout } from '../AppStore/actions/auth.actions';

import { setAuthToken } from '../utils/setAuthToken';

function AuthPage({ loadUser, logout }) {
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser)
    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch(logout);
    });
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}

AuthPage.prototype = {
  loadUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(null, { loadUser, logout })(AuthPage);
