import axios from "axios";
import { GET_QUERIES_SUCCESS, GET_QUERIES_FAILED } from "../constants";

export const getQueries = (query) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/query${query ? query : ""}`);
    dispatch({
      type: GET_QUERIES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: GET_QUERIES_FAILED,
      payload: errors,
    });
  }
};
