import React, { useState, useCallback, useEffect } from "react";
import {
  Stack,
  Card,
  Icon,
  Text,
  Button,
  Tooltip,
  Collapsible,
  TextField,
  Form,
  Spinner,
  Modal,
  FormLayout,
  Select,
  Box,
  Badge,
} from "@shopify/polaris";
import {
  ClipboardMinor,
  InviteMinor,
  ResetMinor,
  LinkMinor,
  OrdersMinor,
  FinancesMinor,
  FraudProtectMinor,
  CashDollarMinor,
} from "@shopify/polaris-icons";
import { useForm, Controller } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAffiliateById,
  affiliateStatusHandler,
  updateAffiliatePersonalNote,
  affiliateEmailStatus,
} from "../../AppStore/actions/affiliates.actions";
import { useParams } from "react-router-dom";
import { status } from "../../utils/data";
import { setAlert } from "../../AppStore/actions/alert.actions";
import { sendAffiliateEmail } from "../../AppStore/actions/affiliates.actions";

const Details = ({
  setAlert,
  affiliateStatusHandler,
  getAffiliateById,
  updateAffiliatePersonalNote,
  affiliateEmailStatus,
  sendAffiliateEmail,
}) => {
  const { affiliateData } = useOutletContext();
  const { id } = useParams();
  const [data, setData] = useState(null);

  const {
    control: personalNoteControl,
    reset: resetPersonalNote,
    formState: { errors },
    handleSubmit: handlepersonalNote,
  } = useForm({});

  const [active, setActive] = useState(false);
  const [productURL, setPRoductURL] = useState("");
  const [productURLCopy, setPRoductURLCopy] = useState("");
  const [coupon, setCoupon] = useState("");

  const [personalNoteLoading, setPersonalNoteLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const handleNoteToggle = useCallback(() => {
    setCollapsed((collapsed) => !collapsed);
  }, []);

  const handleEmailVerification = useCallback(async () => {
    setStatusLoading(true);
    await affiliateEmailStatus(id);
    await getAffiliateById(id);
    setStatusLoading(false);
  }, [id]);
  const handleNewEmail = useCallback(async () => {
    setStatusLoading(true);
    await sendAffiliateEmail(id);
    await getAffiliateById(id);
    setStatusLoading(false);
  }, [id]);

  const handleProductURL = useCallback(
    (value) => {
      if (!value) {
        setPRoductURL("");
        setPRoductURLCopy("");
        return;
      }

      if (!coupon) {
        return;
      }

      setPRoductURL(value);
      const copyURL = `https://${value
        .replace("https://", "")
        .replace("http://", "")}?coupon=${coupon}`;
      setPRoductURLCopy(copyURL);
    },
    [coupon]
  );

  const handleCoupon = useCallback((value) => setCoupon(value), []);

  const handleChange = useCallback(() => setActive(!active), [active]);
  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        setAlert("Copied", "success");
      }
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function() {
        setAlert("Copied", "success");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const submitPersonalNote = async (data) => {
    data.id = id;
    setPersonalNoteLoading(true);
    await updateAffiliatePersonalNote(data);
    await getAffiliateById(id);
    setPersonalNoteLoading(false);
  };

  const submitStatus = async (data) => {
    setStatusLoading(true);
    await affiliateStatusHandler(data);
    await getAffiliateById(id);
    setStatusLoading(false);
  };

  useEffect(() => {
    if (affiliateData.data) {
      setData(affiliateData.data.data);

      setCollapsed(affiliateData.data.data.personalNote ? true : false);
      resetPersonalNote({
        personalNote: affiliateData.data.data.personalNote,
      });
    }
  }, [affiliateData]);

  const statusActions = useCallback((actionStatus) => {
    return actionStatus === "B"
      ? {
          content: "Unblock",
          primary: true,
          removeUnderline: true,
          size: "slim",
          disabled: statusLoading,
          onAction: () =>
            submitStatus({
              id,
              activationStatus: "P",
            }),
        }
      : {
          content: "Block",
          removeUnderline: true,
          size: "slim",
          disabled: statusLoading,
          destructive: true,
          onAction: () =>
            submitStatus({
              id,
              activationStatus: "B",
            }),
        };
  }, []);

  return (
    <>
      {data ? (
        <>
          <Stack distribution='fillEvenly'>
            <Card
              title={
                <Stack alignment='center' distribution='equalSpacing'>
                  <Text variant='headingMd' as='h3'>
                    Total Orders
                  </Text>
                  <Icon source={OrdersMinor} />
                </Stack>
              }
            >
              <Card.Section>
                <Text variant='bodyMd'>{data.totalOrders}</Text>
              </Card.Section>
            </Card>
            <Card
              title={
                <Stack alignment='center' distribution='equalSpacing'>
                  <Text variant='headingMd' as='h3'>
                    Total Revenue
                  </Text>
                  <Icon source={FinancesMinor} />
                </Stack>
              }
            >
              <Card.Section>
                <Text variant='bodyMd'>{data.totalRevenue}</Text>
              </Card.Section>
            </Card>
            <Card
              title={
                <Stack alignment='center' distribution='equalSpacing'>
                  <Text variant='headingMd' as='h3'>
                    Total Commission
                  </Text>
                  <Icon source={CashDollarMinor} />
                </Stack>
              }
            >
              <Card.Section>
                <Text variant='bodyMd'>{data.totalCommission}</Text>
              </Card.Section>
            </Card>
          </Stack>
          <div style={{ paddingTop: "15px" }}>
            <Stack distribution={"fillEvenly"}>
              <Stack.Item>
                <Card
                  title={`${data.firstName} ${data.lastName}`}
                  actions={[
                    {
                      content: (
                        <Badge
                          status={
                            status[data.activationStatus] === "Approved"
                              ? "success"
                              : status[data.activationStatus] === "Pending"
                              ? "attention"
                              : "critical"
                          }
                        >
                          {status[data.activationStatus]}
                        </Badge>
                      ),
                      disabled: true,
                    },
                  ]}
                >
                  <Card.Section>
                    <Stack distribution='equalSpacing'>
                      <p>
                        <b>Email: </b>
                        {data.email}
                      </p>

                      <p>
                        <b>Phone: </b>
                        {data.phone}
                      </p>
                    </Stack>
                    <Stack distribution='equalSpacing'>
                      <p>
                        <b>Created At: </b>
                        {`${new Date(
                          data.createdAt
                        ).toLocaleDateString()} ${new Date(
                          data.createdAt
                        ).toLocaleTimeString()} `}
                      </p>
                      <p>
                        <b>Updated At: </b>
                        {`${new Date(
                          data.updatedAt
                        ).toLocaleDateString()} ${new Date(
                          data.updatedAt
                        ).toLocaleTimeString()} `}
                      </p>
                    </Stack>
                  </Card.Section>
                  <Card.Section title={"Reason to join?"}>
                    <Text>{data.enquiry || ""}</Text>
                  </Card.Section>
                  <Card.Section title={"Affiliate Coupon"}>
                    <div style={{ paddingTop: "10px" }}>
                      <Stack vertical>
                        {data.couponsData.coupons.map(({ _id, coupon }) => (
                          <Box key={_id}>
                            <Stack alignment={"center"}>
                              <Stack.Item fill>{coupon}</Stack.Item>
                              <Stack.Item>
                                <Stack spacing={"loose"}>
                                  <Stack.Item>
                                    <Tooltip
                                      active={false}
                                      content='Copy'
                                      dismissOnMouseOut
                                      preferredPosition='above'
                                    >
                                      <Button
                                        plain
                                        onClick={() =>
                                          copyTextToClipboard(coupon)
                                        }
                                      >
                                        <Icon source={ClipboardMinor} />
                                      </Button>
                                    </Tooltip>
                                  </Stack.Item>
                                  <Stack.Item>
                                    <Tooltip
                                      active={false}
                                      content='Copy'
                                      dismissOnMouseOut
                                      preferredPosition='above'
                                    >
                                      <Button
                                        plain
                                        onClick={() =>
                                          copyTextToClipboard(
                                            `https://${data.shop}?coupon=${coupon}`
                                          )
                                        }
                                      >
                                        <Icon source={LinkMinor} />
                                      </Button>
                                    </Tooltip>
                                  </Stack.Item>
                                </Stack>
                              </Stack.Item>
                            </Stack>
                          </Box>
                        ))}
                      </Stack>
                    </div>
                  </Card.Section>
                  <Card.Section
                    title={"Personal Note"}
                    actions={[
                      {
                        content: collapsed ? "Cancel" : "Add Note",
                        destructive: collapsed ? true : false,
                        onAction: handleNoteToggle,
                      },
                    ]}
                  >
                    <Collapsible
                      open={collapsed}
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <Form onSubmit={handlepersonalNote(submitPersonalNote)}>
                        <Controller
                          control={personalNoteControl}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoComplete='on'
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              requiredIndicator
                              multiline={4}
                              placeholder='Type Personal Note'
                              type='text'
                              error={
                                errors.personalNote
                                  ? "Personal Note should be specified"
                                  : ""
                              }
                            />
                          )}
                          name='personalNote'
                        />
                        <div style={{ paddingTop: "10px" }}>
                          <Button primary loading={personalNoteLoading} submit>
                            Save
                          </Button>
                        </div>
                      </Form>
                    </Collapsible>
                  </Card.Section>
                </Card>
              </Stack.Item>
              <Stack.Item>
                <Card
                  title={"Actions"}
                  actions={[
                    { content: statusLoading ? <Spinner size='small' /> : "" },

                    statusActions(data.activationStatus),
                  ]}
                >
                  <Card.Section>
                    <Stack vertical>
                      {data.activationStatus === "A" ? (
                        <div style={{ color: "#e1a900" }}>
                          <Button
                            removeUnderline
                            size='slim'
                            disabled={statusLoading}
                            icon={ResetMinor}
                            outline
                            monochrome
                            onClick={() =>
                              submitStatus({
                                id,
                                activationStatus: "P",
                              })
                            }
                          >
                            Revoke Approval
                          </Button>
                        </div>
                      ) : (
                        <div style={{ color: "#008060" }}>
                          <Button
                            removeUnderline
                            size='slim'
                            disabled={statusLoading}
                            icon={ResetMinor}
                            outline
                            monochrome
                            onClick={() =>
                              submitStatus({
                                id,
                                activationStatus: "A",
                              })
                            }
                          >
                            Approve
                          </Button>
                        </div>
                      )}
                      {!data.emailVerified ? (
                        <div style={{ color: "#008060" }}>
                          <Button
                            removeUnderline
                            size='slim'
                            icon={InviteMinor}
                            onClick={handleNewEmail}
                          >
                            Resend Welcome Email
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                      {!data.emailVerified ? (
                        <div style={{ color: "#00c1d7" }}>
                          <Button
                            removeUnderline
                            size='slim'
                            outline
                            monochrome
                            onClick={handleEmailVerification}
                            icon={FraudProtectMinor}
                          >
                            Set Email as Verified
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                      <div style={{ color: "#2E72D2" }}>
                        <Button
                          removeUnderline
                          size='slim'
                          icon={LinkMinor}
                          onClick={handleChange}
                        >
                          Product Link Creator
                        </Button>
                      </div>
                    </Stack>
                  </Card.Section>
                </Card>
              </Stack.Item>
            </Stack>
            <Modal
              open={active}
              onClose={handleChange}
              title='Create Product Link'
            >
              <Modal.Section>
                <FormLayout>
                  <Select
                    placeholder='Select Coupon'
                    options={data.couponsData.coupons.map(({ coupon }) => ({
                      label: coupon,
                      value: coupon,
                    }))}
                    onChange={handleCoupon}
                    value={coupon}
                  />
                  <TextField
                    label={"Product URL"}
                    value={productURL}
                    placeholder={`https://${data.shop}`}
                    onChange={handleProductURL}
                  />
                  <TextField
                    label='Copy URL'
                    disabled
                    readOnly
                    value={productURLCopy}
                    connectedRight={
                      <Button
                        icon={ClipboardMinor}
                        onClick={() => copyTextToClipboard(productURLCopy)}
                      />
                    }
                  />
                </FormLayout>
              </Modal.Section>
            </Modal>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

export default connect(mapStateToProps, {
  getAffiliateById,
  updateAffiliatePersonalNote,
  affiliateStatusHandler,
  affiliateEmailStatus,
  setAlert,
  sendAffiliateEmail,
})(Details);
