import { combineReducers } from "redux";
import auth from "./auth.reducer";
import alerts from "./alerts.reducer";
import dashboard from "./dashboard.reducer";
import affiliates from "./affiliates.reducer";
import affiliate from "./affiliate.reducer";
import pricerules from "./pricerules.reducer";
import pricerule from "./pricerule.reducer";
import coupons from "./coupons.reducer";
import coupon from "./coupon.reducer";
import products from "./products.reducer";
import collections from "./collections.reducer";
import orders from "./orders.reducer";
import order from "./order.reducer";
import payouts from "./payouts.reducer";
import payout from "./payout.reducer";
import notifications from "./notifications.reducer";
import staff from "./staff.reducer";
import staffInfo from "./staffInfo.reducer";
import settings from "./settings.reducer";
import templates from "./templates.reducer";
import query from "./query.reducer";
import emailVerification from "./emailVerification.reducer";

export default combineReducers({
  auth,
  alerts,
  dashboard,
  staff,
  staffInfo,
  affiliates,
  affiliate,
  pricerules,
  pricerule,
  coupons,
  coupon,
  products,
  collections,
  orders,
  order,
  payouts,
  payout,
  notifications,
  settings,
  templates,
  query,
  emailVerification,
});
