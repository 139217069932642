import React, { useEffect, useState } from 'react';
import { Card, Form, Stack, TextField, Button } from '@shopify/polaris';

import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';

import {
  changeEmailAdress,
  getStaffById,
} from '../../../AppStore/actions/staff.actions';

import { useParams } from 'react-router-dom';

function StaffEmail({ currentEmail, changeEmailAdress, getStaffById }) {
  const { id } = useParams();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    data.id = id;

    setIsLoading(true);
    await changeEmailAdress(data);
    await getStaffById(id);
    setIsLoading(false);
  };

  useEffect(() => {
    reset({
      email: currentEmail,
    });
  }, [currentEmail]);

  return (
    <Card title='Change Email'>
      <Card.Section title={`Email: ${currentEmail}`}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Stack vertical spacing='tight'>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder='New Email'
                  error={errors.email ? 'Email cannot be empty' : ''}
                />
              )}
              name='email'
            />
            <Button primary submit loading={isLoading}>
              Save
            </Button>
          </Stack>
        </Form>
      </Card.Section>
    </Card>
  );
}

export default connect(null, {
  changeEmailAdress,
  getStaffById,
})(StaffEmail);
