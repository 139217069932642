import axios from 'axios';
import { setAlert } from './alert.actions';

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
} from '../constants';

import { setAuthToken } from '../../utils/setAuthToken';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const loadUser = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const res = await axios.get('/api/v1/me');

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    setAuthToken();
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err.response.statusText },
    });
  }
};

export const shopifyAppLogin = (query) => async (dispatch) => {
  try {
    const res = await axios.post(`/auth/callback${query}`);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: LOGIN_FAIL,
      payload: { msg: error.response.statusText },
    });
  }
};

export const login = (formData) => async (dispatch) => {
  try {
    const res = await axios.post('/api/v1/login', formData, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
    dispatch({
      type: LOGIN_FAIL,
      payload: { msg: err.response.statusText },
    });
  }
};

export const updateProfile = (formData) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v1/profile/update', formData, config);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};

export const changeProfilePassword = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/v1/profile/change/password`,
      formData,
      config
    );
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const autoLogin = (token) => (dispatch) => {
  setAuthToken(token);
  dispatch(loadUser);
};
