import React, { useState, useCallback, useEffect } from "react";

import {
  Card,
  Form,
  FormLayout,
  Layout,
  Page,
  Tabs,
  TextField,
  Button,
  PageActions,
  Stack,
  Text,
  Badge,
} from "@shopify/polaris";

import { useForm, Controller } from "react-hook-form";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Editor } from "@tinymce/tinymce-react";

import {
  getTemplates,
  updateTemplate,
} from "../../../AppStore/actions/templates.actions";

function Settings({ templatesData, getTemplates, updateTemplate }) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notificationStatus: false,
      emailStatus: false,
      smsStatus: false,
    },
  });

  const notificationStatus = watch("notificationStatus");
  const emailStatus = watch("emailStatus");
  const smsStatus = watch("smsStatus");
  const emailTemplate = watch("emailTemplate");

  const [isLoading, setIsLoading] = useState(false);

  const [selected, setSelected] = useState(0);

  const preview = false;

  const tabs = [
    {
      id: "RE",
      content: "Registration",
    },
    {
      id: "AA",
      content: "Account Approval",
    },
    {
      id: "EV",
      content: "Email Verification",
    },
    {
      id: "OP",
      content: "Order Place",
    },
    {
      id: "OA",
      content: "Order Approve",
    },
    {
      id: "PP",
      content: "Payment Paid",
    },
    {
      id: "RP",
      content: "Reset Password",
    },
  ];

  const handleNotificationToggle = useCallback(
    () => setValue("notificationStatus", !notificationStatus),
    [notificationStatus]
  );
  const handleSmsToggle = useCallback(() => setValue("smsStatus", !smsStatus), [
    smsStatus,
  ]);
  const handleEmailToggle = useCallback(
    () => setValue("emailStatus", !emailStatus),
    [emailStatus]
  );

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);

      const template = templatesData.data.data.find(
        ({ templateType }) => templateType === tabs[selectedTabIndex].id
      );

      if (template) {
        const { _id, templateType, notification, sms, email } = template;

        reset({
          id: _id,
          templateType,
          notificationTitle: notification.title,
          notificationBody: notification.body,
          notificationStatus: notification.isActive,
          smsTemplate: sms.body,
          smsStatus: sms.isActive,
          emailTitle: email.title,
          emailSubject: email.subject,
          emailFrom: email.from,
          emailStatus: email.isActive,
          emailTemplate: email.body,
        });
      } else {
        reset({
          id: "",
          templateType: "",
          notificationTitle: "",
          notificationBody: "",
          notificationStatus: "",
          smsTemplate: "",
          smsStatus: "",
          emailTitle: "",
          emailSubject: "",
          emailFrom: "",
          emailStatus: "",
          emailTemplate: "",
        });
      }
    },
    [templatesData, reset]
  );

  useEffect(() => {
    if (!templatesData.isLoading && templatesData.data) {
      const template = templatesData.data.data.find(
        ({ templateType }) => templateType === tabs[selected].id
      );
      if (template) {
        const { _id, templateType, notification, sms, email } = template;

        reset({
          id: _id,
          templateType,
          notificationTitle: notification.title,
          notificationBody: notification.body,
          notificationStatus: notification.isActive,
          smsTemplate: sms.body,
          smsStatus: sms.isActive,
          emailTitle: email.title,
          emailSubject: email.subject,
          emailFrom: email.from,
          emailStatus: email.isActive,
          emailTemplate: email.body,
        });
      }
    }
  }, [templatesData]);

  useEffect(() => {
    getTemplates();
  }, []);

  const onSubmit = async (data) => {
    const {
      id,
      notificationStatus,
      notificationTitle,
      notificationBody,
      smsStatus,
      smsTemplate,
      emailStatus,
      emailTitle,
      emailSubject,
      emailFrom,
      emailTemplate,
    } = data;
    const formData = {
      id,
      notification: {
        isActive: notificationStatus,
        title: notificationTitle,
        body: notificationBody,
      },
      sms: {
        isActive: smsStatus,
        body: smsTemplate,
      },
      email: {
        isActive: emailStatus,
        title: emailTitle,
        subject: emailSubject,
        from: emailFrom,
        body: emailTemplate,
      },
    };
    setIsLoading(true);
    await updateTemplate(formData);
    setIsLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Page>
        <Layout>
          <Layout.Section fullWidth>
            <Card>
              {" "}
              <Tabs
                tabs={tabs}
                selected={selected}
                onSelect={handleTabChange}
              ></Tabs>
            </Card>
          </Layout.Section>
          <Layout.Section fullWidth>
            {/* <Card.Section>Tab {selected} selected</Card.Section> */}

            <Layout sectioned>
              <Layout.AnnotatedSection
                title={"Notification"}
                description={`Create and customize notifications for your affiliates' clients. Set the Notification Title and Template to ensure consistent and engaging messages. Keep clients informed about updates and promotions easily.`}
              >
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <Stack alignment='center' distribution='equalSpacing'>
                        <Text as='p' variant='headingMd'>
                          Notification{" "}
                          <Badge
                            size='small'
                            status={notificationStatus ? "success" : ""}
                          >
                            {notificationStatus ? "Enabled" : "Disabled"}
                          </Badge>
                        </Text>
                        <Button
                          role='switch'
                          id={"notification-activation"}
                          ariaChecked={true}
                          primary={!notificationStatus}
                          onClick={handleNotificationToggle}
                          size='slim'
                        >
                          {notificationStatus ? "Disable" : "Enable"}
                        </Button>
                      </Stack>
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            label='Notification Title'
                            type='text'
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            requiredIndicator
                            error={
                              errors.notificationTitle
                                ? "Notification Body is required"
                                : ""
                            }
                          />
                        )}
                        name='notificationTitle'
                      />
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            label='Notification Body'
                            type='text'
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            requiredIndicator
                            error={
                              errors.notificationBody
                                ? "Notification Body is required"
                                : ""
                            }
                          />
                        )}
                        name='notificationBody'
                      />
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title={"SMS"}
                description={`Customize SMS Template for clear and engaging messages to affiliates' clients.`}
              >
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <Stack alignment='center' distribution='equalSpacing'>
                        <Text as='p' variant='headingMd'>
                          SMS{" "}
                          <Badge
                            size='small'
                            status={smsStatus ? "success" : ""}
                          >
                            {smsStatus ? "Enabled" : "Disabled"}
                          </Badge>
                        </Text>
                        <Button
                          role='switch'
                          id={"sms-activation"}
                          ariaChecked={true}
                          onClick={handleSmsToggle}
                          size='slim'
                          primary={!smsStatus}
                        >
                          {smsStatus ? "Disable" : "Enable"}
                        </Button>
                      </Stack>
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            label='SMS Template'
                            type='text'
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            multiline={4}
                            requiredIndicator
                            error={
                              errors.smsTemplate
                                ? "Sms Template is required"
                                : ""
                            }
                          />
                        )}
                        name='smsTemplate'
                      />
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title={"Email"}
                description={`In the Email Section, you can easily configure email settings for your affiliates' clients. Customize the Title, Subject, From, and Code fields to ensure professional and personalized email communication. This section allows you to create and send effective emails, keeping clients informed and engaged with your affiliate program.`}
              >
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <Stack alignment='center' distribution='equalSpacing'>
                        <Text as='p' variant='headingMd'>
                          Email{" "}
                          <Badge
                            size='small'
                            status={emailStatus ? "success" : ""}
                          >
                            {emailStatus ? "Enabled" : "Disabled"}
                          </Badge>
                        </Text>
                        <Button
                          role='switch'
                          id={"email-activation"}
                          ariaChecked={true}
                          onClick={handleEmailToggle}
                          size='slim'
                          primary={!emailStatus}
                        >
                          {emailStatus ? "Disable" : "Enable"}
                        </Button>
                      </Stack>
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            label='Email Title'
                            type='text'
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            requiredIndicator
                            error={
                              errors.emailTitle ? "Email Title is required" : ""
                            }
                          />
                        )}
                        name='emailTitle'
                      />
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            label='Subject'
                            type='text'
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            requiredIndicator
                            error={
                              errors.emailSubject ? "Subject is required" : ""
                            }
                          />
                        )}
                        name='emailSubject'
                      />
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            label='From'
                            type='email'
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            requiredIndicator
                            error={errors.emailFrom ? "From is required" : ""}
                          />
                        )}
                        name='emailFrom'
                      />
                      {preview ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: "<div>testing</div>",
                          }}
                        />
                      ) : (
                        <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange } }) => (
                            <Editor
                              // tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                              apiKey='ca06pp62u00hrarjbyiu9v9450i05qtlj3m7o5u8y3tg94y1'
                              // cloudChannel='5-stable'
                              onEditorChange={onChange}
                              // toolbar='advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount'
                              value={emailTemplate}
                              init={{
                                relative_urls: false,
                                remove_script_host: false,
                                convert_urls: true,
                                height: 600,
                                language: "en",
                                plugins: [
                                  "advlist",
                                  "autolink",
                                  "link",
                                  "image",
                                  "lists",
                                  "charmap",
                                  "preview",
                                  "anchor",
                                  "pagebreak",
                                  "searchreplace",
                                  "wordcount",
                                  "visualblocks",
                                  "visualchars",
                                  "code",
                                  "fullscreen",
                                  "insertdatetime",
                                  "media",
                                  "table",
                                  "emoticons",
                                  "help",
                                ],
                                toolbar:
                                  "undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media | forecolor backcolor emoticons",
                              }}
                            />
                          )}
                          name='emailTemplate'
                        />
                      )}
                    </FormLayout>
                  </Card.Section>
                </Card>
                <br />
              </Layout.AnnotatedSection>
            </Layout>
          </Layout.Section>
        </Layout>
        <PageActions
          primaryAction={{ content: "Save", loading: isLoading, submit: true }}
        />
      </Page>
    </Form>
  );
}

Settings.prototype = {
  templatesData: PropTypes.object.isRequired,
  getTemplates: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  templatesData: state.templates,
  alerts: state.alerts,
});

export default connect(mapStateToProps, { getTemplates, updateTemplate })(
  Settings
);
