import React, { useCallback, useState } from 'react';
import {
  Page,
  Layout,
  Checkbox,
  Bleed,
  Divider,
  AlphaStack,
  TextField,
  AlphaCard,
  Stack,
  Text,
  Form,
  FormLayout,
  Select,
  PageActions,
} from '@shopify/polaris';

import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { staffRoles } from '../../../utils/data';
import { connect } from 'react-redux';

import { createStaff } from '../../../AppStore/actions/staff.actions';

function CreateStaff({ createStaff }) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      roles: staffRoles,
      isActive: true,
      emailVerified: true,
    },
  });

  const roles = watch('roles');

  const handleRoles = useCallback(
    (index) => {
      const getData = staffRoles;
      getData[index].checked = !getData[index].checked;
      setValue('roles', [...getData]);
    },
    [roles, staffRoles]
  );

  const onSubmit = async (data) => {
    const {
      firstName,
      lastName,
      email,
      password,
      phone,
      isActive,
      emailVerified,
      role,
      roles,
      city,
      province,
      address,
      country,
      postalCode,
      instagram,
      facebook,
      twitter,
      website,
    } = data;

    const getSelectedRoles = roles
      .filter((item) => item.checked)
      .map((item) => item.role);

    const formData = {
      firstName,
      lastName,
      email,
      password,
      phone,
      isActive,
      emailVerified,
      role,
      roles: getSelectedRoles,
      city,
      province,
      address,
      country,
      postalCode,
      social: {
        instagram,
        facebook,
        twitter,
        website,
      },
    };

    setIsLoading(true);
    await createStaff(formData);
    setIsLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Page
        title='Create Staff'
        breadcrumbs={[{ onAction: () => navigate(-1) }]}
      >
        <Layout>
          <Layout.Section>
            <AlphaCard>
              <FormLayout>
                <FormLayout.Group>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        label='First Name'
                        type='text'
                        error={errors.firstName ? 'First Name is required' : ''}
                      />
                    )}
                    name='firstName'
                  />
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='Last Name'
                        error={errors.lastName ? 'Last Name is required' : ''}
                      />
                    )}
                    name='lastName'
                  />
                </FormLayout.Group>
                <Bleed marginInline={'5'}>
                  <Divider />
                </Bleed>

                <FormLayout.Group>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='Email'
                        placeholder='example@example.com'
                        type='email'
                        error={errors.email ? 'Email is required' : ''}
                      />
                    )}
                    name='email'
                  />
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        type='text'
                        label='Phone'
                        placeholder='923127076927'
                        error={errors.phone ? 'Phone is required' : ''}
                      />
                    )}
                    name='phone'
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        type='password'
                        label='Password'
                        error={errors.password ? 'Password is required' : ''}
                      />
                    )}
                    name='password'
                  />
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Select
                        label='Role'
                        options={[
                          { label: 'Select', value: '' },
                          { label: 'Guest', value: 'guest' },
                          { label: 'Staff', value: 'staff' },
                          { label: 'Moderator', value: 'moderator' },
                        ]}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        error={errors.role ? 'Role is required' : ''}
                      />
                    )}
                    name='role'
                  />
                </FormLayout.Group>

                <AlphaStack gap={'1'} fullWidth>
                  <Text as='p' variant='bodySm'>
                    User & Email Activation
                  </Text>
                  <Stack distribution='fillEvenly'>
                    <Controller
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <Checkbox
                          label={'Active'}
                          checked={value}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                      name='isActive'
                    />
                    <Controller
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <Checkbox
                          label={'Verify Email'}
                          checked={value}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                      name='emailVerified'
                    />
                  </Stack>
                </AlphaStack>

                <AlphaStack gap={'1'}>
                  <Text as='p' variant='bodySm'>
                    Access Scopes
                  </Text>
                  <FormLayout.Group>
                    {roles.map(({ name, role, checked }, index) => (
                      <Checkbox
                        key={`role${index}`}
                        label={name}
                        name={role}
                        checked={checked}
                        value={role}
                        onChange={() => handleRoles(index)}
                      />
                    ))}
                  </FormLayout.Group>
                </AlphaStack>

                <AlphaStack gap={'1'} fullWidth>
                  <Text as='p' variant='bodySm'>
                    Address
                  </Text>

                  <Controller
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='Street Address'
                        placeholder='Enter Street Address'
                        multiline={4}
                      />
                    )}
                    name='address'
                  />

                  <Stack distribution='fillEvenly'>
                    <Controller
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          autoComplete='on'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label='City'
                          placeholder='Enter City'
                        />
                      )}
                      name='city'
                    />
                    <Controller
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          autoComplete='on'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label='State'
                          placeholder='Enter State'
                          error={errors.province ? 'Province is required' : ''}
                        />
                      )}
                      name='province'
                    />
                    <Controller
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          autoComplete='on'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label='Country'
                          placeholder='Enter Country'
                          error={errors.country ? 'Country is required' : ''}
                        />
                      )}
                      name='country'
                    />
                    <Controller
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          autoComplete='on'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label='Postal Code'
                          placeholder='Enter Postal Code'
                          error={
                            errors.postalCode ? 'Postal Code is required' : ''
                          }
                        />
                      )}
                      name='postalCode'
                    />
                  </Stack>
                </AlphaStack>
                <AlphaStack gap={'1'} fullWidth>
                  <Text as='p' variant='bodySm'>
                    Social
                  </Text>
                  <Stack distribution='fillEvenly'>
                    <Controller
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          autoComplete='on'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label='Instagram'
                          placeholder='@'
                        />
                      )}
                      name='instagram'
                    />
                    <Controller
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          autoComplete='on'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label='Twitter'
                          placeholder='@'
                        />
                      )}
                      name='twitter'
                    />
                    <Controller
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          autoComplete='on'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label='Facebook'
                          placeholder='@'
                        />
                      )}
                      name='facebook'
                    />
                    <Controller
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          autoComplete='on'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label='Website'
                          placeholder='https://example.com'
                        />
                      )}
                      name='webiste'
                    />
                  </Stack>
                </AlphaStack>
              </FormLayout>
            </AlphaCard>
          </Layout.Section>
        </Layout>
        <PageActions
          primaryAction={{
            content: 'Create',
            submit: true,
            loading: isLoading,
          }}
        />
      </Page>
    </Form>
  );
}

export default connect(null, { createStaff })(CreateStaff);
