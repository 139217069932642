import {
  EMAIL_VERIFICATION,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_ERROR,
} from "../constants";

const initialState = {
  data: null,
  isLoading: true,
  error: null,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EMAIL_VERIFICATION:
      return {
        ...state,
        data: null,
        isLoading: true,
      };
    case EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    case EMAIL_VERIFICATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    default:
      return state;
  }
}
