import React, { useEffect } from 'react';
import { Page, Layout, Button, Frame, Loading } from '@shopify/polaris';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCoupons } from '../../../AppStore/actions/coupons.actions';

import { useNavigate, useLocation } from 'react-router-dom';

import CouponsTable from '../../../Components/CouponsTable';

function Coupons({ couponsData, getCoupons }) {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getCoupons(search);
  }, [getCoupons, search]);

  return (
    <Page
      title='Coupons'
      fullWidth
      primaryAction={
        <Button
          onClick={() => navigate({ pathname: '/dashboard/coupons/create' })}
          primary
        >
          Create Coupon
        </Button>
      }
    >
      {couponsData.isLoading ? (
        <Frame>
          <Loading />
        </Frame>
      ) : (
        <Layout>
          <Layout.Section>
            <CouponsTable coupons={couponsData.data} />
          </Layout.Section>
        </Layout>
      )}
    </Page>
  );
}

Coupons.prototype = {
  couponsData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  couponsData: state.coupons,
});

export default connect(mapStateToProps, { getCoupons })(Coupons);
