import axios from "axios";
import { setAlert } from "./alert.actions";
import { SHOPDATA, SHOPDATA_ERROR } from "../constants";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getShopData = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/shop");
    dispatch({
      type: SHOPDATA,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: SHOPDATA_ERROR,
      payload: errors,
    });
  }
};

export const updateShopData = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("/api/v1/shop/update", formData, config);
    dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "critical")));
    }
  }
};
