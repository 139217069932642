import React, { useEffect, useState } from "react";
import {
  Card,
  Layout,
  Form,
  FormLayout,
  PageActions,
  Text,
} from "@shopify/polaris";
import { useForm, Controller } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import {
  getShopData,
  updateShopData,
} from "../../../../AppStore/actions/settings.actions";
import { connect } from "react-redux";

function ShopSettings({ updateShopData, getShopData, settingsData }) {
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      policy: "",
      terms: "",
    },
  });

  useEffect(() => {
    if (settingsData.data && !settingsData.loading) {
      reset({
        policy: settingsData.data.data.privacyPolicy,
        terms: settingsData.data.data.terms,
      });
    }
  }, [settingsData.data]);

  useEffect(() => {
    getShopData();
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    await updateShopData(data);
    await getShopData();
    setIsLoading(false);
  };

  return (
    <>
      <Layout sectioned>
        <Form>
          <Layout.AnnotatedSection
            title="Privacy Policy"
            description="Manage your Privacy Policy"
          >
            <Card sectioned>
              <FormLayout>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Editor
                      tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                      apiKey="ca06pp62u00hrarjbyiu9v9450i05qtlj3m7o5u8y3tg94y1"
                      cloudChannel="5-stable"
                      disabled={false}
                      inline={false}
                      onEditorChange={onChange}
                      plugins=""
                      tagName="div"
                      textareaName=""
                      toolbar=" lists link image charmap print preview anchor','searchreplace visualblocks code fullscreen','insertdatetime media table paste code help wordcount"
                      value={value}
                      init={{
                        document_base_url: "https://affiliate.nutrifactor.app",
                        height: 500,
                        language: "en",
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        advlist_bullet_styles: "default,circle,disc,square",
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help",
                      }}
                    />
                  )}
                  name="policy"
                />
                {errors.policy ? (
                  <Text as={"p"} color="critical">
                    Policy is required
                  </Text>
                ) : (
                  ""
                )}
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Terms & Conditions"
            description="Manage your Terms & Conditions"
          >
            <Card sectioned>
              <FormLayout>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Editor
                      tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                      apiKey="ca06pp62u00hrarjbyiu9v9450i05qtlj3m7o5u8y3tg94y1"
                      cloudChannel="5-stable"
                      disabled={false}
                      inline={false}
                      onEditorChange={onChange}
                      plugins=""
                      tagName="div"
                      textareaName=""
                      toolbar="advlist autolink lists link image charmap print preview anchor','searchreplace visualblocks code fullscreen','insertdatetime media table paste code help wordcount"
                      value={value}
                      init={{
                        height: 500,
                        language: "en",
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help",
                      }}
                    />
                  )}
                  name="terms"
                />
                {errors.terms ? (
                  <Text as={"p"} color="critical">
                    Terms & Conditions are required
                  </Text>
                ) : (
                  ""
                )}
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Form>
      </Layout>
      <PageActions
        primaryAction={{
          content: "Save",
          loading: isLoading,
          onAction: handleSubmit(onSubmit),
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  settingsData: state.settings,
});

export default connect(mapStateToProps, { updateShopData, getShopData })(
  ShopSettings
);
