export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const OTP_CHECK = "OTP_CHECK";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

// Dashboard
export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const DASHBOARD_DATA_ERROR = "DASHBOARD_DATA_ERROR";

//Staff
export const STAFF_DATA = "STAFF_DATA";
export const STAFF_DATA_ERROR = "STAFF_DATA_ERROR";
export const STAFF_INFO = "STAFF_INFO";
export const STAFF_INFO_ERROR = "STAFF_INFO_ERROR";

// Affiliates
export const AFFILIATES = "AFFILIATES";
export const AFFILIATES_ERROR = "AFFILIATES_ERROR";
export const AFFILIATE_DATA = "AFFILIATE_DATA";
export const AFFILIATE_DATA_ERROR = "AFFILIATE_DATA_ERROR";

// Price Rules
export const PRICERULES = "PRICERULES";
export const PRICERULES_ERROR = "PRICERULES_ERROR";
export const PRICERULE = "PRICERULE";
export const PRICERULE_ERROR = "PRICERULE_ERROR";

// Coupons
export const COUPONS = "COUPONS";
export const COUPONS_ERROR = "COUPONS_ERROR";
export const COUPON = "COUPON";
export const COUPON_ERROR = "COUPON_ERROR";

// Products & Collections
export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";

export const COLLECTION_SUCCESS = "COLLECTIONS_SUCCESS";
export const COLLECTION_ERROR = "COLLECTIONS_ERROR";

// Orders
export const ORDERS = "ORDERS";
export const ORDERS_ERROR = "ORDERS_ERROR";
export const ORDER = "ORDER";
export const ORDER_ERROR = "ORDER_ERROR";

//Payouts
export const PAYOUTS = "PAYOUTS";
export const PAYOUTS_ERROR = "PAYOUTS_ERROR";
export const PAYOUT = "PAYOUT";
export const PAYOUT_ERROR = "PAYOUT_ERROR";

// Notifications
export const NOTIFICATIONS = "NOTIFICATIONS";
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";

// Settings
export const SHOPDATA = "SHOPDATA";
export const SHOPDATA_ERROR = "SHOPDATA_ERROR";

// Templates
export const TEMPLATES = "TEMPLATES";
export const TEMPLATES_ERROR = "TEMPLATES_ERROR";

export const GET_QUERIES = "GET_QUERIES";
export const GET_QUERIES_SUCCESS = "GET_QUERIES_SUCCESS";
export const GET_QUERIES_FAILED = "GET_QUERIES_FAILED";

// Email Verification
export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_ERROR = "EMAIL_VERIFICATION_ERROR";
