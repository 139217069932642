import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { Page, Layout, Card, Tabs, Frame, Loading } from '@shopify/polaris';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getOrders } from '../../../AppStore/actions/orders.actions';

import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import OrdersTable from '../../../Components/OrdersTable';

function Orders({ ordersData, getOrders }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const tabs = [
    {
      id: 'all-orders',
      content: 'All',
      accessibilityLabel: 'All Orders',
      query: '',
    },
    {
      id: 'pending-orders',
      content: 'Pending Orders',
      query: 'P',
    },
    {
      id: 'approved-orders',
      content: 'Approved Orders',
      query: 'A',
    },
    {
      id: 'rejected-orders',
      content: 'Rejected Orders',
      query: 'R',
    },
  ];

  const handleTabChange = useCallback((selectedTabIndex) => {
    const selected = tabs[selectedTabIndex];
    const newSearchParams = new URLSearchParams({
      ...searchParamsObject,
      os: selected.query,
    });
    navigate({
      pathname,
      search: `?${newSearchParams}`,
    });
    setSelectedTab(selectedTabIndex);
  }, []);

  useEffect(() => {
    if (searchParamsObject['os']) {
      const index = tabs.findIndex(
        (item) => item.query === searchParamsObject['os']
      );
      if (index === -1) {
        setSelectedTab(0);
      } else {
        setSelectedTab(index);
      }
    }
    getOrders(search);
  }, [getOrders, search]);

  return (
    <Page fullWidth title='Orders'>
      <Layout>
        {ordersData.isLoading ? (
          <Frame>
            <Loading />
          </Frame>
        ) : (
          ''
        )}
        <Layout.Section>
          <Card>
            <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
              {!ordersData.isLoading && (
                <OrdersTable ordersData={ordersData.data} />
              )}
            </Tabs>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

Orders.prototype = {
  ordersData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ordersData: state.orders,
});

export default connect(mapStateToProps, { getOrders })(Orders);
