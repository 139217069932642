import React, { useState, useCallback, useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  Form,
  TextField,
  FormLayout,
  ChoiceList,
  PageActions,
  Select,
  Checkbox,
} from '@shopify/polaris';

import { useNavigate } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createAffiliate } from '../../../AppStore/actions/affiliates.actions';

function Create({ alerts, createAffiliate }) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      activationStatus: 'P',
      emailVerified: true,
      isActive: true,
    },
  });

  const activationStatus = watch('activationStatus');

  const handleActivationStatus = useCallback(
    (value) => setValue('activationStatus', value[0]),
    []
  );

  const onSubmit = useCallback(async (formData) => {
    setIsLoading(true);

    await createAffiliate(formData);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const checkSuccess = alerts.filter(
      ({ alertType }) => alertType === 'success'
    );

    if (checkSuccess.length) {
      reset();
    }
  }, [alerts]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Page
        title='Create Affiliate'
        breadcrumbs={[{ content: 'Affiliates', onAction: () => navigate(-1) }]}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section title='Personal Information'>
                <FormLayout>
                  <FormLayout.Group>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          label='First Name'
                          type='text'
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          requiredIndicator
                          error={
                            errors.firstName ? 'First Name is required' : ''
                          }
                        />
                      )}
                      name='firstName'
                    />
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          label='Last Name'
                          type='text'
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          requiredIndicator
                          error={errors.lastName ? 'Last Name is required' : ''}
                        />
                      )}
                      name='lastName'
                    />
                  </FormLayout.Group>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        label='Email'
                        type='text'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        requiredIndicator
                        error={errors.email ? 'Email is required' : ''}
                      />
                    )}
                    name='email'
                  />
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        label='Phone'
                        type='text'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        requiredIndicator
                        error={errors.phone ? 'Phone is required' : ''}
                      />
                    )}
                    name='phone'
                  />
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        label='Password'
                        type='password'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        requiredIndicator
                        error={errors.password ? 'Password is required' : ''}
                      />
                    )}
                    name='password'
                  />
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        label='Confirm Password'
                        type='password'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        requiredIndicator
                        error={
                          errors.confrimPassword ? 'Password is required' : ''
                        }
                      />
                    )}
                    name='confrimPassword'
                  />
                  <FormLayout.Group>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          label='Age'
                          type='text'
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          requiredIndicator
                          error={errors.age ? 'Age is required' : ''}
                        />
                      )}
                      name='age'
                    />
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          label='Gender'
                          requiredIndicator
                          error={errors.gender ? 'Gender is required' : ''}
                          options={[
                            { label: 'Select', value: '' },
                            { label: 'Male', value: 'male' },
                            { label: 'Female', value: 'female' },
                          ]}
                        ></Select>
                      )}
                      name='gender'
                    />
                  </FormLayout.Group>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        label='Education'
                        type='text'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        requiredIndicator
                        error={errors.education ? 'Education is required' : ''}
                      />
                    )}
                    name='education'
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section title='Status Management'>
                <FormLayout>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        label='Affiliate Activation Status'
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        checked={value}
                      />
                    )}
                    name='isActive'
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        label='Verify Email'
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        checked={value}
                      />
                    )}
                    name='emailVerified'
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section title='Address Information'>
                <FormLayout>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        label='Address'
                        multiline={4}
                        type='text'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        requiredIndicator
                        error={errors.address ? 'Address is required' : ''}
                      />
                    )}
                    name='address'
                  />
                  <FormLayout.Group>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          label='City'
                          type='text'
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          requiredIndicator
                          error={errors.city ? 'City is required' : ''}
                        />
                      )}
                      name='city'
                    />
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          label='Postal Code'
                          type='text'
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          requiredIndicator
                          error={errors.postalCode ? 'Postal Code is required' : ''}
                        />
                      )}
                      name='postalCode'
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          label='Province'
                          type='text'
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          requiredIndicator
                          error={errors.province ? 'Province is required' : ''}
                        />
                      )}
                      name='province'
                    />
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          label='Country'
                          type='text'
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          requiredIndicator
                          error={errors.country ? 'Country is required' : ''}
                        />
                      )}
                      name='country'
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card>
              <Card.Section title='Activation'>
                <ChoiceList
                  title='Activation Status'
                  choices={[
                    { label: 'Pending', value: 'P' },
                    { label: 'Approved', value: 'A' },
                    { label: 'Blocked', value: 'B' },
                  ]}
                  label='Active'
                  selected={[activationStatus]}
                  onChange={handleActivationStatus}
                />
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section fullWidth>
            <PageActions
              primaryAction={{
                content: 'Create',
                loading: isLoading,
                primary: true,
                onAction: handleSubmit(onSubmit),
              }}
            />
          </Layout.Section>
        </Layout>
      </Page>
    </Form>
  );
}

Create.prototype = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

export default connect(mapStateToProps, { createAffiliate })(Create);
