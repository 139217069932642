export const status = {
  P: "Pending",
  A: "Approved",
  B: "Blocked",
  R: "Rejected",
  RE: "Ready to Collect",
  PA: "Paid",
  C: "Cancelled",
  T: "Transition",
  unfulfilled: "Unfulfilled",
  fulfilled: "Fulfilled",
  cancelled: "Cancelled",
};

export const staffRoles = [
  {
    name: "Dashboard",
    role: "dashboard",
    checked: false,
  },
  {
    name: "Affiliates",
    role: "affiliates",
    checked: false,
  },
  {
    name: "Orders",
    role: "orders",
    checked: false,
  },
  {
    name: "Price Rules",
    role: "priceRules",
    checked: false,
  },
  {
    name: "Coupons",
    role: "coupons",
    checked: false,
  },
  {
    name: "Payouts",
    role: "payouts",
    checked: false,
  },
  {
    name: "Users",
    role: "users",
    checked: false,
  },
  {
    name: "Products",
    role: "products",
    checked: false,
  },
  {
    name: "Collections",
    role: "collections",
    checked: false,
  },
  {
    name: "Notifications",
    role: "notifications",
    checked: false,
  },
  {
    name: "Templates",
    role: "templates",
    checked: false,
  },
  {
    name: "Customer Query",
    role: "query",
    checked: false,
  },
];
