import axios from 'axios';
import { setAlert } from './alert.actions';
import {
  PRICERULES,
  PRICERULES_ERROR,
  PRICERULE,
  PRICERULE_ERROR,
} from '../constants';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const createPriceRule = (formData) => async (dispatch) => {
  try {
    const res = await axios.post('/api/v1/create-pricerule', formData, config);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};

export const getPriceRules = (query) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/pricerules${query ? query : ''}`);
    dispatch({
      type: PRICERULES,
      payload: res.data, //res.data.map((item) => ({ ...item, id: item._id })),
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: PRICERULES_ERROR,
      payload: errors,
    });
  }
};

export const getPriceRule = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/pricerule/${id}`);
    dispatch({
      type: PRICERULE,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: PRICERULE_ERROR,
      payload: errors,
    });
  }
};

export const updatePriceRule = (formData) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v1/update-pricerule', formData, config);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};

export const deletePriceRule = () => async (dispatch) => {
  try {
    const res = await axios.delete('/api/v1/delete-pricerule');
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
