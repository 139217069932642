import axios from 'axios';
import { setAlert } from './alert.actions';
import { ORDERS, ORDERS_ERROR, ORDER, ORDER_ERROR } from '../constants';

export const getOrders = (query) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/orders${query ? query : ''}`);

    dispatch({
      type: ORDERS,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: ORDERS_ERROR,
      payload: errors,
    });
  }
};

export const getOrderById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/order/${id}`);
    dispatch({
      type: ORDER,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: ORDER_ERROR,
      payload: errors,
    });
  }
};

export const acceptOrderById = (id) => async (dispatch) => {
  try {
    const res = await axios.patch(`/api/v1/approve-order/${id}`);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
export const rejectOrderById = (id) => async (dispatch) => {
  try {
    const res = await axios.patch(`/api/v1/reject-order/${id}`);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
export const revokeOrderById = (id) => async (dispatch) => {
  try {
    const res = await axios.patch(`/api/v1/revoke-order/${id}`);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
