import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '@shopify/polaris';
import ShopSettings from './settingsComponents/ShopSettings';

function Settings() {
  const navigate = useNavigate();

  return (
    <Page
      title='Settings'
      subtitle=''
      breadcrumbs={[{ content: 'Coupons', onAction: () => navigate(-1) }]}
      divider
    >
      <ShopSettings />
    </Page>
  );
}

export default Settings;
