import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import {
  IndexTable,
  Card,
  Filters,
  useIndexResourceState,
  Text,
  EmptySearchResult,
  FormLayout,
  RadioButton,
  Badge,
} from '@shopify/polaris';

import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link,
} from 'react-router-dom';

import Pagination from './Pagination';

function Table({ priceRules }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resourceName = {
    singular: 'Price Rule',
    plural: 'Price Rules',
  };

  const timeOutId = useRef(null);

  const [status, setStatus] = useState('');

  const resourceIDResolver = (data) => {
    return data._id;
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(priceRules.data, {
    resourceIDResolver,
  });
  const [queryValue, setQueryValue] = useState('');

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const handleStatusRemove = useCallback(() => {
    setStatus(null);
    delete searchParamsObject.status;

    const newSearchParams = new URLSearchParams({
      ...searchParamsObject,
    });

    navigate({
      pathname,
      search: `?${newSearchParams}`,
    });
  }, [searchParamsObject]);

  const handleQueryChange = useCallback(
    (query) => {
      setQueryValue(query);

      let newSearchParams = {};

      if (!query) {
        delete searchParamsObject.query;
        newSearchParams = new URLSearchParams({
          ...searchParamsObject,
        });

        return navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }

      newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        query,
      });

      clearTimeout(timeOutId);
      timeOutId.current = setTimeout(async () => {
        navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }, 100);
    },
    [search, searchParamsObject]
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);

  const handleClearAll = useCallback(() => {
    handleStatusRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleStatusRemove]);

  const handleStatusChange = useCallback(
    (_checked, newValue) => {
      setStatus(newValue);

      const newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        status: newValue,
      });

      navigate({
        pathname,
        search: `?${newSearchParams}`,
      });
    },
    [searchParamsObject]
  );

  const filters = [
    {
      key: 'status',
      label: 'Status',
      filter: (
        <FormLayout>
          <RadioButton
            id='active'
            label='Active'
            checked={status === 'active'}
            name='status'
            onChange={handleStatusChange}
          />
          <RadioButton
            id='expired'
            label='Expired'
            checked={status === 'expired'}
            name='status'
            onChange={handleStatusChange}
          />
        </FormLayout>
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = !isEmpty(status)
    ? [
        {
          key: 'status',
          label: disambiguateLabel('status', status),
          onRemove: handleStatusRemove,
        },
      ]
    : [];

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No Data Found'}
      description={'Try changing the filters or search term'}
      withIllustration
    />
  );

  useEffect(() => {
    if (searchParamsObject['status']) {
      setStatus(searchParamsObject['status']);
    }
    if (searchParamsObject['query']) {
      setQueryValue(searchParamsObject['query']);
    }
  }, [searchParamsObject]);

  const rowMarkup = useMemo(() => {
    return priceRules.data.map((item, index) => (
      <IndexTable.Row
        key={item._id}
        id={item._id.toString()}
        position={index}
        selected={selectedResources.includes(item._id.toString())}
      >
        <IndexTable.Cell>
          <Link
            to={`${pathname}/${item._id}`}
            style={{ textDecoration: 'none', color: 'unset' }}
          >
            <Text variant='bodyMd' fontWeight='bold' as='span'>
              {item.priceRuleTitle}
            </Text>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text>
            {item.valueType === 'percentage' ? `${item.value}%` : item.value}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text>{item.valueType}</Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text>
            {new Date() > new Date(item.endDate) ? (
              <Badge size='small'>Expired</Badge>
            ) : (
              <Badge status='success' size='small'>
                Active
              </Badge>
            )}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {`${new Date(item.startDate).toLocaleTimeString()} ${new Date(
            item.startDate
          ).toLocaleDateString()}`}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {item.endDate
            ? `${new Date(item.endDate).toLocaleTimeString()} ${new Date(
                item.endDate
              ).toLocaleDateString()} `
            : ''}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {`${new Date(item.startDate).toLocaleTimeString()}  ${new Date(
            item.createdAt
          ).toLocaleDateString()}`}
        </IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [priceRules, selectedResources]);

  return (
    <Card>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            queryPlaceholder='Search Price Rules'
            appliedFilters={appliedFilters}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
      </div>
      <IndexTable
        selectable={false}
        resourceName={resourceName}
        itemCount={priceRules.data.length}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        hasMoreItems
        headings={[
          { title: 'Name' },
          { title: 'Value' },
          { title: 'Type' },
          { title: 'Status' },
          { title: 'Start Date' },
          { title: 'End Date' },
          { title: 'Created At' },
        ]}
        emptyState={emptyStateMarkup}
      >
        {rowMarkup}
      </IndexTable>
      <Pagination totalRecords={priceRules.totalRecords} />
    </Card>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'status':
        return `${key}: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}

export default Table;
