import axios from 'axios';
import {
  STAFF_DATA,
  STAFF_DATA_ERROR,
  STAFF_INFO,
  STAFF_INFO_ERROR,
} from '../constants';
import { setAlert } from './alert.actions';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const createStaff = (formData) => async (dispatch) => {
  try {
    const res = await axios.post('/api/v1/user/create', formData, config);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};

export const getStaff = (query) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/users${query ? query : ''}`);
    dispatch({
      type: STAFF_DATA,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: STAFF_DATA_ERROR,
      payload: errors,
    });
  }
};

export const getStaffById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/user/${id}`);
    dispatch({
      type: STAFF_INFO,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: STAFF_INFO_ERROR,
      payload: errors,
    });
  }
};

export const updateStaff = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/user/update`, formData, config);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
export const handleUserActivation = (id) => async (dispatch) => {
  try {
    const res = await axios.patch(`/api/v1/user/activation/${id}`);

    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
export const changeEmailAdress = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/user/email/change`, formData, config);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
export const changePassword = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/v1/user/password/change`,
      formData,
      config
    );
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
