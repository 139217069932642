import React from 'react';
import { useState, useEffect } from 'react';
import {
  AlphaCard,
  Bleed,
  Divider,
  FormLayout,
  Layout,
  Page,
  Text,
  TextField,
  AlphaStack,
  ButtonGroup,
  Button,
  Form,
  Badge,
  Stack,
} from '@shopify/polaris';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  updateProfile,
  loadUser,
} from '../../../AppStore/actions/auth.actions';
import ProfilePass from '../../../Components/Atoms/User/ProfilePass';

import { staffRoles } from '../../../utils/data';
const Profile = ({ alerts, auth, updateProfile, loadUser }) => {
  const [formLoading, setFormLoading] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      roles: staffRoles,
      isActive: true,
    },
  });

  const isActive = watch('isActive');
  const email = watch('email');

  const onSubmit = async (data) => {
    const {
      firstName,
      lastName,
      city,
      province,
      address,
      country,
      postalCode,
      instagram,
      facebook,
      twitter,
      website,
    } = data;

    const formData = {
      firstName,
      lastName,
      city,
      province,
      address,
      country,
      postalCode,
      social: {
        instagram,
        facebook,
        twitter,
        website,
      },
    };

    setFormLoading(true);
    await updateProfile(formData);
    await loadUser();
    setFormLoading(false);
  };
  useEffect(() => {
    if (auth.user) {
      const {
        firstName,
        lastName,
        email,
        address,
        gender,
        isActive,
        role,
        city,
        province,
        country,
        postalCode,
        social,
      } = auth.user;

      reset({
        firstName,
        lastName,
        email,
        address,
        gender,
        isActive,
        role,
        city,
        province,
        country,
        postalCode,
        facebook: social ? social.facebook : '',
        twitter: social ? social.twitter : '',
        instagram: social ? social.instagram : '',
        website: social ? social.website : '',
      });
    }
  }, [auth]);

  return (
    <Page
      title={`Update Profile (${email})`}
      titleMetadata={<Badge>{auth.user.role}</Badge>}
      breadcrumbs={[{ onAction: () => navigate(-1) }]}
    >
      <Layout>
        <Layout.Section>
          <AlphaCard>
            <Stack distribution='equalSpacing'>
              <Stack.Item>
                <Text as='h4' variant='headingMd'>
                  Details
                </Text>
              </Stack.Item>
              <Stack.Item>
                {isActive ? (
                  <Badge status='success'>Active</Badge>
                ) : (
                  <Badge status='critical'>Blocked</Badge>
                )}
              </Stack.Item>
            </Stack>

            <Bleed marginInline={'5'}>
              <div style={{ paddingBlock: 15 }}>
                <Divider />
              </div>
            </Bleed>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormLayout>
                <FormLayout.Group>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        label='First Name'
                        error={errors.firstName ? 'First Name is required' : ''}
                      />
                    )}
                    name='firstName'
                  />
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='Last Name'
                        error={errors.lastName ? 'Last Name is required' : ''}
                      />
                    )}
                    name='lastName'
                  />
                </FormLayout.Group>
                <Bleed marginInline={'5'}>
                  <Divider />
                </Bleed>

                {/* <Text as='p' variant='bodySm'>
                  Email
                </Text>
                <Text as='p' variant='bodyMd'>
                  {email}
                </Text> */}
                {/* <Bleed marginInline={'5'}>
                  <Divider />
                </Bleed> */}
                <Text as='p' variant='bodySm'>
                  Address
                </Text>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      autoComplete='on'
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      label='Street Address'
                      placeholder='Enter Street Address'
                      multiline={4}
                      error={errors.address ? 'Address is required' : ''}
                    />
                  )}
                  name='address'
                />

                <FormLayout.Group>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='City'
                        placeholder='Enter City'
                        error={errors.city ? 'City is required' : ''}
                      />
                    )}
                    name='city'
                  />
                  <Controller
                    control={control}
                    // rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='State'
                        placeholder='Enter State'
                        error={errors.province ? 'Province is required' : ''}
                      />
                    )}
                    name='province'
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  <Controller
                    control={control}
                    // rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='Country'
                        placeholder='Enter Country'
                        error={errors.country ? 'Country is required' : ''}
                      />
                    )}
                    name='country'
                  />
                  <Controller
                    control={control}
                    // rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='Postal Code'
                        placeholder='Enter Postal Code'
                        error={
                          errors.postalCode ? 'Postal Code is required' : ''
                        }
                      />
                    )}
                    name='postalCode'
                  />
                </FormLayout.Group>

                <Bleed marginInline={'5'}>
                  <Divider />
                </Bleed>
                <Text as='p' variant='bodySm'>
                  Social
                </Text>

                <FormLayout.Group>
                  <Controller
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='Instagram'
                        placeholder='@'
                      />
                    )}
                    name='instagram'
                  />
                  <Controller
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='Twitter'
                        placeholder='@'
                      />
                    )}
                    name='twitter'
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Controller
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='Facebook'
                        placeholder='@'
                      />
                    )}
                    name='facebook'
                  />
                  <Controller
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoComplete='on'
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label='Website'
                        placeholder='https://example.com'
                      />
                    )}
                    name='website'
                  />
                </FormLayout.Group>

                <Bleed marginInline={'5'}>
                  <Divider />
                </Bleed>
                <AlphaStack align='end'>
                  <ButtonGroup>
                    <Button primary loading={formLoading} submit>
                      Save
                    </Button>
                    <Button onClick={() => navigate(-1)}>Cancel</Button>
                  </ButtonGroup>
                </AlphaStack>
              </FormLayout>
            </Form>
          </AlphaCard>
        </Layout.Section>
        <Layout.Section secondary>
          <ProfilePass />
        </Layout.Section>
      </Layout>
      <br />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  alerts: state.alerts,
});

export default connect(mapStateToProps, { updateProfile, loadUser })(Profile);
