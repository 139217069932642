import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  ResourceList,
  Thumbnail,
  ResourceItem,
  Text,
  Modal,
  Stack,
  TextField,
  Icon,
} from "@shopify/polaris";

import { SearchMinor, ImageMajor } from "@shopify/polaris-icons";
import {
  getProducts,
  getCollections,
} from "../../AppStore/actions/productsncollections";

import { connect } from "react-redux";

function ProductsnCollections({
  isModal,
  setIsModal,
  type,
  products,
  collections,
  selectedProducts,
  selectedCollections,
  setValue,
  getProducts,
  getCollections,
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchProducts, setSearchProducts] = useState("");
  const [searchCollections, setSearchCollections] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const productsResouce = {
    singular: "product",
    plural: "products",
  };

  const collectionsResouce = {
    singular: "collection",
    plural: "collections",
  };

  const selectedType = useMemo(() => {
    if (type === "products") {
      return productsResouce;
    } else if (type === "collections") {
      return collectionsResouce;
    }
  }, [type]);

  const handleModal = useCallback(() => {
    setIsModal(!isModal);
    setSelectedItems([]);
  }, [isModal]);

  const handleProductsSelection = useCallback(() => {
    const getUniqueArray = (arr) => [...new Set(arr.map((item) => item))];

    if (type === "products") {
      const selectedArray = products.filter(({ id }) =>
        selectedItems.includes(id)
      );

      setValue(
        "products",
        getUniqueArray([...selectedProducts, ...selectedArray])
      );
    } else if (type === "collections") {
      const selectedArray = collections.filter(({ id }) =>
        selectedItems.includes(id)
      );

      setValue(
        "collections",
        getUniqueArray([...selectedCollections, ...selectedArray])
      );
    }
    setSelectedItems([]);
    handleModal();
  }, [selectedItems, type]);

  const handleProductsSearch = useCallback(
    async (query) => {
      setIsSearch(true);
      clearTimeout(searchTimeout);
      setSearchProducts(query);
      const timeoutId = setTimeout(async () => {
        await getProducts({
          query,
        });
        setIsSearch(false);
      }, 500);

      setSearchTimeout(timeoutId);
    },
    [searchTimeout]
  );

  const handleCollectionsSearch = useCallback(
    async (query) => {
      setIsSearch(true);
      clearTimeout(searchTimeout);
      setSearchCollections(query);
      const timeoutId = setTimeout(async () => {
        await getCollections({
          query,
        });
        setIsSearch(false);
      }, 500);
      setSearchTimeout(timeoutId);
    },
    [searchTimeout]
  );

  useEffect(() => {
    if (type === "products") {
      setSelectedItems([...selectedProducts.map(({ productId }) => productId)]);
    } else {
      setSelectedItems([
        ...selectedCollections.map(({ collectionId }) => collectionId),
      ]);
    }
  }, [type]);

  return (
    <Modal
      open={isModal}
      onClose={handleModal}
      title={
        type === "products"
          ? "Add Products"
          : type === "collections"
          ? "Add Collections"
          : ""
      }
      primaryAction={{
        content: "Add",
        onAction: handleProductsSelection,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleModal,
        },
      ]}
      footer={
        selectedItems.length
          ? selectedItems.length > 1
            ? `${selectedItems.length} ${selectedType.plural} selected`
            : `${selectedItems.length} ${selectedType.singular} selected`
          : ""
      }
    >
      {type === "products" ? (
        <ResourceList
          filterControl={
            <TextField
              placeholder='Search Products'
              prefix={<Icon source={SearchMinor} />}
              value={searchProducts}
              onChange={handleProductsSearch}
            />
          }
          resourceName={productsResouce}
          items={products}
          renderItem={renderProducts}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          selectable
          loading={isSearch}
        />
      ) : type === "collections" ? (
        <ResourceList
          filterControl={
            <TextField
              placeholder='Search Collections'
              prefix={<Icon source={SearchMinor} />}
              value={searchCollections}
              onChange={handleCollectionsSearch}
            />
          }
          resourceName={collectionsResouce}
          items={collections}
          renderItem={renderCollections}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          selectable
          loading={isSearch}
        />
      ) : (
        ""
      )}
    </Modal>
  );

  function renderProducts(item) {
    const { id, image, productTitle } = item;
    const media = (
      <Thumbnail
        source={image ? image : ImageMajor}
        size='small'
        alt='Black choker necklace'
      />
    );

    return (
      <ResourceItem
        id={id}
        key={id}
        verticalAlignment='center'
        media={media}
        accessibilityLabel={`View details for ${productTitle}`}
      >
        <Stack>
          <Stack.Item>
            <Text variant='bodyMd' fontWeight='bold' as='h3'>
              {productTitle}
            </Text>
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  }
  function renderCollections(item) {
    const { id, image, collectionName } = item;
    const media = (
      <Thumbnail
        source={image ? image : ImageMajor}
        size='small'
        alt='Black choker necklace'
      />
    );

    return (
      <ResourceItem
        id={id}
        key={id}
        verticalAlignment='center'
        media={media}
        accessibilityLabel={`View details for ${collectionName}`}
      >
        <Stack>
          <Stack.Item>
            <Text variant='bodyMd' fontWeight='bold' as='h3'>
              {collectionName}
            </Text>
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  }
}

export default connect(null, {
  getProducts,
  getCollections,
})(ProductsnCollections);
