import React, { useState } from 'react';
import { Card, Form, Stack, TextField, Button } from '@shopify/polaris';

import { useForm, Controller } from 'react-hook-form';

import { useParams } from 'react-router-dom';

function StaffPass({ changePassword }) {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const onSubmit = async (data) => {
    data.id = id;
    setIsLoading(true);
    await changePassword(data);
    setIsLoading(false);
    reset({});
  };

  return (
    <Card>
      <Card.Section title='Change Password'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Stack vertical spacing='tight'>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onBlur, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  type='password'
                  placeholder='Enter New Password'
                  error={errors.password ? 'Password cannot be empty' : ''}
                />
              )}
              name='password'
            />
            <Button primary submit loading={isLoading}>
              Save
            </Button>
          </Stack>
        </Form>
      </Card.Section>
    </Card>
  );
}

export default StaffPass;
