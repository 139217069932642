import axios from 'axios';
import { setAlert } from './alert.actions';
import { PAYOUTS, PAYOUTS_ERROR, PAYOUT, PAYOUT_ERROR } from '../constants';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getPayouts = (query) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/payouts${query ? query : ''}`);
    dispatch({
      type: PAYOUTS,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: PAYOUTS_ERROR,
      payload: errors,
    });
  }
};

export const getPayoutById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/payout/${id}`);
    dispatch({
      type: PAYOUT,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: PAYOUT_ERROR,
      payload: errors,
    });
  }
};

export const markPaid = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/v1/payout/mark/paid`,
      formData,
      config
    );
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};

export const processPayment = (formData) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v1/payout/process', formData, config);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
