import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  Form,
  FormLayout,
  Modal,
  Text,
  Stack,
  TextField,
  Button,
  ButtonGroup,
  Icon,
  Autocomplete,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { addNotification } from '../../AppStore/actions/notifications.actions';
import { getAllAffiliates } from '../../AppStore/actions/affiliates.actions';

const notificationModal = ({
  affiliatesData,
  handleModalChange,
  modalActive,
  addNotification,
  getAllAffiliates,
}) => {
  const [selectedBtn, setSelectedBtn] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notificationTitle: '',
      notificationType: 'General',
      notificationBody: '',
    },
  });
  const onSubmit = async (data) => {
    setNotificationLoading(true);
    await addNotification(data);
    setNotificationLoading(false);
  };

  useEffect(() => {
    if (selectedBtn) {
      setValue('notificationType', 'G');
    } else {
      setValue('notificationType', 'AF');
    }
  }, [selectedBtn]);

  const handleSelectedBtn = useCallback(() => {
    setSelectedBtn(true);
  }, [selectedBtn]);
  const handleSelectedBtnSpecific = useCallback(() => {
    setSelectedBtn(false);
  }, [selectedBtn]);

  const affiliates = useMemo(() => {
    if (!affiliatesData.isLoading && affiliatesData.data) {
      return affiliatesData.data.data.map((item) => ({
        value: item._id,
        label: `${item.firstName} ${item.lastName} (${item.email})`,
        email: item.email,
      }));
    } else {
      return [];
    }
  }, [affiliatesData]);

  const updateText = useCallback(
    async (value) => {
      setInputValue(value);

      if (!loading) {
        setLoading(true);
      }

      if (value === '') {
        await getAllAffiliates();
        setLoading(false);
        return;
      }

      setTimeout(async () => {
        await getAllAffiliates({ query: value });
        setLoading(false);
      }, 300);
    },
    [affiliates, loading]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedObject = affiliates.find((option) => {
        return selected.includes(option.value);
      });
      setSelectedOptions(selected);
      setInputValue(selectedObject.label);
      setValue('recipient', selectedObject.value);
    },
    [affiliates]
  );

  useEffect(() => {
    getAllAffiliates();
  }, []);

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label='Select Affiliate'
      value={inputValue}
      prefix={<Icon source={SearchMinor} color='base' />}
      placeholder='Search Affiliate'
    />
  );

  return (
    <>
      <Modal
        open={modalActive}
        onClose={handleModalChange}
        title='Create new notification'
        primaryAction={{
          content: 'Create',
          loading: notificationLoading,
          onAction: handleSubmit(onSubmit),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <Form>
            <FormLayout>
              <Stack alignment='center'>
                <Text variant='bodyLg'>Send to</Text>
                <ButtonGroup segmented>
                  <Button onClick={handleSelectedBtn} pressed={selectedBtn}>
                    All
                  </Button>
                  <Button
                    onClick={handleSelectedBtnSpecific}
                    pressed={!selectedBtn}
                  >
                    Specific
                  </Button>
                </ButtonGroup>
              </Stack>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label='Title'
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    requiredIndicator
                    error={errors.notificationTitle ? 'Title is required' : ''}
                  />
                )}
                name='notificationTitle'
              />
              {/* {!selectedBtn ? (
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      label='Recipient'
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors.recipient ? 'Recipient is required' : ''}
                    />
                  )}
                  name='recipient'
                />
              ) : (
                ''
              )} */}

              <Autocomplete
                options={affiliates}
                selected={selectedOptions}
                onSelect={updateSelection}
                loading={loading}
                textField={textField}
              />

              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label='Message'
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    requiredIndicator
                    multiline={3}
                    error={errors.notificationBody ? 'Message is required' : ''}
                  />
                )}
                name='notificationBody'
              />
            </FormLayout>
          </Form>
        </Modal.Section>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  affiliatesData: state.affiliates,
});

export default connect(mapStateToProps, { addNotification, getAllAffiliates })(
  notificationModal
);
