import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  IndexTable,
  Card,
  Filters,
  useIndexResourceState,
  ChoiceList,
  Badge,
  Link,
} from '@shopify/polaris';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Pagination from './Pagination';

import { status } from '../utils/data';

function AffiliatesTable({ affiliates }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resourceName = {
    singular: 'affiliate',
    plural: 'affiliates',
  };

  const [affiliateStatus, setAffiliateStatus] = useState('');

  const [timeOutId, setTimeoutId] = useState(null);

  const resourceIDResolver = (data) => {
    return data._id;
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(affiliates.data, {
    resourceIDResolver,
  });

  const [queryValue, setQueryValue] = useState('');

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const handleTypeRemove = useCallback(() => {
    setAffiliateStatus('');
    delete searchParamsObject.status;

    const newSearchParams = new URLSearchParams({
      ...searchParamsObject,
    });

    navigate({
      pathname,
      search: `?${newSearchParams}`,
    });
  }, [searchParamsObject]);

  const handleQueryChange = useCallback(
    (query) => {
      setQueryValue(query);

      let newSearchParams = {};

      if (!query) {
        delete searchParamsObject.query;
        newSearchParams = new URLSearchParams({
          ...searchParamsObject,
        });

        return navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }

      newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        query,
      });

      clearTimeout(timeOutId);
      const id = setTimeout(async () => {
        navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }, 500);

      setTimeoutId(id);
    },
    [search, searchParamsObject, timeOutId]
  );
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue('');
    searchParams.delete('query');

    navigate({
      pathname,
      search: `?${searchParams}`,
    });
  }, [searchParams]);

  const handleClearAll = useCallback(() => {
    handleTypeRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTypeRemove]);

  const handleStatusChange = useCallback(
    (newValue) => {
      const newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        status: newValue,
      });

      navigate({
        pathname,
        search: `?${newSearchParams}`,
      });
    },
    [searchParamsObject]
  );

  const filters = [
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title='Order Status'
          choices={[
            { label: 'Pending', value: 'P' },
            { label: 'Approved', value: 'A' },
            { label: 'Blocked', value: 'B' },
          ]}
          selected={affiliateStatus}
          onChange={handleStatusChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = !isEmpty(affiliateStatus)
    ? [
        {
          key: 'status',
          label: disambiguateLabel('status', status[affiliateStatus[0]]),
          onRemove: handleTypeRemove,
        },
      ]
    : [];

  const getStatus = useCallback((s) => {
    if (status[s]) {
      if (s === 'P') {
        return <Badge size='small'>{status[s]}</Badge>;
      } else if (s === 'A' || s === 'RE' || s === 'PA' || s === 'fulfilled') {
        return (
          <Badge size='small' status='success'>
            {status[s]}
          </Badge>
        );
      } else if (s === 'R' || s === 'C' || s === 'B' || s === 'cancelled') {
        return (
          <Badge size='small' status='critical'>
            {status[s]}
          </Badge>
        );
      } else {
        return <Badge size='small'>{status[s]}</Badge>;
      }
    } else {
      return <Badge size='small'>{s}</Badge>;
    }
  }, []);

  const rowMarkup = affiliates.data.map(
    (
      {
        _id,
        firstName,
        lastName,
        totalOrders,
        approvedOrders,
        commissionEarned,
        activationStatus,
        email,
        createdAt,
        updatedAt,
      },
      index
    ) => (
      <IndexTable.Row
        id={_id}
        key={_id}
        selected={selectedResources.includes(_id)}
        position={index}
      >
        <IndexTable.Cell>
          <Link
            removeUnderline
            onClick={() => navigate(`/dashboard/affiliates/${_id}`)}
          >
            {firstName} {lastName}
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>{email}</IndexTable.Cell>
        <IndexTable.Cell>{totalOrders}</IndexTable.Cell>
        <IndexTable.Cell>{approvedOrders}</IndexTable.Cell>
        <IndexTable.Cell>{commissionEarned}</IndexTable.Cell>
        <IndexTable.Cell>{getStatus(activationStatus)}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  useEffect(() => {
    if (searchParamsObject.status) {
      setAffiliateStatus([searchParamsObject.status]);
    }
    if (searchParams.get('query')) {
      setQueryValue(searchParams.get('query'));
    }
  }, [searchParamsObject]);

  return (
    <Card>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            queryPlaceholder='Search Affiliates'
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        itemCount={affiliates.data.length}
        selectable={false}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        hasMoreItems
        headings={[
          { title: 'Full Name' },
          { title: 'Email' },
          { title: 'Total Orders' },
          { title: 'Approved Orders' },
          { title: 'Commission Earned' },
          { title: 'Activation Status' },
        ]}
      >
        {rowMarkup}
      </IndexTable>
      <Pagination totalRecords={affiliates.totalRecords} />
    </Card>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'status':
        return `${key}: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}

export default AffiliatesTable;
