import React, { useState, useCallback, useEffect, useMemo } from "react";

import {
  Page,
  PageActions,
  Layout,
  Card,
  Form,
  FormLayout,
  TextField,
  Button,
  ButtonGroup,
  Stack,
  RadioButton,
  Icon,
  Checkbox,
  ResourceList,
  ResourceItem,
  Thumbnail,
  Popover,
  List,
  AlphaStack,
  Text,
} from "@shopify/polaris";

import {
  SearchMinor,
  CalendarMajor,
  ClockMajor,
  CancelSmallMinor,
  ImageMajor,
} from "@shopify/polaris-icons";

import { useNavigate } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getProducts,
  getCollections,
} from "../../../AppStore/actions/productsncollections";

import { getPriceRule } from "../../../AppStore/actions/pricerules.actions";

import ProductsnCollections from "../../../Components/Modal/PAndC";
import DatePicker from "../../../Components/DatePicker";

import { useParams } from "react-router-dom";

import { updatePriceRule } from "../../../AppStore/actions/pricerules.actions";

function PriceRule({
  products,
  collections,
  getProducts,
  getCollections,
  priceRuleData,
  getPriceRule,
  updatePriceRule,
}) {
  const navigate = useNavigate();

  const { id } = useParams();

  const [isModal, setIsModal] = useState(false);
  const [isPopover, setIsPopover] = useState(false);
  const [isEndPopover, setIsEndPopover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const productsData = useMemo(() => {
    if (!products.isLoading && products.data) {
      return products.data.data.edges.map((item) => ({
        id: item.node.id,
        productTitle: item.node.title,
        image: item.node.images.edges.length
          ? item.node.images.edges[0].node.originalSrc
          : "",
        url: item.node.onlineStorePreviewUrl,
      }));
    } else {
      return [];
    }
  }, [products]);

  const collectionsData = useMemo(() => {
    if (!collections.isLoading && collections.data) {
      return collections.data.data.edges.map((item) => ({
        id: item.node.id,
        collectionName: item.node.title,
      }));
    } else {
      return [];
    }
  }, [collections]);

  const currentDate = new Date();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      enddate: `${currentDate.getFullYear()}-${String(
        currentDate.getMonth() + 1
      ).padStart(2, 0)}-${String(currentDate.getDate()).padStart(2, 0)}`,
    },
  });
  const isPercentage = watch("isPercentage");
  const priceRuleTitle = watch("priceRuleTitle");
  const type = watch("type");
  const value = watch("value");
  const selectedProducts = watch("products");
  const selectedCollections = watch("collections");
  const startDate = watch("startdate");
  const endDate = watch("enddate");
  const isEndDate = watch("isEndDate");

  const setPercentage = useCallback(() => setValue("isPercentage", true), []);
  const setFalt = useCallback(() => setValue("isPercentage", false), []);

  const handlePriceRuleType = useCallback(
    (_checked, newValue) => setValue("type", newValue),
    []
  );

  const handleModal = useCallback(() => setIsModal(true), [isModal]);
  const handleStartDatePopover = useCallback(() => setIsPopover(!isPopover), [
    isPopover,
  ]);
  const handleEndDatePopover = useCallback(
    () => setIsEndPopover(!isEndPopover),
    [isEndPopover]
  );

  const removeProduct = useCallback(
    (unselect) => {
      setValue(
        "products",
        selectedProducts.filter(({ id }) => id !== unselect)
      );
    },
    [selectedProducts]
  );

  const removeCollection = useCallback(
    (unselect) =>
      setValue(
        "collections",
        selectedCollections.filter(({ id }) => id !== unselect)
      ),
    [selectedCollections]
  );

  useEffect(() => {
    if (type === "products") {
      setValue("collections", []);
      getProducts();
    } else if (type === "collections") {
      setValue("products", []);
      getCollections();
    }
  }, [type]);

  const onSubmit = async (data) => {
    const {
      isPercentage,
      priceRuleTitle,
      products,
      collections,
      type,
      value,
      startTime,
      startdate,
      endtime,
      enddate,
    } = data;

    const formData = {
      id,
      priceRuleTitle,
      products,
      collections,
      type,
      valueType: isPercentage ? "percentage" : "fixed_amount",
      value,
      startDate: new Date(`${startdate} ${startTime}`),
      endDate: isEndDate ? new Date(`${enddate} ${endtime}`) : null,
    };

    setIsLoading(true);
    await updatePriceRule(formData);
    await getPriceRule(id);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!priceRuleData.isLoading && priceRuleData.data) {
      if (priceRuleData.data.data) {
        const {
          priceRuleTitle,
          valueType,
          value,
          products,
          collections,
          itemType,
          startDate,
          endDate,
        } = priceRuleData.data.data;

        const sdate = new Date(startDate);
        const edate = endDate ? new Date(endDate) : "";

        setValue("isPercentage", valueType === "percentage" ? true : false);

        const formatedProducts = products.map((item) => ({
          ...item,
          id: `gid://shopify/Product/${item.productId}`,
        }));
        const formatedCollections = collections.map((item) => ({
          ...item,
          id: `gid://shopify/Collection/${item.collectionId}`,
        }));

        reset({
          priceRuleTitle,
          isPercentage: valueType === "percentage" ? true : false,
          value,
          type: itemType,
          products: formatedProducts,
          collections: formatedCollections,
          isEndDate: edate ? true : false,
          startdate: `${sdate.getFullYear()}-${String(
            sdate.getMonth() + 1
          ).padStart(2, 0)}-${String(sdate.getDate()).padStart(2, 0)}`,
          startTime: `${String(sdate.getHours()).padStart(2, 0)}:${String(
            sdate.getMinutes()
          ).padStart(2, 0)}`,
          enddate: edate
            ? `${edate.getFullYear()}-${String(edate.getMonth() + 1).padStart(
                2,
                0
              )}-${String(edate.getDate()).padStart(2, 0)}`
            : `${currentDate.getFullYear()}-${String(
                currentDate.getMonth() + 1
              ).padStart(2, 0)}-${String(currentDate.getDate()).padStart(
                2,
                0
              )}`,
          endtime: edate
            ? `${String(edate.getHours()).padStart(2, 0)}:${String(
                edate.getMinutes()
              ).padStart(2, 0)}`
            : `${String(currentDate.getHours()).padStart(2, 0)}:${String(
                currentDate.getMinutes()
              ).padStart(2, 0)}`,
        });
      }
    }
  }, [priceRuleData]);

  useEffect(() => {
    getPriceRule(id);
  }, [id]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Page
        breadcrumbs={[{ onAction: () => navigate(-1) }]}
        title={"Update Price Rule"}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      label='Title'
                      type='text'
                      onBlur={onBlur}
                      onChange={onChange}
                      error={
                        errors.priceRuleTitle
                          ? "Price rule Title is required"
                          : ""
                      }
                      value={value}
                    />
                  )}
                  name='priceRuleTitle'
                />
              </Card.Section>
            </Card>
            <Card title={"Value"}>
              <Card.Section>
                {isPercentage ? (
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        type='text'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        suffix={"%"}
                        error={errors.value ? "Value is required" : ""}
                        connectedRight={
                          <ButtonGroup segmented>
                            <Button pressed={true} onClick={setPercentage}>
                              Percentage
                            </Button>
                            <Button pressed={false} onClick={setFalt}>
                              Fixed amount
                            </Button>
                          </ButtonGroup>
                        }
                      />
                    )}
                    name='value'
                  />
                ) : (
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        type='text'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        prefix='Rs'
                        error={errors.value ? "Value is required" : ""}
                        connectedRight={
                          <ButtonGroup segmented>
                            <Button pressed={false} onClick={setPercentage}>
                              Percentage
                            </Button>
                            <Button pressed={true} onClick={setFalt}>
                              Fixed amount
                            </Button>
                          </ButtonGroup>
                        }
                      />
                    )}
                    name='value'
                  />
                )}
              </Card.Section>
              <Card.Section title={"Applies to"}>
                <Stack vertical distribution='fill'>
                  <Stack.Item>
                    <Stack vertical spacing={"none"}>
                      <RadioButton
                        label='Specific collections'
                        name='type'
                        checked={type === "collections"}
                        onChange={handlePriceRuleType}
                        id={"collections"}
                      />
                      <RadioButton
                        label='Specific products'
                        name='type'
                        onChange={handlePriceRuleType}
                        checked={type === "products"}
                        id={"products"}
                      />
                    </Stack>
                  </Stack.Item>
                  {type ? (
                    <>
                      <Stack.Item>
                        <TextField
                          prefix={<Icon source={SearchMinor} />}
                          type='text'
                          placeholder='Search Products'
                          onChange={handleModal}
                          connectedRight={
                            <Button onClick={handleModal}>Browse</Button>
                          }
                        />
                        {type === "products" ? (
                          <ResourceList
                            resourceName={{
                              singular: "product",
                              plural: "products",
                            }}
                            items={selectedProducts}
                            renderItem={(item) => {
                              const { id, productTitle, image } = item;
                              const media = (
                                <Thumbnail
                                  source={image ? image : ImageMajor}
                                  alt='Product Image'
                                  size='small'
                                />
                              );
                              return (
                                <ResourceItem
                                  id={id}
                                  media={media}
                                  verticalAlignment='center'
                                  persistActions
                                  shortcutActions={[
                                    {
                                      content: (
                                        <Icon
                                          source={CancelSmallMinor}
                                          color={"critical"}
                                        />
                                      ),
                                      onAction: () => removeProduct(id),
                                      accessibilityLabel: "Remove",
                                    },
                                  ]}
                                >
                                  {productTitle}
                                </ResourceItem>
                              );
                            }}
                          />
                        ) : type === "collections" ? (
                          <ResourceList
                            resourceName={{
                              singular: "collection",
                              plural: "collections",
                            }}
                            items={selectedCollections}
                            renderItem={(item) => {
                              const { id, collectionName, image } = item;
                              const media = (
                                <Thumbnail
                                  source={image ? image : ImageMajor}
                                  alt='Product Image'
                                  size='small'
                                />
                              );

                              return (
                                <ResourceItem
                                  id={id}
                                  media={media}
                                  verticalAlignment='center'
                                  persistActions
                                  shortcutActions={[
                                    {
                                      content: (
                                        <Icon
                                          source={CancelSmallMinor}
                                          color={"critical"}
                                        />
                                      ),
                                      onAction: () => removeCollection(id),
                                      accessibilityLabel: "Remove",
                                    },
                                  ]}
                                >
                                  {collectionName}
                                </ResourceItem>
                              );
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Stack.Item>
                    </>
                  ) : (
                    ""
                  )}
                </Stack>
              </Card.Section>
            </Card>
            <Card title={"Active dates"}>
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Popover
                      sectioned
                      active={isPopover}
                      onClose={handleStartDatePopover}
                      activator={
                        <div onClick={handleStartDatePopover}>
                          <Controller
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field: { value } }) => (
                              <TextField
                                prefix={<Icon source={CalendarMajor} />}
                                type='date'
                                label='Start date'
                                value={value}
                              />
                            )}
                            name='startdate'
                          />
                        </div>
                      }
                      ariaHaspopup={false}
                    >
                      <DatePicker
                        formKey={"startdate"}
                        formValue={startDate}
                        setValue={setValue}
                      />
                    </Popover>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          prefix={<Icon source={ClockMajor} />}
                          type='time'
                          label='Start time'
                          // value={new Date()
                          //   .toLocaleDateString('zh-Hans-CN')
                          //   .replaceAll('/', '-')}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      name='startTime'
                    />
                  </FormLayout.Group>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        label='End date'
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                      />
                    )}
                    name='isEndDate'
                  />
                  {isEndDate && (
                    <FormLayout.Group>
                      {/* <Controller
                        control={control}
                        rules={{
                          required: isEndDate,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            prefix={<Icon source={CalendarMajor} />}
                            type='date'
                            placeholder='dd/mm/yyyy'
                            label='End Date'
                            // value={new Date()
                            //   .toLocaleDateString('zh-Hans-CN')
                            //   .replaceAll('/', '-')}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                        name='enddate'
                      /> */}

                      <Popover
                        sectioned
                        active={isEndPopover}
                        onClose={handleEndDatePopover}
                        activator={
                          <div onClick={handleEndDatePopover}>
                            <Controller
                              control={control}
                              rules={{
                                required: isEndDate,
                              }}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <TextField
                                  prefix={<Icon source={CalendarMajor} />}
                                  type='date'
                                  label='End Date'
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                              )}
                              name='enddate'
                            />
                          </div>
                        }
                        ariaHaspopup={false}
                      >
                        <DatePicker
                          formKey={"enddate"}
                          formValue={endDate}
                          setValue={setValue}
                        />
                      </Popover>
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            prefix={<Icon source={ClockMajor} />}
                            type='time'
                            label='End time'
                            // value={new Date()
                            //   .toLocaleDateString('zh-Hans-CN')
                            //   .replaceAll('/', '-')}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                        name='endtime'
                      />
                    </FormLayout.Group>
                  )}
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title='Summary' subdued>
              <Card.Section>
                <AlphaStack gap={"5"}>
                  <AlphaStack gap={"3"}>
                    <Text as='p' variant='bodySm' fontWeight='semibold'>
                      Type and method
                    </Text>
                    <List type='bullet'>
                      <List.Item>
                        Amount off{" "}
                        {type === "collections" ? "Collections" : "Products"}
                      </List.Item>
                      <List.Item>Code</List.Item>
                    </List>
                  </AlphaStack>
                  <AlphaStack gap={"3"}>
                    <Text as='p' variant='bodySm' fontWeight='semibold'>
                      Details
                    </Text>
                    <List type='bullet'>
                      {value && isPercentage ? (
                        <List.Item>
                          {value}% off{" "}
                          {type === "collections" ? "Collections" : "Products"}
                        </List.Item>
                      ) : value && !isPercentage ? (
                        <List.Item>
                          Rs {value} off{" "}
                          {type === "collections" ? "Collections" : "Products"}
                        </List.Item>
                      ) : (
                        ""
                      )}
                      {priceRuleTitle ? (
                        <List.Item>Title: {priceRuleTitle}</List.Item>
                      ) : (
                        ""
                      )}
                      <List.Item>Can't combine with other discounts</List.Item>
                      <List.Item>
                        {startDate ? (
                          <>
                            Active from <i>{startDate}</i>
                            {isEndDate ? (
                              <>
                                {" "}
                                to <i>{endDate}</i>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </List.Item>
                    </List>
                  </AlphaStack>
                </AlphaStack>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <PageActions
              primaryAction={{
                content: "Save Price Rule",
                loading: isLoading,
                onAction: handleSubmit(onSubmit),
              }}
              secondaryActions={[
                { content: "Discard", onAction: () => navigate(-1) },
              ]}
            />
          </Layout.Section>
        </Layout>
        {type ? (
          <ProductsnCollections
            isModal={isModal}
            setIsModal={setIsModal}
            collections={collectionsData}
            products={productsData}
            selectedProducts={selectedProducts}
            selectedCollections={selectedCollections}
            type={type}
            setValue={setValue}
          />
        ) : (
          ""
        )}
      </Page>
    </Form>
  );
}

PriceRule.prototype = {
  products: PropTypes.object.isRequired,
  collections: PropTypes.object.isRequired,
  priceRule: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.products,
  collections: state.collections,
  priceRuleData: state.pricerule,
});

export default connect(mapStateToProps, {
  getProducts,
  getCollections,
  getPriceRule,
  updatePriceRule,
})(PriceRule);
