import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Layout,
  Select,
  Text,
  Stack,
  TextField,
  Button,
  Collapsible,
  Form,
  FormLayout,
} from '@shopify/polaris';

import { useForm, Controller } from 'react-hook-form';

import { useOutletContext, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import {
  getAffiliateById,
  updateAffiliate,
  changeAffiliateEmail,
  changeAffiliatePassword,
} from '../../AppStore/actions/affiliates.actions';

const Settings = ({
  getAffiliateById,
  updateAffiliate,
  changeAffiliateEmail,
  changeAffiliatePassword,
}) => {
  const { id } = useParams();

  const { affiliateData } = useOutletContext();
  const [data, setData] = useState(null);
  useEffect(() => {
    if (affiliateData.data) {
      setData(affiliateData.data.data);
    }
  }, [affiliateData]);

  const [formLoading, setFormLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const {
    control: emailControl,
    formState: { errors: emailErrors },
    handleSubmit: handleEmailForm,
  } = useForm({});

  const {
    control: passwordControl,
    reset: passwordDataReset,
    formState: { errors: passwordErrors },
    handleSubmit: handlePasswordForm,
  } = useForm({});

  const [textValue, setTextValue] = useState('');
  const handleTextValue = useCallback((value) => {
    setTextValue(value);
  }, []);

  const [emailToggle, setEmailToggle] = useState(false);
  const handleChangeEmail = useCallback(() => {
    setEmailToggle((toggleValue) => !toggleValue);
  }, []);

  const [passwordToggle, setPasswordToggle] = useState(false);
  const handleChangePassword = useCallback(() => {
    setPasswordToggle((togglePass) => !togglePass);
  }, []);

  useEffect(() => {
    if (affiliateData.data) {
      const {
        firstName,
        lastName,
        phone,
        gender,
        age,
        education,
        company,
        address,
        city,
        province,
        country,
        postalCode,
        social,
      } = affiliateData.data.data;

      reset({
        firstName,
        lastName,
        gender,
        age,
        education,
        phone,
        company,
        address,
        city,
        state: province,
        country,
        postalCode,
        facebook: social && social.facebook ? social.facebook : '',
        twitter: social && social.twitter ? social.twitter : '',
        instagram: social && social.instagram ? social.instagram : '',
        website: social && social.website ? social.website : '',
      });
      setData(affiliateData.data.data);
    }
  }, [affiliateData]);

  const onSubmit = async (form) => {
    const {
      firstName,
      lastName,
      gender,
      age,
      phone,
      company,
      city,
      province,
      address,
      country,
      education,
      postalCode,
      facebook,
      instagram,
      twitter,
      website,
    } = form;
    const formData = {
      id,
      firstName,
      lastName,
      gender,
      age,
      phone,
      company,
      city,
      state: province,
      address,
      country,
      education,
      postalCode,
      social: {
        facebook,
        instagram,
        twitter,
        website,
      },
    };

    setFormLoading(true);
    await updateAffiliate(formData);
    setFormLoading(false);
  };

  const changeEmail = async (form) => {
    form.id = id;
    setEmailLoading(true);
    await changeAffiliateEmail(form);
    await getAffiliateById(id);
    setEmailLoading(false);
  };

  const changePassword = async (form) => {
    form.id = id;
    setPasswordLoading(true);
    await changeAffiliatePassword(form);
    setPasswordLoading(false);
    passwordDataReset();
  };

  return (
    <>
      {data ? (
        <>
          <Layout>
            <Layout.Section>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Card title='Profile Editor'>
                  <div style={{ paddingLeft: '20px', paddingTop: '5px' }}>
                    <Text variant='bodyMd'>Update Affiliate profile data</Text>
                  </div>
                  <Card.Section title='Personal Info'>
                    <FormLayout>
                      <FormLayout.Group>
                        <Controller
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoComplete='on'
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              requiredIndicator
                              label='First Name'
                              placeholder='Enter First Name'
                              type='text'
                              error={
                                errors.firstName ? 'First Name is required' : ''
                              }
                            />
                          )}
                          name='firstName'
                        />
                        <Controller
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoComplete='on'
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              requiredIndicator
                              label='Last Name'
                              placeholder='Enter Last Name'
                              type='text'
                              error={
                                errors.lastName ? 'Last Name is required' : ''
                              }
                            />
                          )}
                          name='lastName'
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Controller
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <Select
                              label='Gender'
                              placeholder='Select Gender'
                              requiredIndicator
                              options={[
                                { label: 'Male', value: 'male' },
                                { label: 'Female', value: 'female' },
                              ]}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              error={errors.gender ? 'Gender is required' : ''}
                            />
                          )}
                          name='gender'
                        />

                        <Controller
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoComplete='on'
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              requiredIndicator
                              label='Age'
                              placeholder='Enter Age'
                              type='text'
                              error={errors.age ? 'Age is required' : ''}
                            />
                          )}
                          name='age'
                        />
                      </FormLayout.Group>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            requiredIndicator
                            label='Education'
                            placeholder='Education'
                            type='text'
                            error={
                              errors.education ? 'Education is required' : ''
                            }
                          />
                        )}
                        name='education'
                      />
                    </FormLayout>
                  </Card.Section>
                  <Card.Section title='Business Info'>
                    <Stack distribution='fillEvenly'>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            requiredIndicator
                            label='Phone'
                            placeholder='Enter Phone Number'
                            type='text'
                            error={errors.phone ? 'Phone is required' : ''}
                          />
                        )}
                        name='phone'
                      />
                      <Controller
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='Company'
                            placeholder='Enter Company Name'
                            type='text'
                            error={errors.company ? 'Company is required' : ''}
                          />
                        )}
                        name='company'
                      />
                    </Stack>
                  </Card.Section>
                  <Card.Section title='Address Info'>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          autoComplete='on'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          requiredIndicator
                          label='Street Address'
                          placeholder='Enter Street Address'
                          multiline={4}
                          type='text'
                          error={errors.address ? 'Address is required' : ''}
                        />
                      )}
                      name='address'
                    />
                    <br />
                    <Stack distribution='fillEvenly'>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='City'
                            requiredIndicator
                            placeholder='Enter City'
                            type='text'
                            error={errors.city ? 'City is required' : ''}
                          />
                        )}
                        name='city'
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            requiredIndicator
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='State'
                            placeholder='Enter State'
                            type='text'
                            error={errors.state ? 'State is required' : ''}
                          />
                        )}
                        name='state'
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            requiredIndicator
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='Country'
                            placeholder='Enter Country'
                            type='text'
                            error={errors.state ? 'Country is required' : ''}
                          />
                        )}
                        name='country'
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value}
                            requiredIndicator
                            onChange={onChange}
                            onBlur={onBlur}
                            label='Postal Code'
                            placeholder='Enter Postal Code'
                            type='text'
                            error={
                              errors.state ? 'Postal Code is required' : ''
                            }
                          />
                        )}
                        name='postalCode'
                      />
                    </Stack>
                  </Card.Section>
                  <Card.Section title='Social Info'>
                    <Stack distribution='fillEvenly'>
                      <Controller
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='Instagram'
                            placeholder='@'
                            type='text'
                            error={
                              errors.instagram ? 'Instagram is required' : ''
                            }
                          />
                        )}
                        name='instagram'
                      />
                      <Controller
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='Twitter'
                            placeholder='@'
                            type='text'
                            error={errors.twitter ? 'Twitter is required' : ''}
                          />
                        )}
                        name='twitter'
                      />
                      <Controller
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='Facebook'
                            placeholder='@'
                            type='text'
                            error={
                              errors.facebook ? 'Facebook is required' : ''
                            }
                          />
                        )}
                        name='facebook'
                      />
                      <Controller
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='Website'
                            placeholder='@'
                            type='text'
                            error={errors.website ? 'Website is required' : ''}
                          />
                        )}
                        name='website'
                      />
                    </Stack>
                  </Card.Section>
                  <Card.Section>
                    <Stack alignment='center' distribution='trailing'>
                      <Button primary submit loading={formLoading}>
                        Update
                      </Button>
                    </Stack>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
            <Layout.Section secondary>
              <Card title='Referral Url'>
                <div style={{ paddingLeft: '20px', paddingTop: '5px' }}>
                  <Text variant='bodyMd'>Set Affiliate Referral Url</Text>
                </div>
                <Card.Section>
                  <TextField value={textValue} onChange={handleTextValue} />
                </Card.Section>
              </Card>
              <Card title='Credential Management'>
                <div style={{ paddingLeft: '20px', paddingTop: '5px' }}>
                  <Text variant='bodyMd'>Set Affiliate Credentials</Text>
                </div>

                <Card.Section
                  title={`Email: "${data.email}"`}
                  actions={[
                    {
                      destructive: emailToggle ? true : false,
                      content: emailToggle ? 'Cancel' : 'Edit',
                      onAction: handleChangeEmail,
                    },
                  ]}
                >
                  <Collapsible
                    open={emailToggle}
                    transition={{
                      duration: '500ms',
                      timingFunction: 'ease-in-out',
                    }}
                    expandOnPrint
                  >
                    <Stack vertical>
                      <Form onSubmit={handleEmailForm(changeEmail)}>
                        <Controller
                          control={emailControl}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoComplete='on'
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              label='Change Email'
                              placeholder='New Email'
                              connectedRight={
                                <Button primary submit loading={emailLoading}>
                                  Save
                                </Button>
                              }
                              error={
                                emailErrors.email ? 'Email is required' : ''
                              }
                            />
                          )}
                          name='email'
                        />
                      </Form>
                    </Stack>
                  </Collapsible>
                </Card.Section>
                <Card.Section
                  title='Change Password'
                  actions={[
                    {
                      destructive: passwordToggle ? true : false,
                      content: passwordToggle ? 'Cancel' : 'Edit',
                      onAction: handleChangePassword,
                    },
                  ]}
                >
                  <Stack vertical>
                    <Collapsible
                      open={passwordToggle}
                      transition={{
                        duration: '500ms',
                        timingFunction: 'ease-in-out',
                      }}
                      expandOnPrint
                    >
                      <Stack distribution='fill'>
                        <Form onSubmit={handlePasswordForm(changePassword)}>
                          <Controller
                            control={passwordControl}
                            rules={{ required: true }}
                            render={({
                              field: { value, onChange, onBlur },
                            }) => (
                              <TextField
                                autoComplete='on'
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                type='password'
                                placeholder='Enter New Password'
                                connectedRight={
                                  <Button
                                    primary
                                    submit
                                    loading={passwordLoading}
                                  >
                                    Save
                                  </Button>
                                }
                                error={
                                  passwordErrors.password
                                    ? 'Password is required'
                                    : ''
                                }
                              />
                            )}
                            name='password'
                          />
                        </Form>
                      </Stack>
                    </Collapsible>
                  </Stack>
                </Card.Section>
              </Card>
              <br />
            </Layout.Section>
          </Layout>
        </>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

export default connect(mapStateToProps, {
  getAffiliateById,
  updateAffiliate,
  changeAffiliateEmail,
  changeAffiliatePassword,
})(Settings);
