import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import {
  IndexTable,
  Filters,
  useIndexResourceState,
  Text,
  EmptySearchResult,
  FormLayout,
  RadioButton,
  Badge,
} from '@shopify/polaris';

import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link,
} from 'react-router-dom';

import Pagination from './Pagination';

function StaffTable({ staffData }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resourceName = {
    singular: 'Staff',
    plural: 'Staffs',
  };

  const [timeOutId, setTimeoutId] = useState(0);

  const [status, setStatus] = useState('');

  const resourceIDResolver = (data) => {
    return data._id;
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(staffData.data, {
    resourceIDResolver,
  });
  const [queryValue, setQueryValue] = useState('');

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const handleStatusRemove = useCallback(() => {
    setStatus(null);
    delete searchParamsObject.status;

    const newSearchParams = new URLSearchParams({
      ...searchParamsObject,
    });

    navigate({
      pathname,
      search: `?${newSearchParams}`,
    });
  }, [searchParamsObject]);

  const handleQueryChange = useCallback(
    (query) => {
      let newSearchParams = {};

      setQueryValue(query);

      if (!query) {
        clearTimeout(timeOutId);
        delete searchParamsObject.query;
        newSearchParams = new URLSearchParams({
          ...searchParamsObject,
        });

        return navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }

      newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        query,
      });

      clearTimeout(timeOutId);
      const timeout = setTimeout(async () => {
        navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }, 500);

      setTimeoutId(timeout);
    },
    [search, timeOutId, searchParamsObject]
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);

  const handleClearAll = useCallback(() => {
    handleStatusRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleStatusRemove]);

  const handleStatusChange = useCallback(
    (_checked, newValue) => {
      const newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        status: newValue,
      });

      navigate({
        pathname,
        search: `?${newSearchParams}`,
      });
    },
    [searchParamsObject]
  );

  const filters = [
    {
      key: 'status',
      label: 'Status',
      filter: (
        <FormLayout>
          <RadioButton
            id={'true'}
            label='Active'
            checked={status === 'true'}
            name='status'
            onChange={handleStatusChange}
          />
          <RadioButton
            id='false'
            label='Inctive'
            checked={status === 'false'}
            name='status'
            onChange={handleStatusChange}
          />
        </FormLayout>
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = !isEmpty(status)
    ? [
        {
          key: 'status',
          label: disambiguateLabel('status', status),
          onRemove: handleStatusRemove,
        },
      ]
    : [];

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No Data Found'}
      description={'Try changing the filters or search term'}
      withIllustration
    />
  );

  useEffect(() => {
    if (searchParamsObject['status']) {
      setStatus(searchParamsObject['status']);
    }
    if (searchParamsObject['query']) {
      setQueryValue(searchParamsObject['query']);
    }
  }, [searchParamsObject]);

  const rowMarkup = useMemo(() => {
    return staffData.data.map(
      (
        { _id, firstName, lastName, email, isActive, role, roles, createdAt },
        index
      ) => (
        <IndexTable.Row
          key={_id}
          id={_id.toString()}
          position={index}
          selected={selectedResources.includes(_id.toString())}
        >
          <IndexTable.Cell>
            <Link
              to={`${pathname}/${_id}`}
              style={{ textDecoration: 'none', color: 'unset' }}
            >
              <Text variant='bodyMd' fontWeight='bold' as='span'>
                {`${firstName} ${lastName}`}
              </Text>
            </Link>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text>{email}</Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {isActive ? (
              <Badge status='success'>Active</Badge>
            ) : (
              <Badge status='critical'>Blocked</Badge>
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge>{role}</Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {`${new Date(createdAt).toLocaleTimeString()} ${new Date(
              createdAt
            ).toLocaleDateString()}`}
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    );
  }, [staffData, selectedResources]);

  return (
    <div>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            queryPlaceholder='Search Staff'
            appliedFilters={appliedFilters}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        selectable={false}
        itemCount={staffData.data.length}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        hasMoreItems
        // bulkActions={bulkActions}
        //promotedBulkActions={promotedBulkActions}
        headings={[
          { title: 'Name' },
          { title: 'Email' },
          { title: 'Status' },
          { title: 'Role' },
          { title: 'Created At' },
        ]}
        emptyState={emptyStateMarkup}
      >
        {rowMarkup}
      </IndexTable>
      <Pagination totalRecords={staffData.totalRecords} />
    </div>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'status':
        return `${key}: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}

export default StaffTable;
