import { AlphaCard, Layout, Page, Button } from '@shopify/polaris';
import React, { useEffect } from 'react';
import StaffTable from '../../../Components/StaffTable';
import { connect } from 'react-redux';
import { getStaff } from '../../../AppStore/actions/staff.actions';

import { useNavigate, useLocation } from 'react-router-dom';

function Staff({ staffData, getStaff }) {
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    getStaff(search);
  }, [search]);
  return (
    <Page
      title='Staff Management'
      fullWidth
      subtitle='Manage your Staff'
      primaryAction={
        <Button onClick={() => navigate('/dashboard/staff/create')} primary>
          Create Staff
        </Button>
      }
    >
      <Layout>
        <Layout.Section>
          <AlphaCard padding={0}>
            {!staffData.isLoading && staffData.data ? (
              <StaffTable staffData={staffData.data} />
            ) : (
              ''
            )}
          </AlphaCard>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  staffData: state.staff,
});

export default connect(mapStateToProps, { getStaff })(Staff);
