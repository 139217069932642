import React, { useState } from 'react';
import {
  Card,
  Form,
  Stack,
  TextField,
  Button,
  FormLayout,
} from '@shopify/polaris';

import { useForm, Controller } from 'react-hook-form';

import { connect } from 'react-redux';

import { changeProfilePassword } from '../../../AppStore/actions/auth.actions';

function ProfilePass({ changeProfilePassword }) {
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const onSubmit = async (data) => {
    setIsLoading(true);
    await changeProfilePassword(data);
    setIsLoading(false);
    reset({});
  };

  return (
    <Card>
      <Card.Section title='Change Password'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormLayout>
            <Stack vertical spacing='tight'>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onBlur, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    type='password'
                    label='Current Password'
                    placeholder='Enter Current Password'
                    error={
                      errors.currentPassword
                        ? 'Current Password cannot be empty'
                        : ''
                    }
                  />
                )}
                name='currentPassword'
              />
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onBlur, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    type='password'
                    label='New Password'
                    placeholder='Enter New Password'
                    error={
                      errors.newPassword ? 'New Password cannot be empty' : ''
                    }
                  />
                )}
                name='newPassword'
              />
              <Stack distribution='trailing'>
                <Button primary submit loading={isLoading}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </FormLayout>
        </Form>
      </Card.Section>
    </Card>
  );
}

export default connect(null, { changeProfilePassword })(ProfilePass);
