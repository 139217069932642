import axios from 'axios';
import { COUPONS, COUPONS_ERROR, COUPON, COUPON_ERROR } from '../constants';
import { setAlert } from './alert.actions';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const createCoupon = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/v1/create-coupon`, formData, config);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};

export const getCoupons = (query) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/coupons${query}`);
    dispatch({
      type: COUPONS,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: COUPONS_ERROR,
      payload: errors,
    });
  }
};

export const getCouponById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/coupon/${id}`);
    dispatch({
      type: COUPON,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: COUPON_ERROR,
      payload: errors,
    });
  }
};

export const updateCoupon = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/update/coupon`, formData, config);

    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};

export const couponActivation = (id) => async (dispatch) => {
  try {
    const res = await axios.patch(`/api/v1/coupon/activation/${id}`);

    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
