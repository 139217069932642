import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  Tabs,
  Frame,
  Loading,
  Grid,
} from '@shopify/polaris';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPayouts } from '../../../AppStore/actions/payouts.actions';

import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

import PayoutsTable from '../../../Components/PayoutsTable';

function Payouts({ payoutsData, getPayouts }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const tabs = [
    {
      id: 'all-payouts',
      content: 'All',
      accessibilityLabel: 'All Payouts',
      query: '',
    },
    {
      id: 'pending-payouts',
      content: 'Pending',
      query: 'P',
    },
    {
      id: 'processing-payouts',
      content: 'Processing',
      query: 'T',
    },
    {
      id: 'approved-payouts',
      content: 'Approved',
      query: 'A',
    },
  ];

  const handleTabChange = useCallback((selectedTabIndex) => {
    const selected = tabs[selectedTabIndex];

    if (selected.query) {
      const newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        status: selected.query,
      });
      navigate({
        pathname,
        search: `?${newSearchParams}`,
      });
      return;
    }

    navigate({
      pathname,
    });

    setSelectedTab(selectedTabIndex);
  }, []);

  useEffect(() => {
    if (searchParamsObject['status']) {
      const index = tabs.findIndex(
        (item) => item.query === searchParamsObject['status']
      );
      if (index === -1) {
        setSelectedTab(0);
      } else {
        setSelectedTab(index);
      }
    }
    getPayouts(search);
  }, [search]);

  return (
    <Page title='Payouts' fullWidth>
      <Layout>
        {payoutsData.isLoading ? (
          <Frame>
            <Loading />
          </Frame>
        ) : (
          ''
        )}
        {payoutsData.data && !payoutsData.isLoading ? (
          <Layout.Section>
            <Grid columns={{ xs: 1, md: 2, lg: 3, xl: 4 }}>
              <Grid.Cell>
                <Card title={payoutsData.data.totalRecords || '0'}>
                  <Card.Section>Total</Card.Section>
                </Card>
              </Grid.Cell>
              <Grid.Cell>
                <Card title={payoutsData.data.pendingPayouts || '0'}>
                  <Card.Section>Pending</Card.Section>
                </Card>
              </Grid.Cell>
              <Grid.Cell>
                <Card title={payoutsData.data.processingPayouts || '0'}>
                  <Card.Section>Processing</Card.Section>
                </Card>
              </Grid.Cell>
              <Grid.Cell>
                <Card title={payoutsData.data.paidPayouts || '0'}>
                  <Card.Section>Paid</Card.Section>
                </Card>
              </Grid.Cell>
            </Grid>
          </Layout.Section>
        ) : (
          ''
        )}
        <Layout.Section>
          <Card>
            <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
              {!payoutsData.isLoading && (
                <PayoutsTable payoutsData={payoutsData.data} />
              )}
            </Tabs>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

Payouts.prototype = {
  payoutsData: PropTypes.object.isRequired,
  getPayouts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  payoutsData: state.payouts,
});

export default connect(mapStateToProps, { getPayouts })(Payouts);
