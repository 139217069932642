import React from 'react';

import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Link as ReactRouterLink,
} from 'react-router-dom';

import store from '../src/AppStore/store';

import Routing from './Routes/index';

import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';

function App() {
  return (
    <Provider store={store}>
      <AppProvider linkComponent={Link} i18n={enTranslations}>
        <Router>
          <Routing />
        </Router>
      </AppProvider>
    </Provider>
  );
}

function Link({ children, url = '', ...rest }) {
  // Use an regular a tag for external and download links
  if (isOutboundLink(url) || rest.download) {
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

function isOutboundLink(url) {
  return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
}

export default App;
