import React, { useCallback, useMemo } from 'react';
import { Card, Stack, Pagination } from '@shopify/polaris';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const perPage = 50;

function PaginationComponent({ totalRecords }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const handleNextPage = () => {
    if (search) {
      if (searchParamsObject.page) {
        const queryString = new URLSearchParams({
          ...searchParamsObject,
          page: parseInt(searchParamsObject.page) + 1,
        });

        navigate({
          pathname,
          search: `?${queryString}`,
        });
      } else {
        const queryString = new URLSearchParams({
          ...searchParamsObject,
          page: 2,
        });
        navigate({
          pathname,
          search: `?${queryString}`,
        });
      }
    } else {
      navigate({
        pathname,
        search: `?page=2`,
      });
    }
  };

  const handlePrevPage = () => {
    if (search) {
      if (searchParamsObject.page && searchParamsObject.page > '1') {
        const queryString = new URLSearchParams({
          ...searchParamsObject,
          page: searchParamsObject.page - 1,
        });

        navigate({
          pathname,
          search: `?${queryString}`,
        });
      }
    }
  };

  const hasNext = useCallback(() => {
    if (searchParamsObject.page) {
      return perPage * parseInt(searchParamsObject.page) < totalRecords;
    } else if (perPage >= totalRecords) {
      return false;
    } else {
      return true;
    }
  }, [totalRecords, searchParamsObject]);

  return (
    <Card.Section>
      <Stack distribution='center'>
        <Pagination
          hasPrevious={
            searchParamsObject && searchParamsObject.page > '1' ? true : false
          }
          onPrevious={handlePrevPage}
          hasNext={hasNext()}
          onNext={handleNextPage}
        />
      </Stack>
    </Card.Section>
  );
}

export default PaginationComponent;
