import React, { useEffect } from "react";
import {
  EmptyState,
  Stack,
  SkeletonThumbnail,
  SkeletonDisplayText,
  SkeletonBodyText,
  Box,
} from "@shopify/polaris";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { verifyAffiliateEmail } from "../AppStore/actions/affiliates.actions";

const EmailVerification = ({ emailVerification, verifyAffiliateEmail }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      verifyAffiliateEmail(token);
    }
  }, [token]);

  return (
    <div
      style={{
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {emailVerification.isLoading ? (
        <Box width='400px'>
          <Stack vertical spacing={"loose"}>
            <SkeletonThumbnail size='large' />
            <SkeletonDisplayText size='large' />
            <SkeletonBodyText />
          </Stack>
        </Box>
      ) : emailVerification.data ? (
        <EmptyState
          heading={emailVerification.data.msg}
          image={require("../assets/images/email_verification.svg")}
        >
          <p>
            You can close this window and get back to Nutrifactor's Ambassador
            app.
          </p>
        </EmptyState>
      ) : (
        <>
          <EmptyState
            heading={emailVerification.error[0].msg}
            image={require("../assets/images/email_error.svg")}
          >
            <p>Please contact our customer support </p>
            <a href='mailto:amabassador@nutrifactor.com'>
              amabassador@nutrifactor.com
            </a>
          </EmptyState>
        </>
      )}
    </div>
  );
};

EmailVerification.prototype = {
  emailVerification: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  emailVerification: state.emailVerification,
});

export default connect(mapStateToProps, {
  verifyAffiliateEmail,
})(EmailVerification);
