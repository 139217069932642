import React, { useState, useEffect } from 'react';
import {
  Stack,
  Card,
  Icon,
  Text,
  Button,
  Layout,
  Grid,
} from '@shopify/polaris';
import { DiscountsMinor } from '@shopify/polaris-icons';
import CardsCustom from '../CardsCustom';
import { useNavigate, useOutletContext } from 'react-router-dom';

const Coupons = () => {
  const navigate = useNavigate();
  const { affiliateData } = useOutletContext();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (affiliateData.data) {
      setData({
        coupons: affiliateData.data.data.couponsData.coupons,
        totalCoupons: affiliateData.data.data.couponsData.totalCoupons,
      });
    }
  }, [affiliateData]);

  return (
    <>
      {data ? (
        <>
          <Layout>
            <Layout.Section>
              <Stack distribution='fillEvenly'>
                <Card
                  title={
                    <Stack alignment='center' distribution='equalSpacing'>
                      <Text variant='headingMd' as='h3'>
                        Total Coupons
                      </Text>
                      <Icon source={DiscountsMinor} />
                    </Stack>
                  }
                >
                  <Card.Section>
                    <Text variant='bodyMd'>{data.totalCoupons}</Text>
                  </Card.Section>
                </Card>
                <Card
                  title={
                    <Stack alignment='center' distribution='equalSpacing'>
                      <Text variant='headingMd' as='h3'>
                        Active Coupons
                      </Text>
                      <Icon color='success' source={DiscountsMinor} />
                    </Stack>
                  }
                >
                  <Card.Section>
                    <Text variant='bodyMd'>{data.totalCoupons}</Text>
                  </Card.Section>
                </Card>
                <Card
                  title={
                    <Stack alignment='center' distribution='equalSpacing'>
                      <Text variant='headingMd' as='h3'>
                        Inactive Coupons
                      </Text>
                      <Icon color='critical' source={DiscountsMinor} />
                    </Stack>
                  }
                >
                  <Card.Section>
                    <Text variant='bodyMd'>0</Text>
                  </Card.Section>
                </Card>
              </Stack>
            </Layout.Section>
            <Layout.Section>
              <div style={{ marginBottom: '15px' }}>
                <Stack alignment='center' distribution='equalSpacing'>
                  <Stack.Item>
                    <Text variant='headingLg' as='h3'>
                      Coupons
                    </Text>
                  </Stack.Item>
                  <Stack.Item>
                    <Button
                      primary
                      onClick={() => navigate('/dashboard/coupons/create')}
                    >
                      Add New
                    </Button>
                  </Stack.Item>
                </Stack>
              </div>
              <Grid columns={{ xs: 1, lg: 2, xl: 3 }}>
                {data.coupons.map(
                  (
                    { _id, commission, coupon, active, shopifyPriceRuleId }
                  ) => (
                    <Grid.Cell key={`coupon-card-${_id}`}>
                      <CardsCustom
                        couponData={{
                          id: _id,
                          url: '#',
                          name: coupon,
                          startDate: shopifyPriceRuleId.startDate,
                          endDate: shopifyPriceRuleId.endDate,
                          active: active,
                          type: shopifyPriceRuleId.valueType,
                          commission: commission,
                          customerDiscount: shopifyPriceRuleId.value,
                        }}
                      />
                    </Grid.Cell>
                  )
                )}
              </Grid>
            </Layout.Section>
          </Layout>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Coupons;
