import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  IndexTable,
  Card,
  Filters,
  useIndexResourceState,
  Text,
  ChoiceList,
  Badge,
  Button,
  Modal,
} from "@shopify/polaris";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Pagination from "./Pagination";

function QueriesTable({ queries }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Modal
  const [modalData, setModalData] = useState({
    isModal: false,
    data: {},
  });
  const handleModal = useCallback(
    (data) => {
      setModalData({
        isModal: !modalData.isModal,
        data: data,
      });
    },
    [modalData]
  );

  const resourceName = {
    singular: "Query",
    plural: "Queries",
  };

  const [notificationStatus, setNotificationStatus] = useState("");

  const [timeOutId, setTimeoutId] = useState(null);

  const resourceIDResolver = (data) => {
    return data._id;
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(queries.data, {
    resourceIDResolver,
  });

  const [queryValue, setQueryValue] = useState("");

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const handleTypeRemove = useCallback(() => {
    setNotificationStatus("");
    delete searchParamsObject.status;

    const newSearchParams = new URLSearchParams({
      ...searchParamsObject,
    });

    navigate({
      pathname,
      search: `?${newSearchParams}`,
    });
  }, [searchParamsObject]);

  const handleQueryChange = useCallback(
    (query) => {
      setQueryValue(query);

      let newSearchParams = {};

      if (!query) {
        delete searchParamsObject.query;
        newSearchParams = new URLSearchParams({
          ...searchParamsObject,
        });

        return navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }

      newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        query,
      });

      clearTimeout(timeOutId);
      const id = setTimeout(async () => {
        navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }, 500);

      setTimeoutId(id);
    },
    [search, searchParamsObject, timeOutId]
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);

  const handleClearAll = useCallback(() => {
    handleTypeRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTypeRemove]);

  const handleStatusChange = useCallback(
    (newValue) => {
      setNotificationStatus(newValue);

      const newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        status: newValue,
      });

      navigate({
        pathname,
        search: `?${newSearchParams}`,
      });
    },
    [searchParamsObject]
  );

  const filters = [
    {
      key: "status",
      label: "Status",
      filter: (
        <ChoiceList
          title='Read Status'
          choices={[
            { label: "Responded", value: "true" },
            { label: "Unresponded", value: "false" },
          ]}
          selected={notificationStatus}
          onChange={handleStatusChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = !isEmpty(notificationStatus)
    ? [
        {
          key: "status",
          label: disambiguateLabel("status", notificationStatus),
          onRemove: handleTypeRemove,
        },
      ]
    : [];

  // const getStatus = useCallback((s) => {
  //   if (status[s]) {
  //     if (s === 'P') {
  //       return <Badge size='small'>{status[s]}</Badge>;
  //     } else if (s === 'A' || s === 'RE' || s === 'PA' || s === 'fulfilled') {
  //       return (
  //         <Badge size='small' status='success'>
  //           {status[s]}
  //         </Badge>
  //       );
  //     } else if (s === 'R' || s === 'C' || s === 'B' || s === 'cancelled') {
  //       return (
  //         <Badge size='small' status='critical'>
  //           {status[s]}
  //         </Badge>
  //       );
  //     } else {
  //       return <Badge size='small'>{status[s]}</Badge>;
  //     }
  //   } else {
  //     return <Badge size='small'>{s}</Badge>;
  //   }
  // }, []);

  const rowMarkup = queries.data.map(
    ({
      _id,
      firstName,
      lastName,
      email,
      phone,
      subject,
      responseStatus,
      message,
      updatedAt,
      createdAt,
    }) => (
      <IndexTable.Row
        id={_id}
        key={_id}
        selected={selectedResources.includes(_id)}
        // position={index}
      >
        <IndexTable.Cell>
          <Text variant='bodyMd' fontWeight='bold' as='span'>
            {firstName} {lastName}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{subject}</IndexTable.Cell>
        {/* <IndexTable.Cell>{notificationBody}</IndexTable.Cell> */}
        <IndexTable.Cell>
          {responseStatus ? (
            <Badge status='success'>{"responded"}</Badge>
          ) : (
            <Badge>{"not responded"}</Badge>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>{email}</IndexTable.Cell>
        <IndexTable.Cell>{phone}</IndexTable.Cell>
        {/* <IndexTable.Cell>{viewStatus}</IndexTable.Cell> */}

        {/* <IndexTable.Cell>{message}</IndexTable.Cell> */}
        {/* <IndexTable.Cell>{`${new Date(
          createdAt
        ).toLocaleDateString()} ${new Date(
          createdAt
        ).toLocaleTimeString()}`}</IndexTable.Cell> */}
        <IndexTable.Cell>
          <Button
            plain
            onClick={() =>
              handleModal({
                _id,
                firstName,
                lastName,
                email,
                phone,
                subject,
                responseStatus,
                message,
                updatedAt,
                createdAt,
              })
            }
          >
            View
          </Button>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  useEffect(() => {
    if (searchParamsObject.status) {
      setNotificationStatus(searchParamsObject.status);
    }
  }, []);

  return (
    <Card>
      <div style={{ padding: "16px", display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            queryPlaceholder='Search'
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        itemCount={queries.data.length}
        selectable={false}
        selectedItemsCount={
          allResourcesSelected ? "All" : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        hasMoreItems
        headings={[
          { title: "Name" },
          { title: "Subject" },
          { title: "Status" },
          { title: "Email" },
          { title: "Phone" },
          // { title: "Body" },
          // { title: "Create At" },
          { title: "View" },
        ]}
      >
        {rowMarkup}
      </IndexTable>
      <Pagination totalRecords={queries.totalRecords} />
      <DetailsModal handleModal={handleModal} modalData={modalData} />
    </Card>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "status":
        return `${key}: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
}

const DetailsModal = ({ handleModal, modalData: { isModal, data } }) => {
  const { firstName, lastName, email, phone, subject, message } = data;
  return (
    <Modal
      open={isModal}
      onClose={() => handleModal({})}
      title={`${firstName} ${lastName}`}
    >
      {data && (
        <Modal.Section>
          <Text variant='headingSm'>Name</Text>
          <Text>{`${firstName} ${lastName}`}</Text>
          <br />
          <Text variant='headingSm'>Email</Text>
          <Text>{email}</Text>
          <br />
          <Text variant='headingSm'>Phone</Text>
          <Text>{phone}</Text>
          <br />
          <Text variant='headingSm'>Subject</Text>
          <Text>{subject}</Text>
          <br />
          <Text variant='headingSm'>Body</Text>
          <Text>{message}</Text>
          <br />
        </Modal.Section>
      )}
    </Modal>
  );
};

export default QueriesTable;
