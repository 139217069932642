import axios from 'axios';
import { setAlert } from './alert.actions';
import { TEMPLATES, TEMPLATES_ERROR } from '../constants';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getTemplates = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/v1/templates');
    dispatch({
      type: TEMPLATES,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: TEMPLATES_ERROR,
      payload: errors,
    });
  }
};

export const updateTemplate = (formData) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v1/template/update', formData, config);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
