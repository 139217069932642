import axios from 'axios';
import { DASHBOARD_DATA, DASHBOARD_DATA_ERROR } from '../constants';

export const getDashboardData = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/v1/shop/dashboard/');

    dispatch({
      type: DASHBOARD_DATA,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    dispatch({
      type: DASHBOARD_DATA_ERROR,
      payload: errors,
    });
  }
};
