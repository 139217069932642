import axios from 'axios';
import { NOTIFICATIONS, NOTIFICATIONS_ERROR } from '../constants';
import { setAlert } from './alert.actions';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getNotifications = (query) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/notifications${query ? query : ''}`);
    dispatch({
      type: NOTIFICATIONS,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: NOTIFICATIONS_ERROR,
      payload: errors,
    });
  }
};

export const addNotification = (formData) => async (dispatch) => {
  try {
    const res = await axios.post('/api/v1/notification/send', formData, config);
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
  }
};
