import React, { useEffect, useMemo, useCallback } from "react";
import {
  Page,
  Layout,
  Card,
  Stack,
  Grid,
  Badge,
  Text,
  Box,
  Icon,
} from "@shopify/polaris";
import { ExternalSmallMinor } from "@shopify/polaris-icons";

import { status } from "../../../utils/data";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { useParams, useNavigate } from "react-router-dom";

import { getPayoutById } from "../../../AppStore/actions/payouts.actions";

import IndexTableComponent from "../../../Components/IndexTable";

function Payout({ getPayoutById, payoutData }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const payoutsHeadings = [
    { title: "Order no" },
    { title: "Amount" },
    { title: "Commission" },
    { title: "Customer discount" },
    { title: "Status" },
    { title: "Fulfillment" },
    { title: "Coupon" },
    { title: "Created At" },
    { title: "Updated At" },
  ];

  const getStatus = useCallback((s) => {
    if (status[s]) {
      if (s === "P") {
        return <Badge size='small'>{status[s]}</Badge>;
      } else if (s === "A" || s === "RE" || s === "PA" || s === "fulfilled") {
        return (
          <Badge size='small' status='success'>
            {status[s]}
          </Badge>
        );
      } else if (s === "R" || s === "C" || s === "cancelled") {
        return (
          <Badge size='small' status='critical'>
            {status[s]}
          </Badge>
        );
      } else {
        return <Badge size='small'>{status[s]}</Badge>;
      }
    } else {
      return <Badge size='small'>{s}</Badge>;
    }
  }, []);

  const payoutInfo = useMemo(() => {
    if (!payoutData.isLoading && payoutData.data) {
      return [
        { title: "Total Commission", value: payoutData.data.data.amount },
        {
          title: "Confirmation",
          value:
            payoutData.data.data.userConfirmation === true
              ? "Confirmed"
              : "Pending",
        },
      ];
    }

    return [];
  }, [payoutData]);

  useEffect(() => {
    getPayoutById(id);
  }, [id]);

  return !payoutData.isLoading && payoutData.data ? (
    <Page
      fullWidth
      title={`${payoutData.data.data.affiliate.firstName} (${payoutData.data.data.payoutId})`}
      breadcrumbs={[{ onAction: () => navigate(-1) }]}
      titleMetadata={
        <Stack spacing='tight' alignment='center'>
          <Stack.Item>
            {getStatus(payoutData.data.data.paymentProcess)}
          </Stack.Item>
        </Stack>
      }
    >
      <Layout>
        <Layout.Section>
          <Box>
            {payoutData.data.data.adminRemarks ? (
              <Text variant='bodyMd'>
                <b>Admin Remarks:</b> {payoutData.data.data.adminRemarks}
              </Text>
            ) : (
              ""
            )}
            {payoutData.data.data.affiliateNote ? (
              <Text variant='bodyMd'>
                <b>Affiliate Note:</b> {payoutData.data.data.affiliateNote}
              </Text>
            ) : (
              ""
            )}
            {payoutData.data.data.paymentProof ? (
              // <Button
              //   // external='true'
              //   plain
              //   target=''
              //   url={payoutData.data.data.paymentProof}
              //   icon={ExternalSmallMinor}
              // >
              //   payment proof
              // </Button>
              <Stack>
                <b>Proof:</b>
                <a
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    maxWidth: "fit-content",
                    textDecoration: "none",
                  }}
                  href={payoutData.data.data.paymentProof}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Payment Image
                  <Icon source={ExternalSmallMinor} />
                </a>
              </Stack>
            ) : (
              ""
            )}
          </Box>
        </Layout.Section>
        <Layout.Section>
          <Grid columns={{ xs: 1, md: 2, lg: 3, xl: 4 }}>
            {payoutInfo.map(({ title, value }, index) => (
              <Grid.Cell key={`card-${index}`}>
                <Card>
                  <Card.Section>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{title}</span> {value}
                    </div>
                  </Card.Section>
                </Card>
              </Grid.Cell>
            ))}
          </Grid>
        </Layout.Section>
        <Layout.Section>
          <div style={{ marginBottom: "12px" }}>
            <Text variant='headingMd'>All Orders</Text>
          </div>
          <Card>
            {!payoutData.isLoading && payoutData.data ? (
              <IndexTableComponent
                payoutsHeadings={payoutsHeadings}
                tableData={payoutData}
                statusHandler={getStatus}
              />
            ) : (
              ""
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  ) : (
    ""
  );
}

Payout.prototype = {
  payoutData: PropTypes.object.isRequired,
  getPayoutById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  payoutData: state.payout,
});

export default connect(mapStateToProps, {
  getPayoutById,
})(Payout);
