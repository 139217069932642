import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Autocomplete, Icon } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPriceRules } from '../AppStore/actions/pricerules.actions';

function AutomCompletePriceRules({
  priceRulesData,
  getPriceRules,
  setValue,
  priceRuleTitle,
  priceRuleId,
}) {
  const [selectedOptions, setSelectedOptions] = useState([priceRuleId]);
  const [inputValue, setInputValue] = useState(priceRuleTitle);

  const [isLoading, setIsLoading] = useState(false);

  const priceRules = useMemo(() => {
    if (!priceRulesData.isLoading && priceRulesData.data) {
      return priceRulesData.data.data.map((item) => ({
        value: item._id,
        label: item.priceRuleTitle,
      }));
    } else return [];
  }, [priceRulesData]);

  const updateText = useCallback(
    async (value) => {
      setInputValue(value);
      setIsLoading(true);

      if (value === '') {
        await getPriceRules();

        setIsLoading(false);
        return;
      }
      await getPriceRules('?query=' + value);
      setIsLoading(false);
    },
    [getPriceRules]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedPriceRule = priceRules.find((option) => {
        return selected.includes(option.value);
      });

      setSelectedOptions(selected);
      setInputValue(selectedPriceRule.label);
      setValue('priceRuleId', selectedPriceRule.value);
    },
    [priceRules]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label='Select Price Rules'
      value={inputValue}
      prefix={<Icon source={SearchMinor} color='base' />}
      placeholder='Search Price Rule'
    />
  );

  useEffect(() => {
    setInputValue(priceRuleTitle);
  }, [priceRuleTitle]);
  useEffect(() => {
    setSelectedOptions([priceRuleId]);
  }, [priceRuleId]);

  useEffect(() => {
    getPriceRules();
  }, [getPriceRules]);

  return (
    <Autocomplete
      options={priceRules}
      selected={selectedOptions}
      onSelect={updateSelection}
      textField={textField}
      loading={isLoading}
    />
  );
}

AutomCompletePriceRules.prototype = {
  getPriceRules: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  priceRulesData: state.pricerules,
});

export default connect(mapStateToProps, {
  getPriceRules,
})(AutomCompletePriceRules);
