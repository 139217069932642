import React, { useState, useCallback, useEffect } from 'react';
import { Card, Layout, Page, Spinner, Tabs } from '@shopify/polaris';

import {
  useLocation,
  useNavigate,
  useParams,
  Outlet,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { getAffiliateById } from '../../../AppStore/actions/affiliates.actions';

function Affiliate({ affiliateData, getAffiliateById }) {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { id } = useParams();
  const [tabLoading, setTabLoading] = useState(false);

  const [selected, setSelected] = useState(0);

  const setNavigation = useCallback(
    (selectedTabIndex) => {
      setTabLoading(false);
      switch (selectedTabIndex) {
        case 1:
          return navigate(`/dashboard/affiliates/${id}/orders`);
        case 2:
          return navigate(`/dashboard/affiliates/${id}/coupons`);
        case 3:
          return navigate(`/dashboard/affiliates/${id}/payouts`);
        case 4:
          return navigate(`/dashboard/affiliates/${id}/settings`);
        default:
          return navigate(`/dashboard/affiliates/${id}`);
      }
    },
    [id]
  );

  useEffect(() => {
    if (pathname.includes('orders')) {
      setSelected(1);
    } else if (pathname.includes('coupons')) {
      setSelected(2);
    } else if (pathname.includes('payouts')) {
      setSelected(3);
    } else if (pathname.includes('settings')) {
      setSelected(4);
    } else {
      setSelected(0);
    }
  }, [pathname]);

  const tabs = [
    {
      id: 'affiliate-details',
      content: 'Details',
      accessibilityLabel: 'Affiliate Details',
      panelID: 'affiliate-details-content',
    },
    {
      id: 'affiliate-sales',
      content: 'Orders',
      panelID: 'orders-details-content',
    },
    {
      id: 'affiliate-coupons',
      content: 'Coupons',
      panelID: 'coupons-details-content',
    },
    {
      id: 'affiliate-payouts',
      content: 'Payouts',
      panelID: 'affiliate-payouts-content',
    },
    {
      id: 'affiliate-settings',
      content: 'Settings',
      panelID: 'affiliate-settings-content',
    },
  ];

  useEffect(() => {
    if (id) {
      getAffiliateById(id + search);
    }
  }, [id, search]);

  return (
    <Page
      title='Affiliate Details'
      fullWidth
      secondaryActions={tabLoading ? <Spinner size='small' /> : ''}
      breadcrumbs={[{ content: 'Coupons', onAction: () => navigate(-1) }]}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <Tabs
              fitted
              tabs={tabs}
              selected={selected}
              onSelect={setNavigation}
            ></Tabs>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Outlet context={{ affiliateData }} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  affiliateData: state.affiliate,
});

export default connect(mapStateToProps, {
  getAffiliateById,
})(Affiliate);
