import React, { useCallback, useEffect, useState } from 'react';

import { Layout, Page, Frame, Loading } from '@shopify/polaris';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getNotifications } from '../../../AppStore/actions/notifications.actions';

import { useLocation } from 'react-router-dom';

import NotificationsTable from '../../../Components/NotificationsTable';

import NotificationModal from '../../../Components/Modal/NotificationModal';

function Notifications({ notificationsData, getNotifications }) {
  const { search } = useLocation();
  const [modalActive, setModalActive] = useState(false);
  const handleModalChange = useCallback(() => setModalActive(!modalActive), [
    modalActive,
  ]);

  useEffect(() => {
    getNotifications(search);
  }, [search]);

  return (
    <Page
      title='Notifications'
      fullWidth
      primaryAction={{
        content: 'Create New',
        onAction: handleModalChange,
      }}
    >
      <NotificationModal
        handleModalChange={handleModalChange}
        modalActive={modalActive}
      />
      {notificationsData.isLoading ? (
        <Frame>
          <Loading />
        </Frame>
      ) : (
        ''
      )}
      <Layout>
        <Layout.Section>
          {!notificationsData.isLoading && (
            <NotificationsTable notifications={notificationsData.data} />
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
}

Notifications.prototype = {
  notificationsData: PropTypes.object.isRequired,
  getNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notificationsData: state.notifications,
});

export default connect(mapStateToProps, { getNotifications })(Notifications);
