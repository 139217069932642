import React, { useState, useEffect } from 'react';

import OrderTable from '../OrdersTable';

import { Stack, Card, Icon, Text } from '@shopify/polaris';

import {
  OrdersMinor,
  CashDollarMinor,
  FinancesMinor,
} from '@shopify/polaris-icons';
import { useOutletContext } from 'react-router-dom';

const Orders = () => {
  const { affiliateData } = useOutletContext();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (affiliateData.data) {
      setData({
        orders: affiliateData.data.data.ordersData.orders,
        totalOrders: affiliateData.data.data.ordersData.totalOrders,
        totalRevenue: affiliateData.data.data.ordersData.totalRevenue,
        totalCommission: affiliateData.data.data.ordersData.totalCommission,
      });
    }
  }, [affiliateData]);

  return (
    <>
      {data ? (
        <>
          <Stack distribution='fillEvenly'>
            <Card
              title={
                <Stack alignment='center' distribution='equalSpacing'>
                  <Text variant='headingMd' as='h3'>
                    Orders
                  </Text>
                  <Icon source={OrdersMinor} />
                </Stack>
              }
            >
              <Card.Section>
                <Text variant='bodyMd'>{data.totalOrders}</Text>
              </Card.Section>
            </Card>
            <Card
              title={
                <Stack alignment='center' distribution='equalSpacing'>
                  <Text variant='headingMd' as='h3'>
                    Revenue
                  </Text>
                  <Icon source={FinancesMinor} />
                </Stack>
              }
            >
              <Card.Section>
                <Text variant='bodyMd'>{data.totalRevenue}</Text>
              </Card.Section>
            </Card>
            <Card
              title={
                <Stack alignment='center' distribution='equalSpacing'>
                  <Text variant='headingMd' as='h3'>
                    Commission
                  </Text>
                  <Icon source={CashDollarMinor} />
                </Stack>
              }
            >
              <Card.Section>
                <Text variant='bodyMd'>{data.totalCommission}</Text>
              </Card.Section>
            </Card>
          </Stack>
          <div style={{ paddingTop: '15px' }}>
            <Text variant='headingLg' as='h3'>
              All Sales
            </Text>
          </div>
          <div style={{ paddingTop: '15px' }}>
            <OrderTable
              hideQueryField={true}
              ordersData={{ data: data.orders, totalRecords: data.totalOrders }}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Orders;
