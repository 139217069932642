import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Frame, Loading } from '@shopify/polaris';

const ProtectedRoute = ({ auth, children }) => {

  if (auth.loading) {
    return (
      <Frame>
        <Loading />
      </Frame>
    );
  }
  if (!auth.isAuthenticated) {
    return <Navigate to={'/login'} replace />;
  }
  return children ? children : <Outlet />;
};

ProtectedRoute.prototype = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ProtectedRoute);
