import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  Form,
  TextField,
  FormLayout,
  Autocomplete,
  Icon,
  PageActions,
  Checkbox,
  Stack,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

import { useForm, Controller } from 'react-hook-form';

import { useNavigate, Link } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createCoupon } from '../../../AppStore/actions/coupons.actions';
import { getAllAffiliates } from '../../../AppStore/actions/affiliates.actions';

import AutomCompletePriceRules from '../../../Components/AutomCompletePriceRules';

function Create({ affiliatesData, getAllAffiliates, createCoupon }) {
  const navigate = useNavigate({ createCoupon });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isActive: true,
    },
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAffilaite, setSelectedAffiliate] = useState({});

  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const affiliates = useMemo(() => {
    if (!affiliatesData.isLoading && affiliatesData.data) {
      return affiliatesData.data.data.map((item) => ({
        value: item._id,
        label: `${item.firstName} ${item.lastName} (${item.email})`,
        email: item.email,
      }));
    } else {
      return [];
    }
  }, [affiliatesData]);

  const updateText = useCallback(
    async (value) => {
      setInputValue(value);

      if (!loading) {
        setLoading(true);
      }

      if (value === '') {
        await getAllAffiliates();
        setLoading(false);
        return;
      }

      setTimeout(async () => {
        await getAllAffiliates({ query: value });
        setLoading(false);
      }, 300);
    },
    [affiliates, loading]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedObject = affiliates.find((option) => {
        return selected.includes(option.value);
      });
      setSelectedAffiliate(selectedObject);
      setSelectedOptions(selected);
      setInputValue(selectedObject.label);
      setValue('affiliate', selectedObject.value);
    },
    [affiliates]
  );

  const removeSelectedAffiliate = useCallback(() => {
    getAllAffiliates();
    setSelectedAffiliate({});
    setSelectedOptions([]);
    setInputValue('');
  }, []);

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label='Select Affiliate'
      value={inputValue}
      prefix={<Icon source={SearchMinor} color='base' />}
      placeholder='Search Affiliate'
    />
  );

  const onSubmit = useCallback(async (data) => {
    await createCoupon(data);
  }, []);

  useEffect(() => {
    getAllAffiliates();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {/* <div style={{ height: '0px' }}>
        <Frame
          logo={{
            width: 50,
            contextualSaveBarSource: logoImage,
          }}
        >
          <ContextualSaveBar
            message='Unsaved changes'
            saveAction={{
              submit: true,
              onAction: handleSubmit(onSubmit),
              loading: false,
              disabled: false,
            }}
            discardAction={{
              onAction: () => navigate(-1),
            }}
          />
        </Frame>
      </div> */}
      <Page
        title='Create Coupon'
        breadcrumbs={[{ onAction: () => navigate(-1) }]}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <FormLayout>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        label='Coupon Name'
                        type='text'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        requiredIndicator
                        error={errors.coupon ? 'Coupon name is requried' : ''}
                      />
                    )}
                    name='coupon'
                  />
                  <Controller
                    control={control}
                    // rules={{
                    //   required: true,
                    // }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        label='Acitve'
                        checked={value}
                        onBlur={onBlur}
                        onChange={onChange}
                      />
                    )}
                    name='isActive'
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section>
                <FormLayout>
                  <AutomCompletePriceRules setValue={setValue} />
                </FormLayout>
              </Card.Section>

              <Card.Section>
                <FormLayout>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        label='Affiliate Commission'
                        type='text'
                        placeholder='0.00'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        prefix={'Rs.'}
                        requiredIndicator
                        error={
                          errors.commission
                            ? 'Commission value is required'
                            : ''
                        }
                      />
                    )}
                    name='commission'
                  />
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            {selectedOptions.length ? (
              <Card
                title='Affiliate'
                actions={[
                  {
                    content: 'Remove',
                    destructive: true,
                    onAction: removeSelectedAffiliate,
                  },
                ]}
              >
                <Card.Section>
                  <Stack vertical>
                    <Stack.Item>
                      <Link
                        to={`/dashboard/affiliates/${selectedAffilaite.value}`}
                      >
                        {selectedAffilaite.label}
                      </Link>
                    </Stack.Item>
                  </Stack>
                </Card.Section>
              </Card>
            ) : (
              <Card title='Affiliate'>
                <Card.Section>
                  <Autocomplete
                    options={affiliates}
                    selected={selectedOptions}
                    onSelect={updateSelection}
                    loading={loading}
                    textField={textField}
                  />
                </Card.Section>
              </Card>
            )}
          </Layout.Section>
          <Layout.Section fullWidth>
            <PageActions
              primaryAction={{
                content: 'Create Coupon',
                primary: true,
                onAction: handleSubmit(onSubmit),
              }}
              secondaryActions={[
                { content: 'Discard', onAction: () => navigate(-1) },
              ]}
            />
          </Layout.Section>
        </Layout>
      </Page>
    </Form>
  );
}

Create.prototype = {
  alerts: PropTypes.array.isRequired,
  affiliatesData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  affiliatesData: state.affiliates,
});

export default connect(mapStateToProps, {
  createCoupon,
  getAllAffiliates,
})(Create);
