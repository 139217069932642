import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  Card,
  IndexTable,
  Filters,
  useIndexResourceState,
  Text,
  EmptySearchResult,
  Badge,
  ChoiceList,
} from '@shopify/polaris';

import { status } from '../utils/data';
import Pagination from './Pagination';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link,
} from 'react-router-dom';

function PayoutsTable({ payoutsData, hideQueryField }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resourceName = {
    singular: 'Order',
    plural: 'Orders',
  };

  const timeOutId = useRef(null);

  const [payoutStatus, setPayoutStatus] = useState('');

  const resourceIDResolver = (data) => {
    return data._id;
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(payoutsData.data, {
    resourceIDResolver,
  });
  const [queryValue, setQueryValue] = useState('');

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const handleStatusRemove = useCallback(() => {
    setPayoutStatus('');
    delete searchParamsObject.status;

    const newSearchParams = new URLSearchParams({
      ...searchParamsObject,
    });

    navigate({
      pathname,
      search: `?${newSearchParams}`,
    });
  }, [searchParamsObject]);

  const handleQueryChange = useCallback(
    (query) => {
      setQueryValue(query);

      let newSearchParams = {};

      if (!query) {
        delete searchParamsObject.query;
        newSearchParams = new URLSearchParams({
          ...searchParamsObject,
        });

        return navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }

      newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        query,
      });

      clearTimeout(timeOutId);
      timeOutId.current = setTimeout(async () => {
        navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }, 100);
    },
    [search, searchParamsObject]
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);

  const handleClearAll = useCallback(() => {
    handleStatusRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleStatusRemove]);

  const handleStatusChange = useCallback(
    (newValue) => {
      const newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        status: newValue,
      });

      navigate({
        pathname,
        search: `?${newSearchParams}`,
      });
    },
    [searchParamsObject]
  );

  const filters = [
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title='Payout Status'
          choices={[
            { label: 'Pending', value: 'P' },
            { label: 'Processing', value: 'T' },
            { label: 'Paid', value: 'PA' },
          ]}
          selected={payoutStatus}
          onChange={handleStatusChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = !isEmpty(payoutStatus)
    ? [
        {
          key: 'status',
          label: disambiguateLabel('status', status[payoutStatus[0]]),
          onRemove: handleStatusRemove,
        },
      ]
    : [];

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No Data Found'}
      description={'Try changing the filters or search term'}
      withIllustration
    />
  );

  useEffect(() => {
    if (searchParamsObject['status']) {
      setPayoutStatus([searchParamsObject['status']]);
    }
    if (searchParamsObject['query']) {
      setQueryValue(searchParamsObject['query']);
    }
  }, [searchParamsObject]);

  const getStatus = useCallback((s) => {
    if (status[s]) {
      if (s === 'P') {
        return <Badge size='small'>{status[s]}</Badge>;
      } else if (s === 'A' || s === 'RE' || s === 'PA' || s === 'fulfilled') {
        return (
          <Badge size='small' status='success'>
            {status[s]}
          </Badge>
        );
      } else if (s === 'R' || s === 'C' || s === 'cancelled') {
        return (
          <Badge size='small' status='critical'>
            {status[s]}
          </Badge>
        );
      } else {
        return <Badge size='small'>{status[s]}</Badge>;
      }
    } else {
      return <Badge size='small'>{s}</Badge>;
    }
  }, []);

  const rowMarkup = useMemo(() => {
    return payoutsData.data.map(
      (
        {
          _id,
          payoutId,
          affiliate: {
            _id: affiliateId,
            firstName,
            lastName,
            email,
            paymentMethod,
          },
          amount,
          paymentProcess,
          userConfirmation,
          createdAt,
        },
        index
      ) => (
        <IndexTable.Row
          id={_id}
          key={_id}
          selected={selectedResources.includes(_id)}
          position={index}
        >
          <IndexTable.Cell>
            <Link
              to={`/dashboard/payouts/${_id}`}
              style={{ textDecoration: 'none' }}
            >
              <Text variant='bodyMd' fontWeight='bold' as='span'>
                {payoutId}
              </Text>
            </Link>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Link
              to={`/dashboard/affiliates/${affiliateId}`}
            >{`${firstName} ${lastName}`}</Link>
          </IndexTable.Cell>
          <IndexTable.Cell>{email}</IndexTable.Cell>
          <IndexTable.Cell>{amount}</IndexTable.Cell>
          <IndexTable.Cell>{getStatus(paymentProcess)}</IndexTable.Cell>
          <IndexTable.Cell>{'Cash'}</IndexTable.Cell>
          <IndexTable.Cell>{`${new Date(
            createdAt
          ).toLocaleTimeString()} ${new Date(
            createdAt
          ).toLocaleDateString()}`}</IndexTable.Cell>
        </IndexTable.Row>
      )
    );
  }, [payoutsData, selectedResources]);

  return (
    <Card>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            hideQueryField={hideQueryField}
            queryPlaceholder={'Search Orders'}
            appliedFilters={appliedFilters}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        selectable={false}
        itemCount={payoutsData.data.length}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        // loading
        emptyState={emptyStateMarkup}
        onSelectionChange={handleSelectionChange}
        headings={[
          { title: 'ID' },
          { title: 'Affiliate Name' },
          { title: 'Affiliate Email' },
          { title: 'Commission' },
          { title: 'Status' },
          { title: 'Payment Method' },
          { title: 'Created At' },
        ]}
      >
        {rowMarkup}
      </IndexTable>
      {payoutsData.totalRecords ? (
        <Pagination totalRecords={payoutsData.totalRecords} />
      ) : (
        ''
      )}
    </Card>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case 'status':
        return `${key}: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}

export default PayoutsTable;
