import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  Home,
  NotFound,
  EmailVerification,
  Login,
  AuthPage,
  ShopifyAppLogin,
} from "../Pages/index";
import Index from "../Pages/dashboard/index";
import Dashboard from "../Pages/dashboard/Dashboard";

//Staff
import {
  Staff,
  StaffInfo,
  StaffIndex,
  CreateStaff,
} from "../Pages/dashboard/staff";

// Price Rules
import PriceRulesIndex from "../Pages/dashboard/pricerules/index";
import PriceRules from "../Pages/dashboard/pricerules/PriceRules";
import PriceRule from "../Pages/dashboard/pricerules/PriceRule";
import CreatePriceRule from "../Pages/dashboard/pricerules/Create";

// Coupons
import CouponsIndex from "../Pages/dashboard/coupons/index";
import Coupons from "../Pages/dashboard/coupons/Coupons";
import Coupon from "../Pages/dashboard/coupons/Coupon";
import CreateCoupon from "../Pages/dashboard/coupons/Create";

// Affiliates
import AffiliatesIndex from "../Pages/dashboard/affiliates/index";
import Affiliates from "../Pages/dashboard/affiliates/Affiliates";
import Affiliate from "../Pages/dashboard/affiliates/Affiliate";
import CreateAffiliate from "../Pages/dashboard/affiliates/Create";

// Orders
import OrdersIndex from "../Pages/dashboard/orders/index";
import Orders from "../Pages/dashboard/orders/Orders";
import Order from "../Pages/dashboard/orders/Order";
// Payouts
import PayoutsIndex from "../Pages/dashboard/payouts/index";
import Payouts from "../Pages/dashboard/payouts/Payouts";
import Payout from "../Pages/dashboard/payouts/Payout";

// Notifications
import NotificationsIndex from "../Pages/dashboard/notifications/index";
import Notifications from "../Pages/dashboard/notifications/Notifications";
import Notification from "../Pages/dashboard/notifications/Notification";
import NotificationsSettings from "../Pages/dashboard/notifications/Settings";

// Settings
import SettingsIndex from "../Pages/dashboard/notifications/index";
import Settings from "../Pages/dashboard/settings/Settings";

// Queries
import QueriesIndex from "../Pages/dashboard/query/index";
import Queries from "../Pages/dashboard/query/Queries";

// Profile
import Profile from "../Pages/dashboard/user/Profile";

import AffiliateDetails from "../Components/AffiliateDetails/Details";
import AffiliateOrders from "../Components/AffiliateDetails/Orders";
import AffiliateCoupons from "../Components/AffiliateDetails/Coupons";
import AffiliatePayouts from "../Components/AffiliateDetails/Payouts";
import AffiliateSettings from "../Components/AffiliateDetails/Settings";

import AuthRoute from "./AuthRoute";
import ProtectedRoute from "./ProtectedRoute";

function Routing() {
  return (
    <Routes>
      {/* Temporary Routes */}
      {/* <Route path='dashboard' element={<Index />}>
        <Route index element={<Dashboard />} />
        <Route path='staff' element={<StaffIndex />}>
          <Route index element={<Staff />} />
          <Route path=':id' element={<StaffInfo />} />
          <Route path='create' element={<CreateStaff />} />
        </Route>
        <Route path='pricerules' element={<PriceRulesIndex />}>
          <Route index element={<PriceRules />} />
          <Route path=':id' element={<PriceRule />} />
          <Route path='create' element={<CreatePriceRule />} />
        </Route>
        <Route path='coupons' element={<CouponsIndex />}>
          <Route index element={<Coupons />} />
          <Route path=':id' element={<Coupon />} />
          <Route path='create' element={<CreateCoupon />} />
        </Route>
        <Route path='affiliates' element={<AffiliatesIndex />}>
          <Route index element={<Affiliates />} />
          <Route path=':id' element={<Affiliate />}>
            <Route index element={<AffiliateDetails />} />
            <Route path='orders' element={<AffiliateOrders />} />
            <Route path='coupons' element={<AffiliateCoupons />} />
            <Route path='payouts' element={<AffiliatePayouts />} />
            <Route path='settings' element={<AffiliateSettings />} />
          </Route>
          <Route path='create' element={<CreateAffiliate />} />
        </Route>
        <Route path='orders' element={<OrdersIndex />}>
          <Route index element={<Orders />} />
          <Route path=':id' element={<Order />} />
        </Route>
        <Route path='payouts' element={<PayoutsIndex />}>
          <Route index element={<Payouts />} />
          <Route path=':id' element={<Payout />} />
        </Route>
        <Route path='notifications' element={<NotificationsIndex />}>
          <Route index element={<Notifications />} />
          <Route path=':id' element={<Notification />} />
          <Route path='settings' element={<NotificationsSettings />} />
        </Route>
        <Route path='coupons' element={<PriceRules />} />
        <Route path='profile' element={<Profile />} />
      </Route> */}
      {/* Temporary Routes */}
      <Route path='/' element={<Home />} />
      <Route element={<AuthPage />}>
        <Route path='/login' element={<Login />} />
        <Route path='/auth/callback' element={<ShopifyAppLogin />} />
        <Route element={<AuthRoute />}>
          <Route path='dashboard' element={<Index />}>
            <Route element={<ProtectedRoute />}>
              <Route index element={<Dashboard accessScope={""} />} />
            </Route>
            <Route element={<ProtectedRoute accessScope={"users"} />}>
              <Route path='staff' element={<StaffIndex />}>
                <Route index element={<Staff />} />
                <Route path=':id' element={<StaffInfo />} />
                <Route path='create' element={<CreateStaff />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute accessScope={"priceRules"} />}>
              <Route path='pricerules' element={<PriceRulesIndex />}>
                <Route index element={<PriceRules />} />
                <Route path=':id' element={<PriceRule />} />
                <Route path='create' element={<CreatePriceRule />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute accessScope={"coupons"} />}>
              <Route path='coupons' element={<CouponsIndex />}>
                <Route index element={<Coupons />} />
                <Route path=':id' element={<Coupon />} />
                <Route path='create' element={<CreateCoupon />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute accessScope={"affiliates"} />}>
              <Route path='affiliates' element={<AffiliatesIndex />}>
                <Route index element={<Affiliates />} />
                <Route path=':id' element={<Affiliate />}>
                  <Route index element={<AffiliateDetails />} />
                  <Route path='orders' element={<AffiliateOrders />} />
                  <Route path='coupons' element={<AffiliateCoupons />} />
                  <Route path='payouts' element={<AffiliatePayouts />} />
                  <Route path='settings' element={<AffiliateSettings />} />
                </Route>
                <Route path='create' element={<CreateAffiliate />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute accessScope={"orders"} />}>
              <Route path='orders' element={<OrdersIndex />}>
                <Route index element={<Orders />} />
                <Route path=':id' element={<Order />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute accessScope={"payouts"} />}>
              <Route path='payouts' element={<PayoutsIndex />}>
                <Route index element={<Payouts />} />
                <Route path=':id' element={<Payout />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute accessScope={"notifications"} />}>
              <Route path='notifications' element={<NotificationsIndex />}>
                <Route index element={<Notifications />} />
                <Route path=':id' element={<Notification />} />
                <Route path='settings' element={<NotificationsSettings />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute accessScope={"query"} />}>
              <Route path='query' element={<QueriesIndex />}>
                <Route index element={<Queries />} />
              </Route>
            </Route>
            <Route path='settings' element={<SettingsIndex />}>
              <Route index element={<Settings />} />
            </Route>
            <Route path='profile' element={<Profile />} />
          </Route>
        </Route>
      </Route>

      <Route path='verify-email' element={<EmailVerification />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default Routing;
