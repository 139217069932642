import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  Card,
  IndexTable,
  Filters,
  useIndexResourceState,
  Text,
  EmptySearchResult,
  Badge,
  Link,
  ChoiceList,
} from '@shopify/polaris';

import { status } from '../utils/data';
import Pagination from './Pagination';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

function OrdersTable({ ordersData, hideQueryField }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resourceName = {
    singular: 'Order',
    plural: 'Orders',
  };

  const timeOutId = useRef(null);

  const [orderStatus, setOrderStatus] = useState('');

  const resourceIDResolver = (data) => {
    return data._id;
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(ordersData.data, {
    resourceIDResolver,
  });
  const [queryValue, setQueryValue] = useState('');

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const handleStatusRemove = useCallback(() => {
    setOrderStatus('');
    delete searchParamsObject.os;

    const newSearchParams = new URLSearchParams({
      ...searchParamsObject,
    });

    navigate({
      pathname,
      search: `?${newSearchParams}`,
    });
  }, [searchParamsObject]);

  const handleQueryChange = useCallback(
    (query) => {
      setQueryValue(query);

      let newSearchParams = {};

      if (!query) {
        delete searchParamsObject.query;
        newSearchParams = new URLSearchParams({
          ...searchParamsObject,
        });

        return navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }

      newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        query,
      });

      clearTimeout(timeOutId);
      timeOutId.current = setTimeout(async () => {
        navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }, 100);
    },
    [search, searchParamsObject]
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);

  const handleClearAll = useCallback(() => {
    handleStatusRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleStatusRemove]);

  const handleStatusChange = useCallback(
    (newValue) => {
      const newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        os: newValue,
      });

      navigate({
        pathname,
        search: `?${newSearchParams}`,
      });
    },
    [searchParamsObject]
  );

  const filters = [
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title='Order Status'
          choices={[
            { label: 'Pending', value: 'P' },
            { label: 'Approved', value: 'A' },
            { label: 'Rejected', value: 'R' },
          ]}
          selected={orderStatus}
          onChange={handleStatusChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = !isEmpty(orderStatus)
    ? [
        {
          key: 'status',
          label: disambiguateLabel('status', status[orderStatus[0]]),
          onRemove: handleStatusRemove,
        },
      ]
    : [];

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No Data Found'}
      description={'Try changing the filters or search term'}
      withIllustration
    />
  );

  useEffect(() => {
    if (searchParamsObject['os']) {
      setOrderStatus([searchParamsObject['os']]);
    }
    if (searchParamsObject['query']) {
      setQueryValue(searchParamsObject['query']);
    }
  }, [searchParamsObject]);

  const getStatus = useCallback((s) => {
    if (status[s]) {
      if (s === 'P') {
        return <Badge size='small'>{status[s]}</Badge>;
      } else if (s === 'A' || s === 'RE' || s === 'PA' || s === 'fulfilled') {
        return (
          <Badge size='small' status='success'>
            {status[s]}
          </Badge>
        );
      } else if (s === 'R' || s === 'C' || s === 'cancelled') {
        return (
          <Badge size='small' status='critical'>
            {status[s]}
          </Badge>
        );
      } else {
        return <Badge size='small'>{status[s]}</Badge>;
      }
    } else {
      return <Badge size='small'>{s}</Badge>;
    }
  }, []);

  const rowMarkup = useMemo(() => {
    return ordersData.data.map(
      (
        {
          _id,
          shopifyOrderName,
          affiliate,
          paymentStatus,
          commission,
          couponApplied,
          userDiscount,
          status,
          fulfillmentStatus,
          trackingURL,
        },
        index
      ) => (
        <IndexTable.Row
          id={_id}
          key={_id}
          selected={selectedResources.includes(_id)}
          position={index}
        >
          <IndexTable.Cell>
            <Link
              dataPrimaryLink
              monochrome
              removeUnderline
              // url={url}
              onClick={() => navigate({ pathname: `/dashboard/orders/${_id}` })}
            />
            <Text variant='bodyMd' fontWeight='bold' as='span'>
              {shopifyOrderName}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>{affiliate.firstName}</IndexTable.Cell>
          <IndexTable.Cell>{commission}</IndexTable.Cell>
          <IndexTable.Cell>{userDiscount}</IndexTable.Cell>
          <IndexTable.Cell>{couponApplied.coupon}</IndexTable.Cell>
          <IndexTable.Cell>{getStatus(status)}</IndexTable.Cell>
          <IndexTable.Cell>{getStatus(fulfillmentStatus)}</IndexTable.Cell>
          <IndexTable.Cell>{getStatus(paymentStatus)}</IndexTable.Cell>
          {/* <IndexTable.Cell>
            {trackingURL ? (
              <Link url={trackingURL} external>
                Track
              </Link>
            ) : (
              ''
            )}
          </IndexTable.Cell> */}
        </IndexTable.Row>
      )
    );
  }, [ordersData, selectedResources]);

  return (
    <Card>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            hideQueryField={hideQueryField ? true : false}
            queryPlaceholder={'Search Orders'}
            appliedFilters={appliedFilters}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
      </div>
      <IndexTable
        selectable={false}
        resourceName={resourceName}
        itemCount={ordersData.data.length}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        // loading
        emptyState={emptyStateMarkup}
        onSelectionChange={handleSelectionChange}
        // hasMoreItems
        // bulkActions={bulkActions}
        // promotedBulkActions={promotedBulkActions}
        headings={[
          { title: 'Order' },
          { title: 'Affiliate' },
          { title: 'Commission' },
          { title: 'Customer Discount' },
          { title: 'Coupon Used' },
          { title: 'Status' },
          { title: 'Fulfilment Status' },
          { title: 'Payment Status' },
          // { title: 'Tracking URL' },
        ]}
      >
        {rowMarkup}
      </IndexTable>
      <Pagination totalRecords={ordersData.totalRecords} />
    </Card>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case 'status':
        return `${key}: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}

export default OrdersTable;
