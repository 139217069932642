import React from 'react';
import { Card, Layout, Page } from '@shopify/polaris';

function ShopifyAppLogin() {
  return (
    <Page fullWidth>
      <Layout>
        <Card>
          <Card.Section>Working Fine and Good</Card.Section>
        </Card>
      </Layout>
    </Page>
  );
}

export default ShopifyAppLogin;
