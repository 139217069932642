import axios from "axios";
import { setAlert } from "./alert.actions";
import {
  AFFILIATES,
  AFFILIATES_ERROR,
  AFFILIATE_DATA,
  AFFILIATE_DATA_ERROR,
  EMAIL_VERIFICATION,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_ERROR,
} from "../constants";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const createAffiliate = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("/api/v1/affiliate/create", formData, config);
    dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "critical")));
    }
  }
};

export const getAllAffiliates = (query) => async (dispatch) => {
  try {
    const params = new URLSearchParams(query);

    const res = await axios.get(
      `/api/v1/affiliates${query ? "?" + params : ""}`
    );
    dispatch({
      type: AFFILIATES,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "critical")));
    }
    dispatch({
      type: AFFILIATES_ERROR,
      payload: errors,
    });
  }
};

export const getAffiliateById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/affiliate/${id}`);
    dispatch({
      type: AFFILIATE_DATA,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: AFFILIATE_DATA_ERROR,
      payload: errors,
    });
  }
};

export const updateAffiliate = (formData) => async (dispatch) => {
  try {
    const res = await axios.put("/api/v1/affiliate/update", formData, config);
    dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "critical")));
    }
  }
};

export const changeAffiliateEmail = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(
      "/api/v1/affiliate/change/email",
      formData,
      config
    );
    dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "critical")));
    }
  }
};
export const changeAffiliatePassword = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(
      "/api/v1/affiliate/change/password",
      formData,
      config
    );
    dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "critical")));
    }
  }
};
export const updateAffiliatePersonalNote = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(
      "/api/v1/affiliate/update/note",
      formData,
      config
    );
    dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "critical")));
    }
  }
};

export const affiliateStatusHandler = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(
      "/api/v1/affiliate/update/status",
      formData,
      config
    );
    dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "critical")));
    }
  }
};
export const affiliateEmailStatus = (id) => async (dispatch) => {
  try {
    const res = await axios.patch(`/api/v1/affiliate/verify/email/${id}`);
    dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "critical")));
    }
  }
};
export const sendAffiliateEmail = (id) => async (dispatch) => {
  try {
    const res = await axios.patch(`/api/v1/affiliate/verify/resend/${id}`);
    dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "critical")));
    }
  }
};

export const verifyAffiliateEmail = (token) => async (dispatch) => {
  try {
    dispatch({
      type: EMAIL_VERIFICATION,
    });
    const res = await axios.get(`/aff/api/v1/verify/email/${token}`);
    console.log(res.data);
    dispatch({
      type: EMAIL_VERIFICATION_SUCCESS,
      payload: res.data,
    });
    dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    const errors = error.response.data.errors;
    dispatch({
      type: EMAIL_VERIFICATION_ERROR,
      payload: errors,
    });
  }
};
