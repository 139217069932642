import React from 'react';

import { Card, Layout, Page } from '@shopify/polaris';

function Notification() {
  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section>Notification</Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default Notification;
