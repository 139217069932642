import React, { useState, useCallback, useRef, useEffect } from "react";

import { AppProvider, Frame, Navigation, TopBar } from "@shopify/polaris";
import {
  TemplateMinor,
  PasskeyMinor,
  OrdersMinor,
  DiscountsMinor,
  NotificationMajor,
  ProfileMinor,
  SettingsMinor,
  LogOutMinor,
  CustomersMinor,
  CashDollarMinor,
  CapturePaymentMinor,
  QuestionMarkMinor,
} from "@shopify/polaris-icons";

import { Outlet, useLocation, Link as ReactRouterLink } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import logoImage from "../../assets/images/logo.png";

import { connect } from "react-redux";
import Alerts from "../../Components/Alerts";
import { logout } from "../../AppStore/actions/auth.actions";

import PropTypes from "prop-types";

function Index({ children, user, logout }) {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const skipToContentRef = useRef(null);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [storeName, setStoreName] = useState("");

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const changeRoute = useCallback((pathname) => {
    navigate({ pathname });
  }, []);

  useEffect(() => {
    if (user) {
      setStoreName(`${user.firstName} ${user.lastName}`);
    }
  }, [user]);

  const userMenuActions = [
    {
      items: [
        {
          content: "Profile",
          icon: ProfileMinor,
          onAction: () => changeRoute("/dashboard/Profile"),
        },
        {
          content: "Logout",
          icon: LogOutMinor,
          onAction: logout,
        },
      ],
    },
  ];

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name='Nutrifactor'
      detail={storeName}
      initials='N'
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const navigationMarkup = (
    <Navigation location={pathname}>
      <Navigation.Section
        title='Menu'
        items={[
          {
            label: "Dashboard",
            icon: TemplateMinor,
            url: "/dashboard",
            selected: "/dashboard" === pathname,
          },
          {
            label: "Staff Management",
            icon: PasskeyMinor,
            url: "/dashboard/staff",
          },
        ]}
      />
      <Navigation.Section
        title='Setup'
        separator
        items={[
          {
            label: "Price Rules",
            icon: CashDollarMinor,
            url: "/dashboard/pricerules",
          },
          {
            label: "Coupons",
            icon: DiscountsMinor,
            url: "/dashboard/coupons",
          },
        ]}
      />
      <Navigation.Section
        title='Manage'
        items={[
          {
            label: "Affiliates",
            icon: CustomersMinor,
            url: "/dashboard/affiliates",
          },
          {
            label: "Orders",
            icon: OrdersMinor,
            url: "/dashboard/orders",
          },
          {
            label: "Payouts",
            icon: CapturePaymentMinor,
            url: "/dashboard/payouts",
          },
          {
            label: "Customer Queries",
            icon: QuestionMarkMinor,
            url: "/dashboard/query",
          },
        ]}
      />
      <Navigation.Section
        fill
        title='Alerts'
        items={[
          {
            label: "Notifications",
            icon: NotificationMajor,
            shouldResizeIcon: true,
            url: "/dashboard/notifications",
            subNavigationItems: [
              {
                label: "Settings",
                url: "/dashboard/notifications/settings",
              },
            ],
          },
          {
            label: "Profile",
            icon: ProfileMinor,
            url: "/dashboard/profile",
          },
        ]}
        separator
      />
      <Navigation.Section
        items={[
          {
            label: "Settings",
            icon: SettingsMinor,
            url: "/dashboard/settings",
          },
        ]}
      />
    </Navigation>
  );

  const logo = {
    width: 30,
    topBarSource: logoImage,
    contextualSaveBarSource: logoImage,
    accessibilityLabel: "Logo",
  };

  return (
    <div style={{ height: "500px" }}>
      <AppProvider
        linkComponent={Link}
        i18n={{
          Polaris: {
            Avatar: {
              label: "Avatar",
              labelWithInitials: "Avatar with initials {initials}",
            },
            ContextualSaveBar: {
              save: "Save",
              discard: "Discard",
            },
            TextField: {
              characterCount: "{count} characters",
            },
            TopBar: {
              toggleMenuLabel: "Toggle menu",

              SearchField: {
                clearButtonLabel: "Clear",
                search: "Search",
              },
            },
            Modal: {
              iFrameTitle: "body markup",
            },
            Frame: {
              skipToContent: "Skip to content",
              navigationLabel: "Navigation",
              Navigation: {
                closeMobileNavigationLabel: "Close navigation",
              },
            },
          },
        }}
      >
        <Frame
          logo={logo}
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
        >
          <Alerts />
          {children}
          <Outlet />
        </Frame>
      </AppProvider>
    </div>
  );

  function Link({ children, url = "", ...rest }) {
    if (isOutboundLink(url) || rest.download) {
      return (
        <a href={url} {...rest}>
          {children}
        </a>
      );
    }

    return (
      <ReactRouterLink to={url} {...rest}>
        {children}
      </ReactRouterLink>
    );
  }

  function isOutboundLink(url) {
    return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
  }
}

Index.prototype = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(Index);
