import React from 'react';
import { useState, useCallback, useEffect } from 'react';
import {
  AlphaCard,
  Bleed,
  Divider,
  FormLayout,
  Layout,
  Page,
  Text,
  TextField,
  AlphaStack,
  Checkbox,
  ButtonGroup,
  Button,
  Form,
  Badge,
  Stack,
  SettingToggle,
  Loading,
  Select,
} from '@shopify/polaris';
import { useForm, Controller } from 'react-hook-form';
import { StaffEmail, StaffPass } from '../../../Components/Atoms';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getStaffById,
  updateStaff,
  handleUserActivation,
  changePassword,
} from '../../../AppStore/actions/staff.actions';

import { staffRoles } from '../../../utils/data';

function StaffInfo({
  staffInfoData,
  getStaffById,
  updateStaff,
  handleUserActivation,
  changePassword,
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const [activationLoading, setActivationLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      roles: staffRoles,
      role: 'guest',
      emailVerified: false,
    },
  });

  const roles = watch('roles');

  const handleRoles = useCallback(
    (index) => {
      const getData = [...roles];
      getData[index].checked = !getData[index].checked;
      setValue('roles', [...getData]);
    },
    [roles, staffRoles]
  );

  const handleActivation = useCallback(async () => {
    setActivationLoading(true);
    await handleUserActivation(id);
    await getStaffById(id);
    setActivationLoading(false);
  }, []);

  const onSubmit = async (data) => {
    const {
      firstName,
      lastName,
      city,
      province,
      address,
      country,
      postalCode,
      emailVerified,
      instagram,
      phone,
      facebook,
      twitter,
      website,
      roles,
      role,
    } = data;

    const getSelectedRoles = roles
      .filter((item) => item.checked)
      .map((item) => item.role);

    const formData = {
      id,
      firstName,
      lastName,
      city,
      emailVerified,
      province,
      address,
      phone,
      country,
      postalCode,
      role,
      roles: getSelectedRoles,
      social: {
        instagram,
        facebook,
        twitter,
        website,
      },
    };

    setFormLoading(true);
    await updateStaff(formData);
    await getStaffById(id);
    setFormLoading(false);
  };

  useEffect(() => {
    if (!staffInfoData.isLoading && staffInfoData.data) {
      const {
        firstName,
        lastName,
        city,
        emailVerified,
        province,
        address,
        country,
        phone,
        postalCode,
        social,
        role,
        roles: userRoles,
      } = staffInfoData.data.data;

      const rolesData = staffRoles.map((item) => {
        return {
          ...item,
          checked: userRoles.includes(item.role),
        };
      });

      reset({
        firstName,
        lastName,
        city,
        emailVerified,
        province,
        address,
        country,
        postalCode,
        phone,
        role: role === 'admin' ? 'guest' : role,
        roles: rolesData,
        facebook: social && social.facebook ? social.facebook : '',
        twitter: social && social.twitter ? social.twitter : '',
        instagram: social && social.instagram ? social.instagram : '',
        website: social && social.website ? social.website : '',
      });
    }
  }, [staffInfoData]);

  useEffect(() => {
    getStaffById(id);
  }, [id]);

  return (
    <>
      {!staffInfoData.isLoading && staffInfoData.data ? (
        <Page
          breadcrumbs={[{ onAction: () => navigate(-1) }]}
          title={`${staffInfoData.data.data.firstName} ${staffInfoData.data.data.lastName}`}
          titleMetadata={<Badge>{staffInfoData.data.data.role}</Badge>}
          subtitle={`${staffInfoData.data.data.email}`}
        >
          <Layout>
            <Layout.Section>
              <AlphaCard>
                <Stack distribution='equalSpacing'>
                  <Stack.Item>
                    <Text as='h4' variant='headingMd'>
                      Details
                    </Text>
                  </Stack.Item>
                  <Stack.Item>
                    {staffInfoData.data.data.isActive ? (
                      <Badge status='success'>Active</Badge>
                    ) : (
                      <Badge status='critical'>Blocked</Badge>
                    )}
                  </Stack.Item>
                </Stack>

                <Bleed marginInline={'5'}>
                  <div style={{ paddingBlock: 15 }}>
                    <Divider />
                  </div>
                </Bleed>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormLayout>
                    <FormLayout.Group>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value || ''}
                            label='First Name'
                            error={
                              errors.firstName ? 'First Name is required' : ''
                            }
                          />
                        )}
                        name='firstName'
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='Last Name'
                            error={
                              errors.lastName ? 'Last Name is required' : ''
                            }
                          />
                        )}
                        name='lastName'
                      />
                    </FormLayout.Group>
                    <Bleed marginInline={'5'}>
                      <Divider />
                    </Bleed>

                    <FormLayout.Group>
                      <Stack alignment='center' distribution='fillEvenly'>
                        <AlphaStack gap={'1'} fullWidth>
                          <Text as='p' variant='bodySm'>
                            Email{' '}
                            {staffInfoData.data.data.emailVerified ? (
                              <Badge status='success'>Verified</Badge>
                            ) : (
                              <Badge status='critical'>Unverified</Badge>
                            )}
                          </Text>

                          <TextField
                            readOnly
                            disabled
                            value={staffInfoData.data.data.email}
                          />
                        </AlphaStack>
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Checkbox
                              label='Verify Email'
                              onChange={onChange}
                              onBlur={onBlur}
                              checked={value}
                            />
                          )}
                          name='emailVerified'
                        />
                      </Stack>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            label='Phone number'
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder='+92'
                            requiredIndicator
                            error={
                              errors.phone ? 'Phone number is required' : ''
                            }
                          />
                        )}
                        name='phone'
                      />
                    </FormLayout.Group>

                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          autoComplete='on'
                          value={value || ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          label='Street Address'
                          placeholder='Enter Street Address'
                          multiline={4}
                          error={errors.address ? 'Address is required' : ''}
                        />
                      )}
                      name='address'
                    />

                    <Stack distribution='fillEvenly'>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='City'
                            placeholder='Enter City'
                            error={errors.city ? 'City is required' : ''}
                          />
                        )}
                        name='city'
                      />
                      <Controller
                        control={control}
                        // rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='State'
                            placeholder='Enter State'
                            error={
                              errors.province ? 'Province is required' : ''
                            }
                          />
                        )}
                        name='province'
                      />

                      <Controller
                        control={control}
                        // rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='Postal Code'
                            placeholder='Enter Postal Code'
                            error={
                              errors.postalCode ? 'Postal Code is required' : ''
                            }
                          />
                        )}
                        name='postalCode'
                      />
                      <Controller
                        control={control}
                        // rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextField
                            autoComplete='on'
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            label='Country'
                            placeholder='Enter Country'
                            error={errors.country ? 'Country is required' : ''}
                          />
                        )}
                        name='country'
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value || ''}
                            label='Role'
                            options={[
                              { label: 'Select', value: '' },
                              { label: 'Guest', value: 'guest' },
                              { label: 'Staff', value: 'staff' },
                              { label: 'Moderator', value: 'moderator' },
                            ]}
                            error={errors.password ? 'Role is required' : ''}
                          />
                        )}
                        name='role'
                      />
                    </Stack>
                    <Bleed marginInline={'5'}>
                      <Divider />
                    </Bleed>
                    <AlphaStack gap={'1'}>
                      <Text as='p' variant='bodySm'>
                        Social
                      </Text>
                      <Stack distribution='fillEvenly'>
                        <Controller
                          control={control}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoComplete='on'
                              value={value || ''}
                              onChange={onChange}
                              onBlur={onBlur}
                              label='Instagram'
                              placeholder='@'
                            />
                          )}
                          name='instagram'
                        />
                        <Controller
                          control={control}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoComplete='on'
                              value={value || ''}
                              onChange={onChange}
                              onBlur={onBlur}
                              label='Twitter'
                              placeholder='@'
                            />
                          )}
                          name='twitter'
                        />
                        <Controller
                          control={control}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoComplete='on'
                              value={value || ''}
                              onChange={onChange}
                              onBlur={onBlur}
                              label='Facebook'
                              placeholder='@'
                            />
                          )}
                          name='facebook'
                        />
                        <Controller
                          control={control}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoComplete='on'
                              value={value || ''}
                              onChange={onChange}
                              onBlur={onBlur}
                              label='Website'
                              placeholder='https://example.com'
                            />
                          )}
                          name='website'
                        />
                      </Stack>
                    </AlphaStack>
                    <Bleed marginInline={'5'}>
                      <Divider />
                    </Bleed>
                    <AlphaStack gap={'1'}>
                      <Text as='p' variant='bodySm'>
                        Roles
                      </Text>
                      <FormLayout.Group>
                        {roles.map(({ name, role, checked }, index) => (
                          <Checkbox
                            key={`role${index}`}
                            label={name}
                            name={role}
                            checked={checked}
                            value={role || ''}
                            onChange={() => handleRoles(index)}
                          />
                        ))}
                      </FormLayout.Group>
                    </AlphaStack>
                    <Bleed marginInline={'5'}>
                      <Divider />
                    </Bleed>
                    <AlphaStack align='end'>
                      <ButtonGroup>
                        <Button primary loading={formLoading} submit>
                          Save
                        </Button>
                        <Button onClick={() => navigate(-1)}>Cancel</Button>
                      </ButtonGroup>
                    </AlphaStack>
                  </FormLayout>
                </Form>
              </AlphaCard>
            </Layout.Section>
            <Layout.Section secondary>
              <SettingToggle
                enabled={staffInfoData.data.data.isActive ? true : false}
                action={{
                  content: staffInfoData.data.data.isActive
                    ? 'Deactivate'
                    : 'Activate',
                  loading: activationLoading,
                  onAction: handleActivation,
                }}
              >
                <Text as='h4' variant='headingMd'>
                  Staff Status
                </Text>
                <Text as='p'>
                  Easily manage your staff's active status with just a single
                  click.
                </Text>
              </SettingToggle>
              <StaffEmail currentEmail={staffInfoData.data.data.email} />
              <StaffPass
                changePassword={changePassword}
                getStaffById={getStaffById}
              />
            </Layout.Section>
          </Layout>
        </Page>
      ) : (
        <Loading />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  staffInfoData: state.staffInfo,
});

export default connect(mapStateToProps, {
  getStaffById,
  updateStaff,
  handleUserActivation,
  changePassword,
})(StaffInfo);
