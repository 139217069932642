import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ auth, children, accessScope }) => {
  if (!auth.user) {
    return <Navigate to={'/login'} replace />;
  } else if (auth.user.role !== 'admin') {
    if (accessScope && !auth.user.roles.includes(accessScope)) {
      return <Navigate to={'/dashboard'} replace />;
    }
  }
  return children ? children : <Outlet />;
};

ProtectedRoute.prototype = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ProtectedRoute);
