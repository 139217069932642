import React, { useState, useCallback } from 'react';
import { useMemo } from 'react';
import {
  useIndexResourceState,
  IndexTable,
  Card,
  Text,
  Modal,
  TextContainer,
  TextField,
  Form,
  FormLayout,
  ButtonGroup,
  Button,
  Stack,
  Link,
} from '@shopify/polaris';
import { useForm, Controller } from 'react-hook-form';
import {
  processPayment,
  markPaid,
  getPayoutById,
} from '../AppStore/actions/payouts.actions';
import { connect } from 'react-redux';

import { useParams } from 'react-router';

const resourceName = {
  singular: 'order',
  plural: 'orders',
};

function IndexTableComponent({
  payoutsHeadings,
  tableData,
  statusHandler,
  processPayment,
  markPaid,
  getPayoutById,
}) {
  const { id } = useParams();
  const [paymentLoading, setPaymentLoading] = useState(false);

  const [active, setActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(() => ({
    defaultValues: {},
  }));

  const resourceIDResolver = (data) => data.orderId._id;

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(tableData.data.data.orders, {
    resourceIDResolver,
  });

  const processPayout = async () => {
    if (!selectedResources.length) {
      return;
    }

    setPaymentLoading(true);
    await processPayment({
      id,
      orders: selectedResources,
    });
    clearSelection()
    await getPayoutById(id);
    setPaymentLoading(false);
  };

  const tableDataMarkup = useMemo(() => {
    if (!tableData.isLoading && tableData.data) {
      return tableData.data.data.orders.map(
        (
          {
            orderId: {
              _id,
              shopifyOrderName,
              amount,
              commission,
              userDiscount,
              status,
              fulfillmentStatus,
              couponApplied,
              createdAt,
              updatedAt,
            },
          },
          index
        ) => (
          <IndexTable.Row
            id={_id}
            key={index}
            selected={selectedResources.includes(_id)}
            position={index}
          >
            <IndexTable.Cell>
              <Text variant='bodyMd' fontWeight='bold' as='span'>
                {shopifyOrderName}
              </Text>
            </IndexTable.Cell>

            <IndexTable.Cell>{amount}</IndexTable.Cell>
            <IndexTable.Cell>{commission}</IndexTable.Cell>
            <IndexTable.Cell>{userDiscount}</IndexTable.Cell>
            <IndexTable.Cell>{statusHandler(status)}</IndexTable.Cell>
            <IndexTable.Cell>{fulfillmentStatus}</IndexTable.Cell>
            <IndexTable.Cell>
              <Link
                removeUnderline
                url={`/dashboard/coupons/${couponApplied._id}`}
              >
                {couponApplied.coupon}
              </Link>
            </IndexTable.Cell>
            <IndexTable.Cell>
              {`${new Date(createdAt).toLocaleDateString()} ${new Date(
                createdAt
              ).toLocaleTimeString()}`}
            </IndexTable.Cell>
            <IndexTable.Cell>{`${new Date(
              updatedAt
            ).toLocaleDateString()} ${new Date(
              updatedAt
            ).toLocaleTimeString()}`}</IndexTable.Cell>
          </IndexTable.Row>
        )
      );
    }
    return [];
  }, [tableData, selectedResources]);

  const onSubmit = async (data) => {
    const formData = { id, ...data };
    setPaymentLoading(true);
    await markPaid(formData);
    clearSelection();
    await getPayoutById(id);
    setPaymentLoading(false);
  };

  return (
    <Card>
      <IndexTable
        loading={paymentLoading}
        promotedBulkActions={[
          {
            content: 'Process Payment',
            disabled: tableData.data.data.paymentProcess === 'P' ? false : true,
            onAction: processPayout,
          },
          {
            content: 'Mark as paid',
            disabled: tableData.data.data.paymentProcess === 'T' ? false : true,
            onAction: handleChange,
          },
        ]}
        selectable={tableData.data.data.paymentProcess === 'PA' ? false : true}
        resourceName={resourceName}
        itemCount={tableDataMarkup.length}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        headings={payoutsHeadings}
      >
        {tableDataMarkup}
      </IndexTable>
      <Modal open={active} onClose={handleChange} title='Add Image and Note'>
        <Modal.Section>
          <TextContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormLayout>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <TextField
                      label='Image Url'
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder='Enter Image Url'
                      error={errors.imageUrl ? 'Image Url is required' : ''}
                    />
                  )}
                  name='imageUrl'
                />
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <TextField
                      label='Admin Remarks'
                      value={value}
                      placeholder='Admin Remarks'
                      multiline={4}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={
                        errors.adminRemarks ? 'Admin Remarks is required' : ''
                      }
                    />
                  )}
                  name='adminRemarks'
                />
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <TextField
                      label='Affiliate Note'
                      value={value}
                      placeholder='Affiliate Note'
                      multiline={4}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={
                        errors.affiliateNote ? 'Affiliate Note is required' : ''
                      }
                    />
                  )}
                  name='affiliateNote'
                />
                <Stack distribution='trailing'>
                  <ButtonGroup>
                    <Button primary submit loading={paymentLoading}>
                      Save
                    </Button>
                    <Button onClick={() => handleChange()}>Cancel</Button>
                  </ButtonGroup>
                </Stack>
              </FormLayout>
            </Form>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Card>
  );
}

export default connect(null, { processPayment, markPaid, getPayoutById })(
  IndexTableComponent
);
