import React, { useEffect } from 'react';

import { Layout, Page, Frame, Loading, Button } from '@shopify/polaris';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPriceRules } from '../../../AppStore/actions/pricerules.actions';

import Table from '../../../Components/Table';

import { useLocation, useNavigate } from 'react-router-dom';

function PriceRules({ priceRulesData, getPriceRules }) {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getPriceRules(search);
  }, [getPriceRules, search]);

  return (
    <Page
      fullWidth
      title='Price Rules'
      primaryAction={
        <Button
          onClick={() => navigate('/dashboard/pricerules/create')}
          primary
        >
          Create Price Rule
        </Button>
      }
    >
      {priceRulesData.isLoading ? (
        <Frame>
          <Loading />
        </Frame>
      ) : (
        ''
      )}
      <Layout>
        <Layout.Section>
          {!priceRulesData.isLoading && (
            <Table priceRules={priceRulesData.data} />
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
}

PriceRules.prototype = {
  priceRulesData: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  collections: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  priceRulesData: state.pricerules,
  products: state.products,
  collections: state.collections,
});

export default connect(mapStateToProps, { getPriceRules })(PriceRules);
