import React from 'react';
import { DatePicker } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function CustomDatePicker({ formKey, formValue, setValue }) {
  const [{ month, year }, setDate] = useState({
    month: new Date(formValue).getMonth(),
    year: new Date(formValue).getFullYear(),
  });

  const [selectedDates, setSelectedDates] = useState({
    start: new Date(formValue),
    end: new Date(formValue),
  });

  const handleMonthChange = useCallback((month, year) => {
    setDate({ month, year });
  }, []);

  const handleDateChange = useCallback((date) => {
    const selectedDate = new Date(date.start);

    setValue(
      formKey,
      `${selectedDate.getFullYear()}-${String(
        selectedDate.getMonth() + 1
      ).padStart(2, 0)}-${String(selectedDate.getDate()).padStart(2, 0)}`
    );
    setSelectedDates({ ...date });
  }, []);

  return (
    <DatePicker
      month={month}
      year={year}
      onChange={handleDateChange}
      onMonthChange={handleMonthChange}
      selected={selectedDates}
    />
  );
}

export default CustomDatePicker;
