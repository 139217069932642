import React, { useState } from 'react';

import PayoutsTable from '../PayoutsTable';

import {
  Stack,
  Card,
  Icon,
  Text,
  Badge,
} from '@shopify/polaris';

import { CreditCardMajor, FinancesMajor } from '@shopify/polaris-icons';
import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';

const Payouts = () => {
  const { affiliateData } = useOutletContext();
  const [data, setData] = useState(null);
  const paymentMethod = 'Cash';

  useEffect(() => {
    if (affiliateData.data) {
      setData({
        payouts: affiliateData.data.data.payoutsData.payouts,
        totalPayout: affiliateData.data.data.payoutsData.totalPayouts,
        pendingPayouts: affiliateData.data.data.payoutsData.pendingPayouts,
        paidPayouts: affiliateData.data.data.payoutsData.paidPayouts,
      });
    }
  }, [affiliateData]);

  return (
    <>
      {data ? (
        <>
          <Stack distribution='fillEvenly'>
            <Card title={'Total Payouts'}>
              <Card.Section>{data.totalPayout}</Card.Section>
            </Card>
            <Card title={'Pending Payments'}>
              <Card.Section>{data.pendingPayouts}</Card.Section>
            </Card>
            <Card title={'Total Paid'}>
              <Card.Section>{data.paidPayouts}</Card.Section>
            </Card>
            <Card>
              <Card.Header title={'Payment Method'}></Card.Header>
              <Card.Section>
                {paymentMethod === 'Credit' ? (
                  <Stack distribution='equalSpacing'>
                    <Text variant='BodySm'>Credit Card</Text>
                    <Icon color='highlight' source={CreditCardMajor} />
                  </Stack>
                ) : paymentMethod === 'Cash' ? (
                  <Stack distribution='equalSpacing'>
                    <Text variant='BodySm'>
                      Cash <Badge>Default</Badge>
                    </Text>
                    <Icon color='success' source={FinancesMajor} />
                  </Stack>
                ) : (
                  <Stack distribution='equalSpacing'>
                    <Text variant='BodySm'>Other</Text>
                  </Stack>
                )}
              </Card.Section>
            </Card>
          </Stack>
          <div style={{ paddingTop: '20px' }}>
            <Text variant='headingLg' as='h3'>
              All Payouts
            </Text>
          </div>
          <div style={{ paddingTop: '15px' }}>
            <PayoutsTable
              hideQueryField={true}
              payoutsData={{ data: data.payouts, totalRecords: 0 }}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Payouts;
