import React, { useEffect } from "react";

import { Layout, Page, Frame, Loading } from "@shopify/polaris";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getQueries } from "../../../AppStore/actions/query.actions";

import { useLocation } from "react-router-dom";

import QueriesTable from "../../../Components/QueriesTable";

function Queries({ queriesData, getQueries }) {
  const { search } = useLocation();

  useEffect(() => {
    getQueries(search);
  }, [search]);

  return (
    <Page title='Customer Queries' fullWidth>
      {queriesData.isLoading ? (
        <Frame>
          <Loading />
        </Frame>
      ) : (
        ""
      )}
      <Layout>
        <Layout.Section>
          {!queriesData.isLoading && (
            <QueriesTable queries={queriesData.data.data} />
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
}

Queries.prototype = {
  queriesData: PropTypes.object.isRequired,
  getQueries: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  queriesData: state.query,
});

export default connect(mapStateToProps, { getQueries })(Queries);
