import React, { useCallback, useMemo } from 'react';

import { Toast } from '@shopify/polaris';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { removeAlert } from '../AppStore/actions/alert.actions';

const Alerts = ({ alerts, removeAlert }) => {
  const handleToast = useCallback(
    (id) => {
      removeAlert(id);
    },
    [removeAlert]
  );

  const toasts = useMemo(() => {
    return alerts.map(({ id, msg, alertType }) => (
      <Toast
        key={id}
        content={msg}
        duration={4500}
        error={alertType === 'critical' ? true : false}
        onDismiss={() => handleToast(id)}
      />
    ));
  });

  return <div>{toasts}</div>;
};

Alerts.prototype = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

export default connect(mapStateToProps, { removeAlert })(Alerts);
