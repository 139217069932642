import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  Form,
  TextField,
  FormLayout,
  Autocomplete,
  Icon,
  PageActions,
  Stack,
  Link,
  Button,
  Badge,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

import { useForm, Controller } from 'react-hook-form';

import { useNavigate, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getCouponById,
  updateCoupon,
  couponActivation,
} from '../../../AppStore/actions/coupons.actions';
import { getAllAffiliates } from '../../../AppStore/actions/affiliates.actions';

function Coupon({
  couponData,
  affiliatesData,
  getCouponById,
  updateCoupon,
  getAllAffiliates,
  couponActivation,
}) {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      priceRuleId: '',
      priceRuleTitle: '',
    },
  });

  const coupon = watch('coupon');
  const priceRuleTitle = watch('priceRuleTitle');
  const valueType = watch('valueType');
  const affiliate = watch('affiliate');

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAffilaite, setSelectedAffiliate] = useState({});

  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [couponActivationStatus, setCouponActivationStatus] = useState(false);
  const [activationLoading, setActivationLoading] = useState(false);

  const affiliates = useMemo(() => {
    if (!affiliatesData.isLoading && affiliatesData.data) {
      return affiliatesData.data.data.map((item) => ({
        value: item._id,
        label: `${item.firstName} ${item.lastName} (${item.email})`,
        email: item.email,
      }));
    } else {
      return [];
    }
  }, [affiliatesData]);

  useMemo(() => {
    if (!couponData.isLoading && couponData.data.data) {
      const {
        coupon,
        active,
        shopifyPriceRuleId: { _id, priceRuleTitle, valueType },
        commission,
        affiliate,
      } = couponData.data.data;

      setCouponActivationStatus(active);

      reset({
        coupon,
        priceRuleTitle,
        valueType,
        priceRuleId: _id,
        commission,
        affiliate,
      });
    }
  }, [couponData]);

  const updateText = useCallback(
    async (value) => {
      setInputValue(value);

      if (!loading) {
        setLoading(true);
      }

      if (value === '') {
        await getAllAffiliates();
        setLoading(false);
        return;
      }

      setTimeout(async () => {
        await getAllAffiliates({ query: value });
        setLoading(false);
      }, 300);
    },
    [affiliates, loading]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedObject = affiliates.find((option) => {
        return selected.includes(option.value);
      });
      setSelectedOptions(selected);
      setSelectedAffiliate(selectedObject);
      setInputValue(selectedObject.label);
      setValue('affiliate', selectedObject.value);
    },
    [affiliates]
  );

  const handleCouponActivation = useCallback(async () => {
    setActivationLoading(true);
    await couponActivation(id);
    await getCouponById(id);
    setActivationLoading(false);
  }, [id]);

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label='Affiliate'
      value={inputValue}
      prefix={<Icon source={SearchMinor} color='base' />}
      placeholder='Search Affiliate'
    />
  );

  const onSubmit = useCallback(
    async (formData) => {
      formData.id = id;
      setIsLoading(true);
      await updateCoupon(formData);
      await getCouponById(id);
      setIsLoading(false);
    },
    [id]
  );

  useEffect(() => {
    if (affiliates.length) {
      const data = affiliates.find((item) => item.value === affiliate);
      if (data) {
        setSelectedAffiliate({
          ...data,
        });
        setSelectedOptions([data._id]);
        setInputValue(data._id);
      }
    }
  }, [affiliates, affiliate]);

  useEffect(() => {
    if (id) {
      getCouponById(id);
      getAllAffiliates();
    }
  }, [id]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Page
        title={coupon}
        breadcrumbs={[{ content: 'Coupons', onAction: () => navigate(-1) }]}
        // titleMetadata={<Badge status='attention'>inactive</Badge>}
        // primaryAction={{ content: 'Save' }}
        // subtitle='May 3, 2019 to June 2, 2019'
        // secondaryActions={[
        //   { content: 'Duplicate' },
        //   { content: 'View on your store' },
        // ]}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <FormLayout>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        label='Coupon Name'
                        type='text'
                        onBlur={onBlur}
                        onChange={onChange}
                        requiredIndicator
                        value={value}
                        error={errors.coupon ? 'Coupon name is required' : ''}
                      />
                    )}
                    name='coupon'
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section>
                <FormLayout>
                  <TextField
                    label='PriceRule Title'
                    value={priceRuleTitle}
                    readOnly
                    disabled
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section hideOnPrint>
                <FormLayout>
                  {valueType === 'percentage' ? (
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          label='Affiliate Commission'
                          type='text'
                          placeholder='0'
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          suffix={'%'}
                          requiredIndicator
                          error={
                            errors.commission
                              ? 'Commission value is required'
                              : ''
                          }
                        />
                      )}
                      name='commission'
                    />
                  ) : (
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          label='Affiliate Commission'
                          type='text'
                          placeholder='0.00'
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          requiredIndicator
                          prefix={'Rs.'}
                          error={
                            errors.commission
                              ? 'Commission value is required'
                              : ''
                          }
                        />
                      )}
                      name='commission'
                    />
                  )}
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            {selectedOptions.length ? (
              <Card title='Affiliate'>
                <Card.Section>
                  <Stack vertical>
                    <Stack.Item>
                      <Link
                        to={`/dashboard/affiliates/${selectedAffilaite.value}`}
                      >
                        {selectedAffilaite.label}
                      </Link>
                    </Stack.Item>
                  </Stack>
                </Card.Section>
              </Card>
            ) : (
              <Card title='Affiliate'>
                <Card.Section>
                  <Autocomplete
                    options={affiliates}
                    selected={selectedOptions}
                    onSelect={updateSelection}
                    loading={loading}
                    textField={textField}
                  />
                </Card.Section>
              </Card>
            )}

            <Card
              title={
                <>
                  Coupon Activation{' '}
                  <Badge
                    status={couponActivationStatus ? 'success' : 'attention'}
                  >
                    {couponActivationStatus ? 'Activate' : 'Deactivate'}
                  </Badge>
                </>
              }
            >
              <Card.Section>
                <Stack vertical>
                  <Stack.Item>
                    <Button
                      loading={activationLoading}
                      onClick={handleCouponActivation}
                    >
                      {couponActivationStatus ? 'Deactive' : 'Activate'}
                    </Button>
                  </Stack.Item>
                </Stack>
              </Card.Section>
            </Card>

            {/* <Autocomplete
                  options={options}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  loading={isLoading}
                  textField={textField}
                  onLoadMoreResults={handleLoadMoreResults}
                  willLoadMoreResults={willLoadMoreResults}
                /> */}
          </Layout.Section>
          <Layout.Section fullWidth>
            <PageActions
              primaryAction={{
                content: 'Update',
                primary: true,
                loading: isLoading,
                onAction: handleSubmit(onSubmit),
              }}
            />
          </Layout.Section>
        </Layout>
      </Page>
    </Form>
  );
}

Coupon.prototype = {
  alerts: PropTypes.array.isRequired,
  couponData: PropTypes.object.isRequired,
  getAllAffiliates: PropTypes.func.isRequired,
  couponActivation: PropTypes.func.isRequired,
  affiliatesData: PropTypes.object.isRequired,
  updateCoupon: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  couponData: state.coupon,
  affiliatesData: state.affiliates,
});

export default connect(mapStateToProps, {
  getCouponById,
  updateCoupon,
  getAllAffiliates,
  couponActivation,
})(Coupon);
