import React from 'react';
import { EmptyState } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftMinor } from '@shopify/polaris-icons';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <EmptyState
        heading='404 - Not Found'
        action={{
          content: 'Go Back',
          onAction: () => navigate(-1),
          icon: ArrowLeftMinor,
        }}
        image='https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png'
      >
        <p>Looks like the page you're looking for does not exist.</p>
      </EmptyState>
    </div>
  );
};

export default NotFound;
