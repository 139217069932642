import React, { useEffect } from 'react';
import { Page, Layout, Button, Frame, Loading } from '@shopify/polaris';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAllAffiliates } from '../../../AppStore/actions/affiliates.actions';
import { useNavigate, useLocation } from 'react-router-dom';

import AffiliatesTable from '../../../Components/AffiliatesTable';

function Affiliates({ affiliatesData, getAllAffiliates }) {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getAllAffiliates(search);
  }, [getAllAffiliates, search]);

  return (
    <Page
      fullWidth
      title='Affiliates'
      primaryAction={
        <Button
          onClick={() => navigate({ pathname: '/dashboard/affiliates/create' })}
          primary
        >
          Create Affiliate
        </Button>
      }
    >
      {affiliatesData.isLoading ? (
        <Frame>
          <Loading />
        </Frame>
      ) : (
        <Layout>
          <Layout.Section>
            <AffiliatesTable affiliates={affiliatesData.data} />
          </Layout.Section>
        </Layout>
      )}
    </Page>
  );
}

Affiliates.prototype = {
  affiliatesData: PropTypes.object.isRequired,
  getAllAffiliates: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  affiliatesData: state.affiliates,
});

export default connect(mapStateToProps, {
  getAllAffiliates,
})(Affiliates);
