import React, { useState, useEffect } from 'react';
import {
  Card,
  Layout,
  Page,
  FormLayout,
  TextField,
  Frame,
  Button,
  Loading,
  Text,
  Image,
  SkeletonDisplayText,
  SkeletonBodyText,
  Form,
  Banner,
} from '@shopify/polaris';

import { useForm, Controller } from 'react-hook-form';
import { Navigate, useSearchParams } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, autoLogin } from '../AppStore/actions/auth.actions';

function Login({ auth, autoLogin, login, alerts }) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    await login(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchParams.get('token')) {
      autoLogin(searchParams.get('token'));
    }
  }, [searchParams]);

  if (auth.loading) {
    return (
      <Frame>
        <Loading />
        <div
          style={{
            height: '100vh',
            overflow: 'hidden',
            background: ' linear-gradient(to right, #c2e59c, #64b3f4)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Page narrowWidth>
            <Layout>
              <Layout.Section>
                <div
                  style={{
                    width: '95vw',
                    maxWidth: '475px',
                  }}
                >
                  <Card sectioned>
                    <Image
                      width={'100%'}
                      height={'100%'}
                      source={require('../assets/images/login.jpg')}
                    />
                    <SkeletonDisplayText size='small' />
                    <br />
                    <SkeletonBodyText lines={2} />
                    <br />
                    <FormLayout>
                      <Button disabled fullWidth />
                      <Button disabled fullWidth />
                      <Button size='large' pressed fullWidth />
                      <SkeletonBodyText lines={1} />
                    </FormLayout>
                  </Card>
                </div>
              </Layout.Section>
            </Layout>
          </Page>
        </div>
      </Frame>
    );
  }

  if (auth.isAuthenticated) {
    return <Navigate to='/dashboard' />;
  }

  return (
    <div
      style={{
        height: '100vh',
        overflow: 'hidden',
        background: ' linear-gradient(to right, #c2e59c, #64b3f4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Page narrowWidth>
        <Layout>
          <Layout.Section>
            <div
              style={{
                width: '95vw',
                maxWidth: '475px',
              }}
            >
              <Card sectioned>
                <Image
                  width={'100%'}
                  height={'100%'}
                  source={require('../assets/images/login.jpg')}
                />
                <Text variant='headingSm'>Welcome to</Text>
                <Text variant='headingLg'>Nutrifactor Affiliate Admin</Text>
                <br />
                <Form onSubmit={handleSubmit(onSubmit)}>
                  {alerts.map(({ id, msg, alertType }) => (
                    <React.Fragment key={id}>
                      <Banner title={msg} status={alertType} />
                      <br />
                    </React.Fragment>
                  ))}
                  <FormLayout>
                    <Controller
                      name='email'
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          label={'Email'}
                          type={'email'}
                          placeholder='example@mail.com'
                          value={field.value}
                          onChange={field.onChange}
                          requiredIndicator
                          inputMode='email'
                          error={
                            errors && errors.email ? 'Email is required' : ''
                          }
                        />
                      )}
                    />
                    <Controller
                      name='password'
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          label={'Password'}
                          type={'password'}
                          placeholder='******'
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          name={field.name}
                          requiredIndicator
                          inputMode='text'
                          error={
                            errors && errors.password
                              ? 'Password is required'
                              : ''
                          }
                        />
                      )}
                    />
                    <Button
                      submit
                      size='large'
                      primary
                      loading={isLoading}
                      fullWidth
                    >
                      Login
                    </Button>
                    <Text variant='bodyMd' alignment='center'>
                      Please login to continue
                    </Text>
                  </FormLayout>
                </Form>
              </Card>
            </div>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  );
}

Login.prototype = {
  auth: PropTypes.object.isRequired,
  alerts: PropTypes.array.isRequired,
};

const mapToProps = (state) => ({
  auth: state.auth,
  alerts: state.alerts,
});

export default connect(mapToProps, {
  login,
  autoLogin,
})(Login);
