import React, { useEffect, useCallback, useState } from 'react';

import {
  Page,
  Layout,
  Card,
  Badge,
  Link,
  ResourceList,
  ResourceItem,
  Thumbnail,
  Icon,
  Stack,
  Text,
  Tooltip,
  Button,
} from '@shopify/polaris';

import {
  CircleCancelMinor,
  TickMinor,
  CircleTickMinor,
  CancelMinor,
  ResetMinor,
  ImageMajor,
} from '@shopify/polaris-icons';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getOrderById,
  acceptOrderById,
  rejectOrderById,
  revokeOrderById,
} from '../../../AppStore/actions/orders.actions';

import { useParams, useNavigate } from 'react-router-dom';

import { status } from '../../../utils/data';

function Order({
  orderData,
  getOrderById,
  acceptOrderById,
  rejectOrderById,
  revokeOrderById,
  alerts,
}) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const getStatus = useCallback((s) => {
    if (status[s]) {
      if (s === 'P') {
        return <Badge size='small'>{status[s]}</Badge>;
      } else if (s === 'A' || s === 'RE' || s === 'PA' || s === 'fulfilled') {
        return (
          <Badge size='small' status='success'>
            {status[s]}
          </Badge>
        );
      } else if (s === 'R' || s === 'C' || s === 'cancelled') {
        return (
          <Badge size='small' status='critical'>
            {status[s]}
          </Badge>
        );
      } else {
        return <Badge size='small'>{status[s]}</Badge>;
      }
    } else {
      return <Badge size='small'>{s}</Badge>;
    }
  }, []);

  const acceptOrder = useCallback(async () => {
    setIsLoading(true);
    await acceptOrderById(id);
    await getOrderById(id);
    setIsLoading(false);
  }, []);
  const rejectOrder = useCallback(async () => {
    setIsLoading(true);
    await rejectOrderById(id);
    await getOrderById(id);
    setIsLoading(false);
  }, []);
  const revokeOrder = useCallback(async () => {
    setIsLoading(true);
    await revokeOrderById(id);
    await getOrderById(id);
    setIsLoading(false);
  }, []);
  useEffect(() => {
    getOrderById(id);
  }, []);

  return (
    <>
      {orderData.isLoading ? (
        <Page title='Loading...' />
      ) : (
        orderData.data && (
          <Page
            title={orderData.data.data.shopifyOrderName}
            breadcrumbs={[{ onAction: () => navigate(-1) }]}
            titleMetadata={
              <Stack>
                {/* <Stack.Item>{getStatus(orderData.data.data.status)}</Stack.Item>
                <Stack.Item>
                  {getStatus(orderData.data.data.paymentStatus)}
                </Stack.Item> */}
              </Stack>
            }
            subtitle={`${new Date(
              orderData.data.data.createdAt
            ).toLocaleDateString()} ${new Date(
              orderData.data.data.createdAt
            ).toLocaleTimeString()}`}
            compactTitle
          >
            <Layout>
              <Layout.Section>
              </Layout.Section>
              <Layout.Section>
                <Card sectioned title={'Order Details'}>
                  <Stack distribution='equalSpacing'>
                    <Stack.Item>
                      <Stack distribution='equalSpacing' vertical>
                        {/* <Stack.Item>Coupon</Stack.Item> */}
                        <Stack.Item>
                          <Text variant='headingSm'>Commission</Text>
                        </Stack.Item>
                        <Stack.Item>
                          <Text variant='headingSm'>User Discount</Text>
                        </Stack.Item>
                        <Stack.Item>
                          <Text variant='headingSm'>Order Status</Text>
                        </Stack.Item>
                        <Stack.Item>
                          <Text variant='headingSm'>Fulfilment Status</Text>
                        </Stack.Item>
                        <Stack.Item>
                          <Text variant='headingSm'>Payment Status</Text>
                        </Stack.Item>
                        <Stack.Item>
                          <Text variant='headingSm'>Tracking Url</Text>
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                    <Stack.Item>
                      <Stack distribution='equalSpacing' vertical>
                        {/* <Stack.Item>
                          <Link
                            onClick={() =>
                              navigate(
                                `/dashboard/coupons/${orderData.data.data.couponApplied._id}`
                              )
                            }
                          >
                            {orderData.data.data.couponApplied.coupon}
                          </Link>
                        </Stack.Item> */}
                        <Stack.Item>
                          {orderData.data.data.commission}
                        </Stack.Item>
                        <Stack.Item>
                          {orderData.data.data.userDiscount}
                        </Stack.Item>
                        <Stack.Item>
                          {getStatus(orderData.data.data.status)}
                        </Stack.Item>
                        <Stack.Item>
                          {getStatus(orderData.data.data.fulfillmentStatus)}
                        </Stack.Item>
                        <Stack.Item>
                          {getStatus(orderData.data.data.paymentStatus)}
                        </Stack.Item>
                        <Stack.Item>
                          {orderData.data.data.trackingURL ? (
                            <Link
                              removeUnderline
                              url={orderData.data.data.trackingURL}
                              external={'true'}
                            >
                              Track
                            </Link>
                          ) : (
                            ''
                          )}
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                  </Stack>
                </Card>
                <Card title={'Products'}>
                  <Card.Section flush>
                    <ResourceList
                      resourceName={{
                        singular: 'product',
                        plural: 'products',
                      }}
                      items={orderData.data.data.products}
                      renderItem={(item) => {
                        const {
                          productName,
                          productPrice,
                          image,
                          url,
                          quantity,
                          discountApplied,
                        } = item;
                        const media = (
                          <Thumbnail
                            source={image || ImageMajor}
                            alt={productName}
                            size='small'
                          />
                        );

                        return (
                          <ResourceItem
                            id={id}
                            url={url}
                            media={media}
                            verticalAlignment='center'
                            persistActions
                            external={'true'}
                          >
                            <Stack distribution='fillEvenly' alignment='center'>
                              <Stack.Item>
                                <Stack alignment='center'>
                                  <Stack.Item>
                                    <Text>{productName}</Text>
                                  </Stack.Item>
                                  <Stack.Item>
                                    {discountApplied ? (
                                      <Tooltip content='Coupon Applied'>
                                        <Icon
                                          source={CircleTickMinor}
                                          color={'success'}
                                          backdrop
                                          accessibilityLabel='Coupon Applied'
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip content='Coupon Not Applied'>
                                        <Icon
                                          source={CircleCancelMinor}
                                          color={'critical'}
                                          backdrop
                                          accessibilityLabel='Coupon Not Applied'
                                        />
                                      </Tooltip>
                                    )}
                                  </Stack.Item>
                                </Stack>
                              </Stack.Item>
                              <Stack.Item>
                                <Stack
                                  distribution='equalSpacing'
                                  alignment='center'
                                >
                                  <Stack.Item>
                                    {quantity} x {productPrice}
                                  </Stack.Item>
                                  <Stack.Item>
                                    {quantity * productPrice}
                                  </Stack.Item>
                                </Stack>
                              </Stack.Item>
                            </Stack>
                          </ResourceItem>
                        );
                      }}
                    />
                  </Card.Section>
                  <Card.Section subdued>
                    <Stack distribution='equalSpacing'>
                      <Stack.Item>
                        <Text variant='headingSm'>
                          Total Commissionable Amount
                        </Text>
                      </Stack.Item>
                      <Stack.Item>
                        {orderData.data.data.commissionableAmount}
                      </Stack.Item>
                    </Stack>
                  </Card.Section>
                </Card>
              </Layout.Section>
              <Layout.Section secondary>
                <Card title='Actions'>
                  <Card.Section title={'Update Order Status'}>
                    {orderData.data.data.status === 'P' ? (
                      <Stack spacing='tight'>
                        <Stack.Item>
                          <Button
                            primary
                            size='slim'
                            icon={<Icon source={TickMinor} />}
                            onClick={acceptOrder}
                            disabled={isLoading}
                          >
                            Accept
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <Button
                            primary
                            size='slim'
                            destructive
                            icon={<Icon source={CancelMinor} />}
                            onClick={rejectOrder}
                            disabled={isLoading}
                          >
                            Reject
                          </Button>
                        </Stack.Item>
                      </Stack>
                    ) : (
                      <Stack distribution='leading'>
                        <Stack.Item>
                          <div style={{ color: '#5aabff' }}>
                            <Button
                              monochrome
                              size='slim'
                              outline
                              icon={<Icon source={ResetMinor} />}
                              onClick={revokeOrder}
                              disabled={isLoading}
                            >
                              Revoke
                            </Button>
                          </div>
                        </Stack.Item>
                      </Stack>
                    )}
                  </Card.Section>
                </Card>
                <Card title='Affiliate Details'>
                  <Card.Section title='Affiliate'>
                    <Link
                      onClick={() =>
                        navigate(
                          `/dashboard/affiliates/${orderData.data.data.affiliate._id}`
                        )
                      }
                    >
                      {orderData.data.data.affiliate.firstName}{' '}
                      {orderData.data.data.affiliate.lastName}
                    </Link>{' '}
                    <Text>{orderData.data.data.affiliate.email}</Text>
                  </Card.Section>
                  <Card.Section title='Coupon'>
                    <Link
                      onClick={() =>
                        navigate(
                          `/dashboard/affiliates/${orderData.data.data.couponApplied._id}`
                        )
                      }
                    >
                      {orderData.data.data.couponApplied.coupon}
                    </Link>
                  </Card.Section>
                </Card>
              </Layout.Section>
            </Layout>
          </Page>
        )
      )}
    </>
  );
}

Order.prototype = {
  orderData: PropTypes.object.isRequired,
  getOrderById: PropTypes.func.isRequired,
  acceptOrderById: PropTypes.func.isRequired,
  rejectOrderById: PropTypes.func.isRequired,
  revokeOrderById: PropTypes.func.isRequired,
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  orderData: state.order,
  alerts: state.alerts,
});

export default connect(mapStateToProps, {
  getOrderById,
  acceptOrderById,
  rejectOrderById,
  revokeOrderById,
})(Order);
