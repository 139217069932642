import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  IndexTable,
  Card,
  Filters,
  useIndexResourceState,
  Text,
  ChoiceList,
  Badge,
  Link,
} from "@shopify/polaris";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Pagination from "./Pagination";

function NotificationsTable({ notifications }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resourceName = {
    singular: "notification",
    plural: "notifications",
  };

  const [notificationStatus, setNotificationStatus] = useState("");

  const [timeOutId, setTimeoutId] = useState(null);

  const resourceIDResolver = (data) => {
    return data._id;
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(notifications.data, {
    resourceIDResolver,
  });

  const [queryValue, setQueryValue] = useState("");

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const handleTypeRemove = useCallback(() => {
    setNotificationStatus("");
    delete searchParamsObject.status;

    const newSearchParams = new URLSearchParams({
      ...searchParamsObject,
    });

    navigate({
      pathname,
      search: `?${newSearchParams}`,
    });
  }, [searchParamsObject]);

  const handleQueryChange = useCallback(
    (query) => {
      setQueryValue(query);

      let newSearchParams = {};

      if (!query) {
        delete searchParamsObject.query;
        newSearchParams = new URLSearchParams({
          ...searchParamsObject,
        });

        return navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }

      newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        query,
      });

      clearTimeout(timeOutId);
      const id = setTimeout(async () => {
        navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }, 500);

      setTimeoutId(id);
    },
    [search, searchParamsObject, timeOutId]
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);

  const handleClearAll = useCallback(() => {
    handleTypeRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTypeRemove]);

  const handleStatusChange = useCallback(
    (newValue) => {
      setNotificationStatus(newValue);

      const newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        status: newValue,
      });

      navigate({
        pathname,
        search: `?${newSearchParams}`,
      });
    },
    [searchParamsObject]
  );

  const filters = [
    {
      key: "status",
      label: "Status",
      filter: (
        <ChoiceList
          title='Read Status'
          choices={[
            { label: "Read", value: "read" },
            { label: "Unread", value: "unread" },
          ]}
          selected={notificationStatus}
          onChange={handleStatusChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = !isEmpty(notificationStatus)
    ? [
        {
          key: "status",
          label: disambiguateLabel("status", notificationStatus),
          onRemove: handleTypeRemove,
        },
      ]
    : [];

  // const getStatus = useCallback((s) => {
  //   if (status[s]) {
  //     if (s === 'P') {
  //       return <Badge size='small'>{status[s]}</Badge>;
  //     } else if (s === 'A' || s === 'RE' || s === 'PA' || s === 'fulfilled') {
  //       return (
  //         <Badge size='small' status='success'>
  //           {status[s]}
  //         </Badge>
  //       );
  //     } else if (s === 'R' || s === 'C' || s === 'B' || s === 'cancelled') {
  //       return (
  //         <Badge size='small' status='critical'>
  //           {status[s]}
  //         </Badge>
  //       );
  //     } else {
  //       return <Badge size='small'>{status[s]}</Badge>;
  //     }
  //   } else {
  //     return <Badge size='small'>{s}</Badge>;
  //   }
  // }, []);

  const rowMarkup = notifications.data.map(
    ({
      _id,
      notificationTitle,
      notificationBody,
      readStatus,
      viewStatus,
      sender,
      recipient,
      notificationType,
      createdAt,
    }) => (
      <IndexTable.Row
        id={_id}
        key={_id}
        selected={selectedResources.includes(_id)}
        // position={index}
      >
        <IndexTable.Cell>
          <Text variant='bodyMd' fontWeight='bold' as='span'>
            {notificationTitle}
          </Text>
        </IndexTable.Cell>
        {/* <IndexTable.Cell>{notificationBody}</IndexTable.Cell> */}
        <IndexTable.Cell>
          <Badge>{readStatus ? "read" : "unread"}</Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>{sender.firstName}</IndexTable.Cell>
        <IndexTable.Cell>
          {recipient.firstName ? (
            <Link url={`/dashboard/affiliates/${recipient._id}`}>
              {recipient.firstName}
            </Link>
          ) : (
            ""
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>{notificationType}</IndexTable.Cell>
        <IndexTable.Cell>{`${new Date(
          createdAt
        ).toLocaleDateString()} ${new Date(
          createdAt
        ).toLocaleTimeString()}`}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  useEffect(() => {
    if (searchParamsObject.status) {
      setNotificationStatus(searchParamsObject.status);
    }
  }, []);

  return (
    <Card>
      <div style={{ padding: "16px", display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            queryPlaceholder='Search Affiliates'
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        itemCount={notifications.data.length}
        selectable={false}
        selectedItemsCount={
          allResourcesSelected ? "All" : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        hasMoreItems
        headings={[
          { title: "Title" },
          // { title: 'Body' },
          { title: "Read Status" },
          { title: "Sender" },
          { title: "Recipient" },
          { title: "Type" },
          { title: "Created At" },
        ]}
      >
        {rowMarkup}
      </IndexTable>
      <Pagination totalRecords={notifications.totalRecords} />
    </Card>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "status":
        return `${key}: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
}

export default NotificationsTable;
