import React, { useEffect, useState } from 'react';
import { Card, Stack, Badge } from '@shopify/polaris';
import { ClipboardMinor, TickMinor } from '@shopify/polaris-icons';

const CardsCustom = ({
  couponData: {
    id,
    name,
    startDate,
    endDate,
    active,
    type,
    commission,
    customerDiscount,
  },
}) => {
  const [copyText, setCopyText] = useState(false);

  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      if (successful) {
        setCopyText(true);
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };

  const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function() {
        setCopyText(true);
      },
      function(err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  useEffect(() => {
    if (copyText) {
      setTimeout(() => {
        setCopyText(false);
      }, 2000);
    }
  }, [copyText]);

  return (
    <Card
      key={`coupon-card-${id}`}
      sectioned
      title={
        <>
          {name}{' '}
          <Badge status={active ? 'success' : 'critical'}>
            {active ? 'Active' : 'inActive'}
          </Badge>
        </>
      }
      actions={[
        {
          content: copyText ? 'Copied' : '',
          icon: copyText ? TickMinor : ClipboardMinor,
          disabled: copyText ? true : false,
          onAction: () => copyTextToClipboard(name),
        },
      ]}
    >
      <Stack distribution='equalSpacing' alignment='trailing'>
        <Stack.Item>
          <div>Type: {type}</div>
          <div>Affiliate Commission: {commission}</div>
          <div>Customer Discount: {customerDiscount}</div>
        </Stack.Item>
        <Stack.Item>
          <div style={{ marginTop: '10px' }}>
            <div>
              Created:{' '}
              <span style={{ color: 'green' }}>{`${new Date(
                startDate
              ).toLocaleDateString()} ${new Date(
                startDate
              ).toLocaleTimeString()}`}</span>
            </div>
            <div>
              Valid Till:{' '}
              <span style={{ color: 'crimson' }}>
                {endDate
                  ? `${new Date(endDate).toLocaleDateString()} ${new Date(
                      endDate
                    ).toLocaleTimeString()}`
                  : ''}
              </span>
            </div>
          </div>
        </Stack.Item>
      </Stack>
    </Card>
  );
};

export default CardsCustom;
