import React, { useEffect, useMemo } from 'react';

import {
  Card,
  Frame,
  Loading,
  Layout,
  Page,
  Text,
  Icon,
  Stack,
  DataTable,
  ResourceList,
  ResourceItem,
  Avatar,
  Link,
  EmptyState,
} from '@shopify/polaris';
import {
  DataDrivenModelMinor,
  CustomersMinor,
  ArrowLeftMinor,
  ProfileMinor,
  PlusMinor,
  CashDollarMinor,
  FinancesMinor,
} from '@shopify/polaris-icons';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDashboardData } from '../../AppStore/actions/dashboard.actions';

import { status } from '../../utils/data';
import { useNavigate } from 'react-router-dom';

function Dashboard({ dashboardData, getDashboardData, user }) {
  const navigate = useNavigate();
  const recentOrders = useMemo(() => {
    if (!dashboardData.isLoading && dashboardData.data) {
      if (dashboardData.data.recentOrders.length) {
        return dashboardData.data.recentOrders.map((item) => {
          return [
            <Text as='span' variant='headingSm'>
              <Link url={`/dashboard/orders/${item._id}`}>
                {item.shopifyOrderName}
              </Link>
            </Text>,
            status[item.status],
            item.amount,
            item.commission,
            `${new Date(item.createdAt).toLocaleTimeString()} ${new Date(
              item.createdAt
            ).toLocaleDateString()}`,
          ];
        });
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [dashboardData]);

  useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <Page fullWidth>
      {dashboardData.isLoading ? (
        <Frame>
          <Loading />
        </Frame>
      ) : dashboardData.data ? (
        <>
          <Layout>
            <Layout.Section oneThird>
              <Card
                title={
                  <Stack alignment='center' distribution='equalSpacing'>
                    <Text variant='headingMd' as='h3'>
                      Orders
                    </Text>
                    <Icon source={DataDrivenModelMinor} />
                  </Stack>
                }
                color='success'
              >
                <Card.Section>
                  <Text variant='heading2xl' as='h3'>
                    {dashboardData.data.orders || 0}
                  </Text>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card
                title={
                  <Stack alignment='center' distribution='equalSpacing'>
                    <Text variant='headingMd' as='h3'>
                      Revenue
                    </Text>
                    <Icon source={FinancesMinor} />
                  </Stack>
                }
              >
                <Card.Section>
                  <Text variant='heading2xl' as='h3'>
                    {dashboardData.data.moneyFormat.replace(
                      '{{amount}}',
                      dashboardData.data.revenue.toFixed(2)
                    ) || 0}
                  </Text>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card
                title={
                  <Stack alignment='center' distribution='equalSpacing'>
                    <Text variant='headingMd' as='h3'>
                      Commission
                    </Text>
                    <Icon source={CashDollarMinor} />
                  </Stack>
                }
              >
                <Card.Section>
                  <Text variant='heading2xl' as='h3'>
                    {dashboardData.data.moneyFormat.replace(
                      '{{amount}}',
                      dashboardData.data.commission.toFixed(2)
                    ) || 0}
                  </Text>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card
                title={
                  <Stack alignment='center' distribution='equalSpacing'>
                    <Text variant='headingMd' as='h3'>
                      Affiliates
                    </Text>
                    <Icon source={CustomersMinor} />
                  </Stack>
                }
              >
                <Card.Section>
                  <Text variant='heading2xl' as='h3'>
                    {dashboardData.data.affiliates || 0}
                  </Text>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>

          <div style={{ marginTop: '30px' }}>
            <Layout>
              <Layout.Section oneHalf>
                <Card
                  title={
                    <Stack alignment='center' distribution='equalSpacing'>
                      <Text variant='headingMd' as='h3'>
                        Top Affiliate
                      </Text>
                      <Icon source={ProfileMinor} />
                    </Stack>
                  }
                >
                  <Card.Section>
                    <ResourceList
                      resourceName={{
                        singular: 'customer',
                        plural: 'customers',
                      }}
                      items={dashboardData.data.topAffiliates || []}
                      renderItem={(item) => {
                        const {
                          _id,
                          firstName,
                          lastName,
                          totalOrders,
                          revenue,
                          commission,
                        } = item;
                        const media = (
                          <Avatar
                            customer
                            size='medium'
                            name={`${firstName} ${lastName}`}
                          />
                        );

                        return (
                          <ResourceItem
                            id={_id}
                            url={`/dashboard/affiliates/${_id}`}
                            media={media}
                            accessibilityLabel={`View details for ${firstName}`}
                          >
                            <Text variant='bodyMd' fontWeight='bold' as='h3'>
                              {`${firstName} ${lastName}`}
                            </Text>
                            <div>{`Orders: ${totalOrders} Commission: Rs.${commission} Revenue: Rs.${revenue}`}</div>
                          </ResourceItem>
                        );
                      }}
                    />
                  </Card.Section>
                </Card>
              </Layout.Section>
              <Layout.Section oneHalf>
                <Card
                  title={
                    <Stack alignment='center' distribution='equalSpacing'>
                      <Text variant='headingMd' as='h3'>
                        New Affiliate
                      </Text>
                      <Icon source={PlusMinor} />
                    </Stack>
                  }
                >
                  <Card.Section>
                    <ResourceList
                      resourceName={{
                        singular: 'customer',
                        plural: 'customers',
                      }}
                      items={dashboardData.data.topAffiliates || []}
                      renderItem={(item) => {
                        const {
                          _id,
                          firstName,
                          lastName,
                          totalOrders,
                          revenue,
                          commission,
                        } = item;
                        const media = (
                          <Avatar
                            customer
                            size='medium'
                            name={`${firstName} ${lastName}`}
                          />
                        );

                        return (
                          <ResourceItem
                            id={_id}
                            url={`/dashboard/affiliates/${_id}`}
                            media={media}
                            accessibilityLabel={`View details for ${firstName}`}
                          >
                            <Text variant='bodyMd' fontWeight='bold' as='h3'>
                              {`${firstName} ${lastName}`}
                            </Text>
                            <div>{`Orders: ${totalOrders} Commission: Rs.${commission} Revenue: Rs.${revenue}`}</div>
                          </ResourceItem>
                        );
                      }}
                    />
                  </Card.Section>
                </Card>
              </Layout.Section>
            </Layout>
          </div>
          <div style={{ marginTop: '30px' }}>
            <Layout>
              <Layout.Section>
                <Card title={'Recent Orders'}>
                  <Card.Section>
                    <DataTable
                      increasedTableDensity
                      columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text',
                        'text',
                      ]}
                      headings={[
                        'Order Id',
                        'Status',
                        'Order Value',
                        'commission',
                        'created At',
                      ]}
                      rows={recentOrders}
                    />
                  </Card.Section>
                </Card>
              </Layout.Section>
            </Layout>
          </div>
        </>
      ) : dashboardData.error ? (
        <Page
          title={user ? `Welcome, ${user.firstName} ${user.lastName}` : ''}
          narrowWidth
        >
          <Layout>
            <Layout.Section>
              <Card>
                <EmptyState
                  heading='Unauthorized'
                  action={{
                    content: 'Go back',
                    icon: ArrowLeftMinor,
                    onAction: () => navigate(-1),
                  }}
                  image='https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png'
                >
                  <p>You are not authorized to access this page.</p>
                </EmptyState>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      ) : (
        'Something went wrong'
      )}
    </Page>
  );
}

Dashboard.prototype = {
  dashboardData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboard,
  user: state.auth.user,
});

export default connect(mapStateToProps, { getDashboardData })(Dashboard);
