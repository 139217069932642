import React from 'react';
import { Outlet } from 'react-router';
import Staff from './Staff';
import StaffInfo from './StaffInfo';
import CreateStaff from './Create';

function StaffIndex() {
  return <Outlet />;
}

export { Staff, StaffInfo, StaffIndex, CreateStaff };
