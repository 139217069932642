import axios from 'axios';
import {
  PRODUCTS_SUCCESS,
  PRODUCTS_ERROR,
  COLLECTION_SUCCESS,
  COLLECTION_ERROR,
} from '../constants';

export const getProducts = (query = {}) => async (dispatch) => {
  try {
    const params = new URLSearchParams(query);

    const res = await axios.get(`/api/v1/products${query ? '?' + params : ''}`);

    dispatch({
      type: PRODUCTS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCTS_ERROR,
      payload: 'error',
    });
  }
};

export const getCollections = (query = {}) => async (dispatch) => {
  try {
    const params = new URLSearchParams(query);

    const res = await axios.get(
      `/api/v1/collections${query ? '?' + params : ''}`
    );
    dispatch({
      type: COLLECTION_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: COLLECTION_ERROR,
      payload: 'error',
    });
  }
};
