import React, { useState, useCallback, useEffect, useMemo } from 'react';

import {
  Page,
  PageActions,
  Layout,
  Card,
  Form,
  FormLayout,
  TextField,
  Button,
  ButtonGroup,
  Stack,
  RadioButton,
  Icon,
  Checkbox,
  ResourceList,
  ResourceItem,
  Thumbnail,
  Popover,
  Text,
  List,
  AlphaStack,
} from '@shopify/polaris';

import {
  SearchMinor,
  CalendarMajor,
  ClockMajor,
  CancelSmallMinor,
  ImageMajor,
} from '@shopify/polaris-icons';

import { useNavigate } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getProducts,
  getCollections,
} from '../../../AppStore/actions/productsncollections';

import ProductsnCollections from '../../../Components/Modal/PAndC';
import DatePicker from '../../../Components/DatePicker';

import { createPriceRule } from '../../../AppStore/actions/pricerules.actions';

function Create({
  products,
  collections,
  getProducts,
  getCollections,
  createPriceRule,
}) {
  const navigate = useNavigate();

  const currentDate = new Date();

  const [isModal, setIsModal] = useState(false);
  const [isPopover, setIsPopover] = useState(false);
  const [isEndPopover, setIsEndPopover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const productsData = useMemo(() => {
    if (!products.isLoading && products.data) {
      return products.data.data.edges.map((item) => ({
        id: item.node.id,
        productTitle: item.node.title,
        image: item.node.images.edges.length
          ? item.node.images.edges[0].node.originalSrc
          : '',
        url: item.node.onlineStorePreviewUrl,
      }));
    } else {
      return [];
    }
  }, [products]);

  const collectionsData = useMemo(() => {
    if (!collections.isLoading && collections.data) {
      return collections.data.data.edges.map((item) => ({
        id: item.node.id,
        collectionName: item.node.title,
      }));
    } else {
      return [];
    }
  }, [collections]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isPercentage: true,
      type: 'collections',
      products: [],
      collections: [],
      isEndDate: false,
      startdate: `${currentDate.getFullYear()}-${String(
        currentDate.getMonth() + 1
      ).padStart(2, 0)}-${String(currentDate.getDate()).padStart(2, 0)}`,
      startTime: `${String(currentDate.getHours()).padStart(2, 0)}:${String(
        currentDate.getMinutes()
      ).padStart(2, 0)}`,
      enddate: `${currentDate.getFullYear()}-${String(
        currentDate.getMonth() + 1
      ).padStart(2, 0)}-${String(currentDate.getDate()).padStart(2, 0)}`,
      endtime: `${String(currentDate.getHours()).padStart(2, 0)}:${String(
        currentDate.getMinutes()
      ).padStart(2, 0)}`,
    },
  });
  const isPercentage = watch('isPercentage');
  const priceRuleTitle = watch('priceRuleTitle');
  const type = watch('type');
  const value = watch('value');
  const selectedProducts = watch('products');
  const selectedCollections = watch('collections');
  const startDate = watch('startdate');
  const endDate = watch('enddate');
  const isEndDate = watch('isEndDate');

  const setPercentage = useCallback(() => setValue('isPercentage', true), []);
  const setFalt = useCallback(() => setValue('isPercentage', false), []);

  const handlePriceRuleType = useCallback(
    (_checked, newValue) => setValue('type', newValue),
    []
  );

  const handleModal = useCallback(() => setIsModal(true), [isModal]);
  const handleStartDatePopover = useCallback(() => setIsPopover(!isPopover), [
    isPopover,
  ]);
  const handleEndDatePopover = useCallback(
    () => setIsEndPopover(!isEndPopover),
    [isEndPopover]
  );

  const removeProduct = useCallback(
    (unselect) => {
      setValue(
        'products',
        selectedProducts.filter(({ id }) => id !== unselect)
      );
    },
    [selectedProducts]
  );

  const removeCollection = useCallback(
    (unselect) =>
      setValue(
        'collections',
        selectedCollections.filter(({ id }) => id !== unselect)
      ),
    [selectedCollections]
  );

  useEffect(() => {
    if (type === 'products') {
      setValue('collections', []);
      getProducts();
    } else if (type === 'collections') {
      setValue('products', []);
      getCollections();
    }
  }, [type]);

  const onSubmit = async (data) => {
    const {
      isPercentage,
      priceRuleTitle,
      products,
      collections,
      type,
      value,
      startTime,
      startdate,
      endtime,
      enddate,
    } = data;
    const formData = {
      priceRuleTitle,
      products,
      collections,
      type,
      valueType: isPercentage ? 'percentage' : 'fixed_amount',
      value,
      startDate: new Date(`${startdate} ${startTime}`),
      endDate: isEndDate ? new Date(`${endtime} ${enddate}`) : '',
    };
    setIsLoading(true);
    await createPriceRule(formData);
    setIsLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Page
        breadcrumbs={[{ onAction: () => navigate(-1) }]}
        title={'Create Price Rule'}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      label='Price Rule Title'
                      type='text'
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={
                        errors.priceRuleTitle
                          ? 'Price rule Title is required'
                          : ''
                      }
                    />
                  )}
                  name='priceRuleTitle'
                />
              </Card.Section>
            </Card>
            <Card title={'Value'}>
              <Card.Section>
                {isPercentage ? (
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        type='text'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        suffix={'%'}
                        error={errors.value ? 'Value is required' : ''}
                        connectedRight={
                          <ButtonGroup segmented>
                            <Button pressed={true} onClick={setPercentage}>
                              Percentage
                            </Button>
                            <Button pressed={false} onClick={setFalt}>
                              Fixed amount
                            </Button>
                          </ButtonGroup>
                        }
                      />
                    )}
                    name='value'
                  />
                ) : (
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        type='text'
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        prefix='Rs'
                        error={errors.value ? 'Value is required' : ''}
                        connectedRight={
                          <ButtonGroup segmented>
                            <Button pressed={false} onClick={setPercentage}>
                              Percentage
                            </Button>
                            <Button pressed={true} onClick={setFalt}>
                              Fixed amount
                            </Button>
                          </ButtonGroup>
                        }
                      />
                    )}
                    name='value'
                  />
                )}
              </Card.Section>
              <Card.Section title={'Applies to'}>
                <Stack vertical distribution='fill'>
                  <Stack.Item>
                    <Stack vertical spacing={'none'}>
                      <RadioButton
                        label='Specific collections'
                        name='type'
                        checked={type === 'collections'}
                        onChange={handlePriceRuleType}
                        id={'collections'}
                      />
                      <RadioButton
                        label='Specific products'
                        name='type'
                        onChange={handlePriceRuleType}
                        checked={type === 'products'}
                        id={'products'}
                      />
                    </Stack>
                  </Stack.Item>
                  {type ? (
                    <Stack.Item>
                      <TextField
                        prefix={<Icon source={SearchMinor} />}
                        type='text'
                        placeholder='Search Products'
                        onChange={handleModal}
                        connectedRight={
                          <Button onClick={handleModal}>Browse</Button>
                        }
                      />
                      {type === 'products' ? (
                        <ResourceList
                          resourceName={{
                            singular: 'product',
                            plural: 'products',
                          }}
                          items={selectedProducts}
                          renderItem={(item) => {
                            const { id, productTitle, image } = item;
                            const media = (
                              <Thumbnail
                                source={image ? image : ImageMajor}
                                alt='Product Image'
                                size='small'
                              />
                            );
                            return (
                              <ResourceItem
                                id={id}
                                media={media}
                                verticalAlignment='center'
                                persistActions
                                shortcutActions={[
                                  {
                                    content: (
                                      <Icon
                                        source={CancelSmallMinor}
                                        color={'critical'}
                                      />
                                    ),
                                    onAction: () => removeProduct(id),
                                    accessibilityLabel: 'Remove',
                                  },
                                ]}
                              >
                                {productTitle}
                              </ResourceItem>
                            );
                          }}
                        />
                      ) : type === 'collections' ? (
                        <ResourceList
                          resourceName={{
                            singular: 'collection',
                            plural: 'collections',
                          }}
                          items={selectedCollections}
                          renderItem={(item) => {
                            const { id, collectionName, image } = item;
                            const media = (
                              <Thumbnail
                                source={image ? image : ImageMajor}
                                alt='Product Image'
                                size='small'
                              />
                            );

                            return (
                              <ResourceItem
                                id={id}
                                media={media}
                                verticalAlignment='center'
                                persistActions
                                shortcutActions={[
                                  {
                                    content: (
                                      <Icon
                                        source={CancelSmallMinor}
                                        color={'critical'}
                                      />
                                    ),
                                    onAction: () => removeCollection(id),
                                    accessibilityLabel: 'Remove',
                                  },
                                ]}
                              >
                                {collectionName}
                              </ResourceItem>
                            );
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </Stack.Item>
                  ) : (
                    ''
                  )}
                </Stack>
              </Card.Section>
            </Card>
            <Card title={'Active dates'}>
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Popover
                      sectioned
                      active={isPopover}
                      onClose={handleStartDatePopover}
                      activator={
                        <div onClick={handleStartDatePopover}>
                          <Controller
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field: { value } }) => (
                              <TextField
                                prefix={<Icon source={CalendarMajor} />}
                                type='date'
                                label='Start date'
                                value={value}
                              />
                            )}
                            name='startdate'
                          />
                        </div>
                      }
                      ariaHaspopup={false}
                    >
                      <DatePicker
                        formKey={'startdate'}
                        formValue={startDate}
                        setValue={setValue}
                      />
                    </Popover>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          prefix={<Icon source={ClockMajor} />}
                          type='time'
                          label='Start time'
                          // value={new Date()
                          //   .toLocaleDateString('zh-Hans-CN')
                          //   .replaceAll('/', '-')}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      name='startTime'
                    />
                  </FormLayout.Group>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        label='End date'
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                      />
                    )}
                    name='isEndDate'
                  />
                  {isEndDate && (
                    <FormLayout.Group>
                      <Popover
                        sectioned
                        active={isEndPopover}
                        onClose={handleEndDatePopover}
                        activator={
                          <div onClick={handleEndDatePopover}>
                            <Controller
                              control={control}
                              rules={{
                                required: isEndDate,
                              }}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <TextField
                                  prefix={<Icon source={CalendarMajor} />}
                                  type='date'
                                  label='End Date'
                                  value={value}
                                />
                              )}
                              name='enddate'
                            />
                          </div>
                        }
                        ariaHaspopup={false}
                      >
                        <DatePicker
                          formKey={'enddate'}
                          formValue={endDate}
                          setValue={setValue}
                        />
                      </Popover>
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            prefix={<Icon source={ClockMajor} />}
                            type='time'
                            label='End time'
                            // value={new Date()
                            //   .toLocaleDateString('zh-Hans-CN')
                            //   .replaceAll('/', '-')}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                        name='endtime'
                      />
                    </FormLayout.Group>
                  )}
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title='Summary' subdued>
              <Card.Section>
                <AlphaStack gap={'5'}>
                  <AlphaStack gap={'3'}>
                    <Text as='p' variant='bodySm' fontWeight='semibold'>
                      Type and method
                    </Text>
                    <List type='bullet'>
                      <List.Item>
                        Amount off{' '}
                        {type === 'collections' ? 'Collections' : 'Products'}
                      </List.Item>
                      <List.Item>Code</List.Item>
                    </List>
                  </AlphaStack>
                  <AlphaStack gap={'3'}>
                    <Text as='p' variant='bodySm' fontWeight='semibold'>
                      Details
                    </Text>
                    <List type='bullet'>
                      {value && isPercentage ? (
                        <List.Item>
                          {value}% off{' '}
                          {type === 'collections' ? 'Collections' : 'Products'}
                        </List.Item>
                      ) : value && !isPercentage ? (
                        <List.Item>
                          Rs {value} off{' '}
                          {type === 'collections' ? 'Collections' : 'Products'}
                        </List.Item>
                      ) : (
                        ''
                      )}
                      {priceRuleTitle ? (
                        <List.Item>Title: {priceRuleTitle}</List.Item>
                      ) : (
                        ''
                      )}
                      <List.Item>Can't combine with other discounts</List.Item>
                      <List.Item>
                        {startDate ? (
                          <>
                            Active from <i>{startDate}</i>
                            {isEndDate ? (
                              <>
                                {' '}
                                to <i>{endDate}</i>
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </List.Item>
                    </List>
                  </AlphaStack>
                </AlphaStack>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <PageActions
              primaryAction={{
                content: 'Save Price Rule',
                loading: isLoading,
                onAction: handleSubmit(onSubmit),
              }}
              secondaryActions={[
                { content: 'Discard', onAction: () => navigate(-1) },
              ]}
            />
          </Layout.Section>
        </Layout>
        {type ? (
          <ProductsnCollections
            isModal={isModal}
            setIsModal={setIsModal}
            collections={collectionsData}
            products={productsData}
            selectedProducts={selectedProducts}
            selectedCollections={selectedCollections}
            type={type}
            setValue={setValue}
          />
        ) : (
          ''
        )}
      </Page>
    </Form>
  );
}

Create.prototype = {
  products: PropTypes.object.isRequired,
  collections: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.products,
  collections: state.collections,
});

export default connect(mapStateToProps, {
  getProducts,
  getCollections,
  createPriceRule,
})(Create);
