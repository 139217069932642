import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import {
  IndexTable,
  Card,
  Filters,
  useIndexResourceState,
  Text,
  Badge,
  RadioButton,
  FormLayout,
  Link,
} from '@shopify/polaris';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Pagination from './Pagination';

function CouponsTable({ coupons }) {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resourceName = {
    singular: 'coupon',
    plural: 'coupons',
  };

  const [type, setType] = useState('');

  const timeOutId = useRef(null);

  const resourceIDResolver = (data) => {
    return data._id;
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(coupons.data, {
    resourceIDResolver,
  });

  const [queryValue, setQueryValue] = useState('');

  const searchParamsObject = useMemo(() => {
    const object = {};
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      return object;
    } else {
      return {};
    }
  }, [searchParams]);

  const handleTypeRemove = useCallback(() => {
    setType(null);
    delete searchParamsObject.type;

    const newSearchParams = new URLSearchParams({
      ...searchParamsObject,
    });

    navigate({
      pathname,
      search: `?${newSearchParams}`,
    });
  }, [searchParamsObject]);

  const handleQueryChange = useCallback(
    (query) => {
      setQueryValue(query);

      let newSearchParams = {};

      if (!query) {
        delete searchParamsObject.query;
        newSearchParams = new URLSearchParams({
          ...searchParamsObject,
        });

        return navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }

      newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        query,
      });

      clearTimeout(timeOutId);
      timeOutId.current = setTimeout(async () => {
        navigate({
          pathname,
          search: `?${newSearchParams}`,
        });
      }, 100);
    },
    [search, searchParamsObject]
  );
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue('');
    searchParams.delete('query');

    navigate({
      pathname,
      search: `?${searchParams}`,
    });
  }, [searchParams]);

  const handleClearAll = useCallback(() => {
    handleTypeRemove();
    handleQueryValueRemove();


  }, [handleQueryValueRemove, handleTypeRemove]);

  const handleTypeChange = useCallback(
    (_checked, newValue) => {
      setType(newValue);
      const newSearchParams = new URLSearchParams({
        ...searchParamsObject,
        type: newValue,
      });

      navigate({
        pathname,
        search: `?${newSearchParams}`,
      });
    },
    [searchParamsObject]
  );

  const filters = [
    {
      key: 'type',
      label: 'Type',
      filter: (
        <FormLayout>
          <RadioButton
            id='percentage'
            label='Percentage'
            checked={type === 'percentage'}
            name='type'
            onChange={handleTypeChange}
          />
          <RadioButton
            id='fixed_amount'
            label='fixed_amount'
            checked={type === 'fixed_amount'}
            name='type'
            onChange={handleTypeChange}
          />
        </FormLayout>
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = !isEmpty(type)
    ? [
      {
        key: 'type',
        label: disambiguateLabel('type', type),
        onRemove: handleTypeRemove,
      },
    ]
    : [];

  useEffect(() => {
    if (searchParams.get('type')) {
      setType(searchParams.get('type'));
    }
    if (searchParams.get('query')) {
      setQueryValue(searchParams.get('query'));
    }
  }, [searchParamsObject]);

  const rowMarkup = coupons.data.map(
    (
      {
        _id,
        coupon,
        orders,
        commission,
        shopifyPriceRuleId,
        affiliate,
        createdAt,
        updatedAt,
      },
      index
    ) => (
      <IndexTable.Row
        id={_id}
        key={_id}
        selected={selectedResources.includes(_id)}
        position={index}
      >
        <IndexTable.Cell>
          <Link removeUnderline url={`/dashboard/coupons/${_id}`}>
            <Text variant='bodyMd' fontWeight='bold' as='span'>
              {coupon}
            </Text>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Link
            removeUnderline
            url={`/dashboard/pricerules/${shopifyPriceRuleId._id}`}
          >
            {shopifyPriceRuleId.priceRuleTitle}
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {orders}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Link
            removeUnderline
            url={`/dashboard/affiliates/${affiliate._id}`}
          >{`${affiliate.firstName} ${affiliate.lastName}`}</Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Badge>{shopifyPriceRuleId.valueType}</Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>{commission}</IndexTable.Cell>
        <IndexTable.Cell>{shopifyPriceRuleId.value}</IndexTable.Cell>
        <IndexTable.Cell>{`${new Date(
          createdAt
        ).toLocaleTimeString()} ${new Date(
          createdAt
        ).toLocaleDateString()}`}</IndexTable.Cell>
        <IndexTable.Cell>{`${new Date(
          updatedAt
        ).toLocaleTimeString()} ${new Date(
          updatedAt
        ).toLocaleDateString()}`}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  return (
    <Card>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            queryPlaceholder='Search Coupons'
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        itemCount={coupons.data.length}
        selectable={false}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        hasMoreItems
        headings={[
          { title: 'Coupon' },
          { title: 'Price Rule' },
          { title: 'Orders' },
          { title: 'Assigned To' },
          { title: 'Type' },
          { title: 'Commission' },
          { title: 'User Disount' },
          { title: 'Updated At' },
          { title: 'Created At' },
        ]}
      >
        {rowMarkup}
      </IndexTable>
      <Pagination totalRecords={coupons.totalRecords} />
    </Card>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'type':
        return `${key}: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}

export default CouponsTable;
